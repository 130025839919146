import React, { useState, useEffect } from 'react';
import * as Actions from './actions';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ReactTable from 'react-table-custom';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {

    },
    hightline: {
        margin: "0 4px",
        fontWeight: 'bold',
        fontSize: 13
    }
}));

export default function SearchPage(props) {
    const classes = useStyles(props);
    const [data, setData] = useState({ records: 0, pages: 1, data: [] });
    const [key, setKey] = useState(null);
    const [filterOption, setFilterOption] = useState({ page: 0, pageSize: 20, filtered: [], sorted: [{id:'fileNumber',desc:false}] });
    function handleFilterdChange(filtered) {
        setFilterOption({...filterOption,filtered})
    }
    function handlePageChange(page) {
        setFilterOption({ ...filterOption,page})
    }
    function refetch() {
        const { filtered, sorted, pageSize, page } = filterOption;        
        var mergedFilter = [...filtered, { id: "name,fileNumber,department,version,UnsignName,issuedBy,slug", value:key&&key.replace(/\//g,'.') }];
        Actions.search_file({ filtered: mergedFilter, page, pageSize, sorted }).then(response => {
            setData(response.data.response);
        })
    }
    useEffect(() => {
        const { key } = props.match.params;
        setKey(decodeURIComponent(key));
    }, [props.match.params]);
    
    useEffect(() => {
        if(key)
            refetch();
    }, [key, filterOption]);
   
    function onClick(item) {
        if (item.slug === "qtqds") {
            //mở giao diện chi tiết QTQD
            if (item._id) {
              props.history.push(`/qtqd-detail/${item._id}`)
            }
          } else {
            //mở giao diện chi tiết VBPQ
            if (item._id) {
              // console.log("show vbpq detail: ", item._id)
              props.history.push(`/vbpq-detail/${item._id}`)
            }
          }
    }
    return (
        <div>
            <div>
                <div className="flex">
                    Tìm thấy
                    <Typography className={classes.hightline}>{data.records}</Typography> kết quả cho từ khóa
                    <Typography className={classes.hightline}>{key}</Typography>
                </div>
            </div>
            <div>
                <ReactTable
                    manual
                    noDataText="Không có dữ liệu"
                    defaultSorted={[{ desc: true }]}
                    data={data.data}
                    pages={data.pages}
                    filterable={true}
                    onFilteredChange={handleFilterdChange}
                    onPageChange={handlePageChange}
                    className="table-document"
                    columns={[
                        {
                            Header: "STT",
                            width: 50,
                            className: "text-center",
                            filterable: false,
                            Cell: row => <span>
                                {row.index + 1+(filterOption.page*filterOption.pageSize)}
                            </span>
                        },
                        {
                            Header: "Văn bản",
                            accessor: "name",
                            Cell: row => <Link style={{ textAlign: "left" }} component="button" onClick={() => onClick(row.original)}>{row.value}</Link>,
                            minWidth:200,
                        },
                        {
                            Header: "Phiên bản",
                            accessor: "version",
                            width:80
                            
                        },
                        {
                            Header: "Khoa/Phòng",
                            accessor: "department",
                            width:150,                           
                        },
                        {
                            Header: "Tag",
                            accessor: "slug",
                            width:70,
                        },
                        {
                            Header: "Số kí hiệu",
                            accessor: "fileNumber",
                            width:150
                        },
                        {
                            Header: "Đơn vị ban hành",
                            accessor: "issuedBy",
                            width:150
                        },
                        {
                            Header: "Ngày ban hành",
                            accessor: "issuedDate",                            
                            Cell: row => <span>{moment(row.value).format("DD/MM/YYYY")}</span>,
                            width: 120,
                            filterable:false
                        },
                        {
                            Header: "Người tạo",
                            accessor: "account.base.fullName",
                            width: 120,
                            filterable:false
                        },
                    ]}
                />
            </div>
        </div>
    )
}
import React from 'react'
import { withApollo } from 'react-apollo'
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import {  ALL_SLUG_BY_TYPE} from 'GraphQL/PVT/Slug';
import authCheck from 'utils/authCheck';
import { Button } from 'reactstrap'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import DepartmentsTable from 'pages/Documents/FileTables/PVT/DepartmentsTable'

import { SLUG_DEPARTMENT } from 'Constants'

class DepartmentsPage extends React.Component
{
    state = {
        departments: [],
        data        : [],
        loading     : false,
    }
    constructor(props){
        super(props)
        this.setState({

        })
        this.fetchDepartments = this.fetchDepartments.bind(this)
        this.handleDetail = this.handleDetail.bind(this)
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        this.fetchDepartments()
    }
    //fetch data
    fetchDepartments(){
        this.setState({loading:true})
        this.props.client.query({
            query: ALL_SLUG_BY_TYPE,
            variables: {  type: SLUG_DEPARTMENT,}
        }).then(result =>{
            authCheck(result.data)
            console.log("get departments success: ", result.data.response.data.map(item => {return {value:item._id, label:item.name}}))
            if(result.data.response.data)
            {
                this.setState({
                    departments : result.data.response.data,
                })
                
            }
            this.setState({loading:false})
        })
    }
    handleDetail = (departmentName) => {
        this.props.history.push(`/departmentFiles/${departmentName}`)
    }

    render(){
        const { departments, } = this.state
        return(
            <div>
                <PageTitle title={"TỔNG HỢP QUY TRÌNH QUY ĐỊNH"}></PageTitle>
                <div style={{maxWidth:"1280px", margin:"auto"}}>
                    <div style = {{marginTop:"20px"}}>
                        <DepartmentsTable parentId = {""} 
                        data = {departments} 
                        pages = {1} 
                        pageSize={20} 
                        loading={this.state.loading} 
                        fetchData={this.fetchDepartments} 
                        onDetail = {this.handleDetail}></DepartmentsTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withApollo(DepartmentsPage)
import gql from 'graphql-tag';


export const LOGIN_MUTATION = gql`
mutation ($username: String!, $password: String!) {
    response:login(username: $username, password: $password) {
      code
      message
      data{
        
        accessToken
        _id
        createOn
        expireOn
        fullName
        userName
        
      }
    }
  }
`

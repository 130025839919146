import React, {useState} from 'react';
import { Button,} from 'reactstrap';
import ReactTable from "react-table-custom";

import editIcon from 'assets/icons/edit_icon.png'
import deleteIcon from 'assets/icons/delete_icon.png'
import downloadIcon from 'assets/icons/download_icon.png'
import printIcon from 'assets/icons/print_icon.png'

const getFileId = (index, data) => {
    const item = data[index]
    if(item){
        let fileId = item.fileId
        console.log("get fileid: ", fileId)
        return fileId
    }
    return ""
}
const BieuMauTable=({parentId,data,pageSize,pages,onUpdateItem,canEdit, canDownload = true, canPrint = true,onDeleteItem,onDownloadItem, onPrintItem, onDetail})=>{
    const[page,setPage]=useState(0);
    return <ReactTable
        // manual
        noDataText="Không có dữ liệu"
        sortable={false}
        // defaultSorted={[{ id:"createdTime",desc: true }]}
        data={data}
        // pages={pages} 
        defaultPageSize={5}
        pageSize={pageSize}
        showPagination={true}
        onPageChange    ={setPage}
        className="table-bieumau"
        style = {{color:"#4E5357", fontSize:"15px"}}
        columns={[
            {
                Header: () => (
                    <div>
                        <i className="fa-tasks" /> STT
                    </div>
                  ),
                width: 50,
                className: "text-center",
                style:{marginTop:"15px"},
                filterable: false,
                Cell: row => <span>
                    {row.index + 1}
                </span>
            },
            {
                Header: () => (
                    <div style={{display:"grid"}}>
                        <span >
                            <i className="fa-tasks" /> MÃ TÀI LIỆU
                        </span>
                    </div>
                  ),
                accessor: "code",
                filterable: true,
                style:{marginTop:"15px"},
                Cell:row=><span style={{textAlign:"left",paddingLeft:"10px", textDecoration:"underline",textDecorationColor:"#C6C6C6", cursor:"pointer"}} 
                    onClick={()=>onDetail(getFileId(row.index,data), row.index)}>
                    {/* // onClick={()=>onDetail(row.index)}> */}
                    {row.value}
                </span>
            },      
            {
                Header: () => (
                    <div style={{display:"grid"}}>
                        <span>
                            <i className="fa-tasks" /> TÊN TÀI LIỆU
                        </span>
                    </div>
                  ),
                accessor: 'name',
                filterable: true,
                style:{marginTop:"15px"},
                Cell:row=><span style={{textAlign:"left",paddingLeft:"10px", textDecoration:"underline",textDecorationColor:"#C6C6C6", cursor:"pointer"}} 
                        onClick={()=>onDetail(getFileId(row.index,data), row.index)}>
                        {/* // onClick={()=>onDetail(row.index)}> */}
                    {row.value}
                </span>
            },     
            {
                Header: () => (
                    <div style = {{marginTop:"10px"}}>
                        <i className="fa-tasks" /> CHỨC NĂNG
                    </div>
                  ),
                width:160   ,
                Cell: row => <div style={{margin:"auto", display:"inline-flex", float:"right"}}>
                    {
                        canEdit&&
                            <Button onClick={e => onUpdateItem(row.index)} size="ms" color = "" style={{ padding:"5px"}}><img src={editIcon} alt="edit" style={{objectFit:"contain", width:"21px", height:"21px"}}></img></Button>
                    }
                    {
                        canEdit&&
                            <Button onClick={e => onDeleteItem(row.index)} size="ms" color = "" style={{padding:"5px"}}><img src={deleteIcon} alt="delete" style={{objectFit:"contain", width:"21px", height:"21px"}}></img></Button>
                    }
                    {canPrint&&
                            <Button onClick={e => onPrintItem(row.index)} size="ms" color = "" style={{padding:"5px"}}><img src={printIcon} alt="print" style={{objectFit:"contain", width:"21px", height:"21px"}}></img></Button>
                    }
                    {canDownload&&
                            <Button onClick={e => onDownloadItem(row.index)} size="ms" color = "" style={{padding:"5px"}}><img src={downloadIcon} alt="download" style={{objectFit:"contain", width:"21px", height:"21px"}}></img></Button>
                    }
                </div>
            }
        ]}
    />
}
export default BieuMauTable;
import React from 'react'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import TutorialTable from 'pages/Documents/FileTables/PVT/TutorialTable'
import authCheck from 'utils/authCheck';
import { withApollo } from 'react-apollo'
import gql from "graphql-tag";
import { GET_TUTORIAL } from './query';

function createMarkup(content){
    return {__html: `${content}`};
  }
class ArticleDetailPage extends React.Component
{
    state = {
        article : null,
        _id     : null,
    }
    constructor(props){
        super(props)
        this.setState({

        })
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        const _id = this.props.match.params._id;
        if(_id){
          this.setState({_id:_id});
          //fetch detail
          this.props.client.query({
              query:GET_TUTORIAL,
              variables:{_id:_id},
              
          }).then(result=>{
            if(authCheck(result)){
                const article=result.data.response.data;    
                this.setState({article});   
            }       
          });
      }
    }

    render(){
        const { article } = this.state
        return(
            <div style = {{textAlign:"center"}}>
                <PageTitle onClick={e => window.history.back()} title={"NỘI DUNG"} subTitle={article&&article.name&&article.name}></PageTitle>
                <div style={{margin:"auto"}}>
                    <div style = {{ backgroundColor:"white", padding:"30px"}}>
                        {article&&article.content&&<div dangerouslySetInnerHTML={createMarkup(article.content)} />}
                    </div>
                </div>
            </div>
        )
    }
}
export default withApollo(ArticleDetailPage);
//Các query xử lý biểu mẫu - đính kèm

import gql from 'graphql-tag';

export const CREATE_APPENDIX=gql`
    mutation createAppendixes($docId:String!, $data:[AppendixInput]!)
    {
        response:createAppendixes(docId: $docId, data: $data){
            code
            message
            data{
                _id
                code
                docId
                fileId
                fileUrl
                pdfUrl:fileUrl(ispdf:true)
                name
                fileInfo(ispdf:false){
                    name
                    filePath
                    oriExtension
                    createdTime
                }
            }
        }
    }
`
export const DELETE_APPENDIX=gql`
    mutation deleteAppendix($_id: String!){
        response:deleteAppendix(_id: $_id){
            code
            message
        }
    }
`
export const UPDATE_APPENDIX=gql`
    mutation updateAppendix($data:AppendixInput!)
    {
        response:updateAppendix(data:$data)
        {
            code
            message
            data{
                _id
                code
                docId
                fileId
                fileUrl
                pdfUrl:fileUrl(ispdf:true)
                name
                fileInfo(ispdf:false){
                    name
                    filePath
                    oriExtension
                    createdTime
                }
            }
        }
    }
`
export const GET_APPENDIXES=gql`
    query getAppendixes($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int)
    {
        response:appendixes(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
            code
            message
            page
            pages
            records
            data{
                _id
                code
                docId
                fileId
                fileUrl
                pdfUrl:fileUrl(ispdf:true)
                name  
                fileInfo(ispdf:false){
                    name
                    filePath
                    oriExtension
                    createdTime
                }
            }
        }
    }
`

export const GET_LINK=gql`
    query getLink($fileId:String!){
        response:getLink(fileId:$fileId){
            code
            message
            data
        }
    }
`
import React from 'react';
import Page from 'components/Page';
import { Button, ButtonGroup,Col,Row,FormGroup, Input} from 'reactstrap';
import { LIST_MENU,DELETE_MENU,LIST_ALL_PARENT_MENU } from 'GraphQL/menu'
import editIcon from 'assets/icons/edit_icon.png'
import deleteIcon from 'assets/icons/delete_icon.png'
import authCheck from 'utils/authCheck';
import { withApollo } from 'react-apollo'
import { MdEdit, MdDelete, MdRemove } from 'react-icons/lib/md';
import ReactTable from "react-table-custom";
import ToggleButton from "react-toggle-button";
import confirm from 'components/Confirmation';

class MenuManager extends React.Component{
    state = {
        data:[],
        level:1,
        parents:[],
        pages:1,
        selectedParent:""
    }

    constructor(props){
        super(props);
        this.fetchData = this.fetchData.bind(this);
    }
    componentDidMount() {
        this.fetchAllParent();
    }
    fetchData = (state) =>{
        const { sorted, filtered, page, pageSize } = state;
        console.log("fetch filtered:",filtered);
        this.props.client.query({
            query: LIST_MENU,
            variables: { filtered, sorted, page, pageSize }
        }).then (result =>{
            if(authCheck(result))
            this.setState({ data: result.data.response.data, pages: result.data.response.pages})
        })
    }
    fetchAllParent=()=>{
        this.props.client.query({
            query:LIST_ALL_PARENT_MENU
        }).then(result=>{
            if(authCheck(result.data)){
                this.setState({parents:result.data.response.data});
            }
        })
    }
    onEdit = (_id) =>{
        this.props.history.push("/menu-edit/"+_id)
    }
    onDelete = (_id, name) =>{
        confirm(`Bạn có chắc chắn muốn xóa menu ${name}?`, { showCancel: false }).then(kq=>{
            this.props.client.mutate({
                mutation: DELETE_MENU,
                variables: { _id }
            }).then(result =>{
                if(result.data.response.code == 0){
                    confirm(`Đã xóa menu ${name} thành công`, { showCancel: false }).then(_=>{
                        this.fetchData(this.state);
                    })
                }
            })
        })
    }
    render = () =>{
        return(
            <Page
                className="CustomePage">
                <div style = {{textAlign:"center"}}>
                    <h2 style = {{marginTop:"15px",fontWeight:"bold", color:"#08AC50"}}>QUẢN LÝ MENU</h2>
                    <div style = {{textAlign:"left"}}>
                        <Button color = "success" style = {{fontSize:"16px", marginTop:"10px", marginLeft:"10px"}} onClick = {e=>this.props.history.push("/menu-edit")}>Thêm mới</Button>
                    </div>
                </div>
                <div style = {{marginTop:"20px"}}></div>
                <ReactTable
                    manual
                    noDataText="Không có dữ liệu"
                    sortable={false}
                    defaultSorted={[{ id: "sort", desc: false }]}                  
                    data={this.state.data}
                    pages={this.state.pages}
                    defaultPageSize={20}
                    // pageSize={this.state.data.length}
                    filterable
                    onFetchData={this.fetchData} 
                    className="table-document"
                    columns={[
                        {
                            Header: "STT",
                            width: 50,
                            className: "text-center",
                            filterable: false,
                            Cell: row => <span>
                                {row.index + 1}
                            </span>
                        },
                        {
                            Header:"Cấp",
                            width:80,
                            accessor: "level",
                            type: 'select',
                            options: [
                                1,2,3
                            ].map(item=>({label:`cấp ${item}`,value:`${item}`}))
                            
                        },
                        {
                            Header: "Tên menu",
                            accessor: "name"
                        },                        
                        {
                            Header: "Đường dẫn",
                            accessor: "path",
                        },
                        {
                            Header:"Thứ tự",
                            width:80,
                            accessor: "sort",
                            type: 'select',
                            options:[1,2,3,4,5,6,7,8,9].map(item=>({label:`cấp ${item}`,value:`${item}`}))
                        },
                        {
                            Header: "Menu cha",
                            accessor: "parentId",
                            Cell: row => <span>{row.original.parent && row.original.parent.name}</span>,
                            type: 'select',
                            options:this.state.parents.map(item=>({label:item.name,value:item._id}))                            
                        },
                        {
                            Header:"Hoạt động",
                            width:100,
                            accessor:"disable",
                            className: "text-center",
                            type: 'select',
                            options: [
                                {
                                    value:"false",label:"Hoạt động"
                                },
                                {
                                    value:"true",label:"Chặn"
                                }
                            ]                            
                        }, 
                        {
                            Header: "Tác vụ",
                            width:120,
                            filterable: false,
                            Cell: row => 
                            <div style ={{ textAlign: "center" }}>
                                <ButtonGroup>
                                    <Button onClick={() => this.onEdit(row.original._id)} color="" ><img src = {editIcon} alt = "edit" style = {{objectFit:"cover", width: "20px"}}></img></Button>
                                    <Button onClick={() => this.onDelete(row.original._id, row.original.name)} color="" ><img src = {deleteIcon} alt = "delete" style = {{objectFit:"cover", width: "20px"}}></img></Button>
                                </ButtonGroup>
                            </div>
                        }
                    ]}
                />
            </Page>
        );
    }
}
export default withApollo(MenuManager);

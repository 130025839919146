import React, { useState, useEffect } from 'react'
import { withApollo } from 'react-apollo'
import { FormGroup, Button, Input, Row, Col, Label } from 'reactstrap'
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/vi';

import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import UploadFileForm from 'components/Widget/PVT/UploadFileForm'
import BieuMauTable from 'pages/Documents/FileTables/PVT/BieuMauTable'
import UpdateBieuMauForm from 'components/Widget/PVT/UpdateBieuMauForm'


import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { CREATE_DOC_FILE } from 'GraphQL/PVT/FileManager';
import { CREATE_APPENDIX, GET_LINK } from 'GraphQL/PVT/Appendix'
import { QUERY_DIRECTORY } from 'GraphQL/directory';
import { GET_DEPARTMENTS} from './query';
import authCheck from 'utils/authCheck';

import { useForm } from 'hooks';

const initialForm = {
    parentPath: "",
    name: "",
    content: "",
    fileNumber: "",
    fileId: null,
    preFileId: null,
    slug: "qtqds",
    "hidden": false,
    "issuedBy": "",
    version: "",
    department: "",
    departmentId: null,
    relateDepartmentIds:[],
    decisionNumber: "",
    decisionDate: null
}

function AddQTQDPages(props) {
    const [parentName, setParentName] = useState(null);
    const [departments, setDepartments] = useState([]);
    const { form, setForm, setInForm, handleChange } = useForm(initialForm);
    const [appendixes, setAppendixes] = useState([]);
    const [openBieuMauForm, setOpenBieuMauForm] = useState(false);
    const [openBieuMauEditForm, setOpenBieuMauEditForm] = useState(false);
    const [appendixUpdateIndex, setAppendixUpdateIndex] = useState(null);

    useEffect(() => {
        const { id } = props.match.params;
        window.scrollTo(0, 0);
        if (id) {
            fetchName(id);
        }
    }, [props.match.params]);

    useEffect(() => {
        fetchDepartments();
    }, []);

    const fetchName = (id) => {
        props.client.query({
            query: QUERY_DIRECTORY,
            variables: { _id: `${id}` }
        }).then(result => {
            authCheck(result.data);

            var name = result.data.response.data.name;
            var parentPath = result.data.response.data.path;
            setInForm("parentPath", parentPath);
            setParentName(name);
        })
    }

    function fetchDepartments() {
        props.client.query({
            query: GET_DEPARTMENTS
        }).then(result => {
            if (authCheck(result.data)) {
                if (result.data.response.data) {
                    setDepartments(result.data.response.data.map(item => ({ value: item._id, label: item.name })));
                }
            }

        })
    }

    function handleShowDetail(fileId) {
        props.client.query({
            query: GET_LINK,
            variables: { fileId: fileId }
        }).then(result => {
            authCheck(result.data)
            console.log("get file url for preview success: ", result.data)
            const url = result.data.response.data
            if (url) {
                var win = window.open(url, '_blank');
                win.focus();
            }
        })
    }
    function handleUpdateAppendix(index) {
        const item = appendixes[index]
        console.log("update item: ", item.files)
        if (item) {
            // setState({
            //     openBieuMauEditForm: true,
            //     appendixUpdateIndex: index,
            //     updateDocCode: item.code,
            //     updateDocName: item.name,
            //     updateDocFileId: item.fileId,
            // })
        }

    }
    function handleDownloadItem() {

    }
    function handleDeleteAppendix(index) {
        // const { appendixes } = state
        console.log("delete index: ", index)
        BaseControl.showConfirmAlert(
            "Thông báo",
            "Bạn có chắc muốn xóa văn bản đính kèm này?",
            "Đồng ý",
            "Hủy",
            () => {//success
                try {
                    appendixes.splice(index, 1)
                    // setState({ appendixes })
                    setAppendixes([...appendixes]);
                } catch (error) {
                    console.log("===> delete appendix error: ", error)
                }
            },
            () => {//cancel

            }
        )
    }

    //button
    function handleAccept() {
        // const { files, selectedDepartment, selectedPushlishDate, selectedDecisionDate, openBieuMauForm, departments, name, docCode, version, number, parentPath } = state
        if (form.name.length === 0) {
            BaseControl.showErrorToast("Vui lòng nhập tên văn bản")
            return
        }
        if (form.fileNumber.length === 0) {
            BaseControl.showErrorToast("Vui lòng nhập mã tài liệu")
            return
        }
        if (form.version.length === 0) {
            BaseControl.showErrorToast("Vui lòng nhập phiên bản")
            return
        }
        if (!form.department || form.department.length == 0) {
            BaseControl.showErrorToast("Vui lòng chọn khoa phòng")
            return
        }
        if (form.decisionNumber.length === 0) {
            BaseControl.showErrorToast("Vui lòng nhập số quyết định")
            return
        }
        if (!form.decisionDate) {
            BaseControl.showErrorToast("Vui lòng nhập ngày quyết định")
            return
        }

        // const submitData = { ...form, issuedDate: moment(form.issuedDate).format("YYYY-MM-DD"), decisionDate: moment(form.decisionDate).format("YYYY-MM-DD") };
        const submitData = { ...form, issuedDate:form.issuedDate, decisionDate: form.decisionDate };
        props.client.mutate({
            mutation: CREATE_DOC_FILE,
            variables: { doc: submitData }
        }).then(result => {
            if (authCheck(result.data)) {
                const docId = result.data.response.data._id
                if (docId) {
                    // console.log("continue process appendixes")
                    createAppendix(docId)
                }
            }
        })

    }
    function createAppendix(docId) {
        props.client.query({
            query: CREATE_APPENDIX,
            variables: { docId: docId, data: appendixes }
        }).then(result => {
            if (authCheck(result.data)) {
                BaseControl.showSuccessToast("Thêm mới văn bản thành công!")
                props.history.push(`/qtqd-detail/${docId}`)
            }
        })
    }
    function findSelectedDepartments() {
        const value = departments.filter(option => form.relateDepartmentIds && form.relateDepartmentIds.includes(option.value));
        return value;
    }
    return (
        <div>
            <PageTitle onClick={e => { window.history.back() }} title={"THÊM VĂN BẢN MỚI"} subTitle={parentName}></PageTitle>
            <div id="input" style={{ maxWidth: "1280px", backgroundColor: "transparent", margin: "auto", marginTop: "35px", marginBottom: "30px", color: "#4E5357", fontSize: "16px", padding: "10px" }}>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Tên văn bản</span>
                            </Col>
                            <Col sm={10}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="name" id="txtName" placeholder="Tên văn bản" value={form.name} onChange={handleChange} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Mã tài liệu</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="fileNumber" id="txtMaTaiLieu" placeholder="Mã tài liệu" value={form.fileNumber} onChange={handleChange} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Phiên bản</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="version" id="txtPhienBan" placeholder="Phiên bản" value={form.version} onChange={handleChange} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* //Row 3 */}
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Khoa phòng</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex"}}>
                                    <div style={{ width: "100%" , zIndex: 10 }}>
                                        <Select
                                            // value={}
                                            onChange={value => {
                                                setInForm('department', value && value.label);
                                                setInForm("departmentId", value && value.value);
                                            }}
                                            options={departments}
                                            placeholder={"Chọn khoa phòng"}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Ngày ban hành</span>
                            </Col>
                            <Col sm={8}>
                                <DayPickerInput
                                    style={{ width: "100%" }}
                                    value={form.issuedDate}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    format="DD/MM/YYYY"
                                    placeholder={`${formatDate(new Date())}`}
                                    onDayChange={day => {
                                        if (day) {
                                            setInForm('issuedDate', day)
                                        }
                                    }}
                                    component={props => <Input {...props} />}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Phòng ban liên quan</span>
                            </Col>
                            <Col sm={10}>
                                {/* <Input type="text" name="issuedBy" placeholder="Người ban hành" value={form.issuedBy} onChange={handleChange} /> */}
                                <div style={{ display: "flex"}}>
                                    <div style={{ width: "100%" ,zIndex:9 }}>
                                        <Select
                                            value={
                                                findSelectedDepartments()
                                            }
                                            onChange={value => {
                                                if (value&&value.find(v=>v.value=="*")) {
                                                    setInForm('relateDepartmentIds', departments.map(v => v.value));   
                                                } else {
                                                    setInForm('relateDepartmentIds', value && value.map(v => v.value));
                                                }
                                            }}
                                            options={[{value:"*",label:"Chọn hết"},...departments]}
                                            placeholder={"Chọn khoa phòng"}
                                            isClearable={true}
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>
                {/* //Row 4 */}
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Quyết định</span>
                            </Col>
                            <Col sm={10} style={{ display: "flex" }}>
                                <div style={{ display: "flex" }}>
                                    <span style={{ marginTop: "8px", marginRight: "10px" }}>Số</span>
                                    <Input type="text" name="decisionNumber" id="txtSo" placeholder="Số" value={form.decisionNumber}
                                        onChange={handleChange} />
                                </div>
                                <div style={{ width: "300px", marginLeft: 15, display: "flex" }}>
                                    <div style={{ marginTop: "8px", marginRight: 10 }}>Ngày</div>
                                    <DayPickerInput
                                        // style={{width:"100%"}}
                                        value={form.decisionDate || ""}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        format="DD/MM/YYYY"
                                        placeholder={`${formatDate(new Date())}`}
                                        onDayChange={day => {
                                            if (day) {
                                                setInForm('decisionDate', day)
                                            }
                                        }}
                                        component={props => <Input {...props} />}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* //row upload file */}
                <Row>
                    <Col sm={5}>
                        <div style={{ marginTop: "20px", display: "flex", animationName: "stretch", animationDuration: "2.5s" }}>
                            <UploadFileForm server={window.env.UPLOADURL}
                                onUpdateFiles={
                                    fileId => {
                                        setInForm('preFileId', fileId);
                                    }
                                }
                                title="chữ ký/dấu (tùy chọn)"
                                allowMultiple={false}
                                maxFiles={1}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </Col>
                    <Col sm={1}></Col>
                    <Col sm={5}>
                        <div style={{ marginTop: "20px", display: "flex", animationName: "stretch", animationDuration: "2.5s" }}>
                            <UploadFileForm
                                title="Nội dung văn bản"
                                server={window.env.UPLOADURL}
                                onUpdateFiles={fileId => {
                                    setInForm("fileId", fileId);
                                }}
                                allowMultiple={false}
                                maxFiles={1}
                                style={{ width: "100%" }} />
                        </div>
                    </Col>
                </Row>
                <Label>Chú ý: Nếu tài liệu này gồm nhiều file, hệ thống sẽ tự động ghép các file văn bản được tải lên thành 1 file pdf.</Label>

                {/* //Row phu luc - bieu mau - dinh kem */}
                <Row style={{ marginTop: "20px" }}>

                    <Col sm={6}><span style={{ color: "#4E5357", fontWeight: "bold", fontSize: "18px" }}>Phụ lục - Biểu mẫu - Đính kèm</span></Col>
                    <Col sm={6} style={{ textAlign: "right" }}>
                        <Button onClick={e => { setOpenBieuMauForm(true) }} color="success"
                            style={{ fontSize: "15px", }}>Thêm mới
                            </Button>
                    </Col>
                    <UpdateBieuMauForm isOpen={openBieuMauForm}
                        isUpdate={false}
                        server={window.env.UPLOADURL}
                        onSuccess={(appendix, isUpdate, index) => {

                            appendixes.push(appendix)
                            setOpenBieuMauForm(false);
                            setAppendixes([...appendixes]);
                        }}
                        onCancel={e => setOpenBieuMauForm(false)} />

                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col sm={12}>
                        <BieuMauTable parentId={""}
                            data={appendixes}
                            pageSize={5}
                            page
                            canEdit={true}
                            canDownload={false}
                            onUpdateItem={handleUpdateAppendix}
                            onDeleteItem={handleDeleteAppendix}
                            onDownloadItem={handleDownloadItem}
                            onDetail={handleShowDetail}>

                        </BieuMauTable>
                        <UpdateBieuMauForm isOpen={openBieuMauEditForm}
                            key={"updateForm"}
                            isUpdate={true}
                            index={appendixUpdateIndex}
                            // docCode={updateDocCode}
                            // docName={updateDocName}
                            // fileId={updateDocFileId}
                            server={window.env.UPLOADURL}
                            onSuccess={(e, isUpdate, index) => {
                                // console.log("update biểu mẫu: ", e)
                                const fileId = e.fileId
                                // console.log("===> create appendix fileId: ", fileId)
                                var appendix = { name: e.docName, code: e.docCode, fileId: fileId, }
                                appendixes.splice(index, 1, appendix)
                                // setState({ openBieuMauEditForm: false, appendixes })
                                setOpenBieuMauEditForm(false);
                                setAppendixes([...appendixes]);
                            }}
                            onCancel={e => setOpenBieuMauEditForm(false)}>
                        </UpdateBieuMauForm>
                    </Col>
                </Row>

                {/* //Row button */}
                <Row style={{ textAlign: "right" }}>
                    <div style={{ width: "100%", textAlign: "right", marginTop: "25px", marginRight: "15px" }}>
                        <Button onClick={handleAccept} color="success" style={{ fontSize: "15px" }}>XÁC NHẬN</Button>
                        <Button onClick={e => { window.history.back() }} color="danger" style={{ fontSize: "15px", marginLeft: "15px" }}>HỦY</Button>
                    </div>
                </Row>
            </div>
        </div>
    )

}
export default withApollo(AddQTQDPages)
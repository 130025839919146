import React from "react";
import { getToken } from "Constants";
import { withApollo } from "react-apollo";
import authCheck from 'utils/authCheck';
import * as iconLib from 'assets/img/icon';
import { QUERY_SIDEMENU } from './query'
import folder_icon from 'assets/icons/folder_icon.png'
import group_icon from 'assets/icons/group_icon.png'
// import "styles/custom.css"
import rules_icon from 'assets/icons/rules_icon.png'
import logo from 'assets/icons/logo.png'
import {
  MdKeyboardArrowDown,
  MdExitToApp,
  MdVerifiedUser,
  MdSettings,
  MdAccountCircle,
  MdEmail,
  MdMultilineChart,
  MdWork,
  MdHome,
  MdSystemUpdate,
  MdCall,
  MdBusiness,
  MdDescription,
  MdBook,
  MdAdd,
  MdFolder
} from "react-icons/lib/md";

import { NavLink } from "react-router-dom";
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  NavItem,
  NavLink as BSNavLink
} from "reactstrap";
import bn from "utils/bemnames";


export const icon_style = {
  width: 24,
  height: 24,
  marginTop: -3,
  marginRight: 10
}
export const svgicon_style = {
  marginTop: -3,
  marginRight: 5,
  marginLeft: 5
}

export const custom_icon = {
  marginTop: -3,
  marginRight: 5,
  marginLeft: 5,
  objectFit: 'contain'
}
export const custom_icon2 = {
  marginTop: -3,
  marginRight: 5,
  marginLeft: 5,
  objectFit: 'contain'
}

const IconTranslate = ({ icon, ...restProps }) => {

  switch (icon) {
    case "MdBusiness":
      return <MdBusiness className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdCall":
      return <MdCall className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdMultilineChart":
      return <MdMultilineChart className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdSystemUpdate":
      return <MdSystemUpdate className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdKeyboardArrowDown":
      return <MdKeyboardArrowDown className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdExitToApp":
      return <MdExitToApp className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdVerifiedUser":
      return <MdVerifiedUser className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdSettings":
      return <MdSettings className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdAccountCircle":
      return <MdAccountCircle className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdEmail":
      return <MdEmail className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdWork":
      return <MdWork className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdHome":
      return <MdHome className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdBook":
      return <MdBook className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdDescription":
      return <MdDescription className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdAdd":
      return <MdAdd className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "MdFolder":
      return <MdFolder className={bem.e("nav-item-icon")} style={svgicon_style} />;
    case "folder_icon":
      return <img src={folder_icon} className={bem.e("nav-item-icon")} style={custom_icon} />;
    case "group_icon":
      return <img src={group_icon} className={bem.e("nav-item-icon")} style={custom_icon2} />;
    case "rules_icon":
      return <img src={rules_icon} className={bem.e("nav-item-icon")} style={custom_icon} />;
    default:
      return <img src={iconLib[icon]} style={icon_style} />;
  }
};

const navSettings = [
  { to: '/profile', name: 'Tài khoản', exact: false, Icon: MdVerifiedUser },
  // {to:'/setting', name: 'Cài đặt',exact:false,Icon: MdSettings},
  { to: '/login', name: 'Đăng xuất', exact: false, Icon: MdExitToApp },
]

const bem = bn.create("sidebar");
const flattenDirectory = (data) => {
  data.forEach(function (item) {
    item.to = `/${item.slug && item.slug !== null ? item.slug : "slug"}/${item.to.replace(/\//g, "*")}`;
    if (item.children && item.children !== null) {
      flattenDirectory(item.children);
    }
  })
}

class Sidebar extends React.Component {
  state = {
    check_menu: [],
    menu: []
  };
  token = {};
  constructor(props) {
    super(props);
    this.token = getToken();

  }
  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      //close all others
      const currentState = {};
      if (!isOpen) {
        navSettings.forEach(function (menu) {
          if (menu !== name) {

            currentState[`isOpen${menu}`] = false;
          }
        })
      }
      currentState[`isOpen${name}`] = !isOpen
      return currentState;

    });
  };
  componentDidMount() {
    this.props.client.query({
      query: QUERY_SIDEMENU
      // variables: { filtered: [{ id: "ParentPath", value: "", operation: "==" }], sorted: [{ id: "priority", desc: true }] }
    }).then(result => {
      console.log("load menu result=",result)
      if (!authCheck(result))
        return;
      var check_menu = this.state.check_menu;
      if (result.data.response.data)
        result.data.response.data.forEach(function (item) {
          if (item.name == "NỘI QUY") {
            check_menu.push(item)
          }
        })
      this.setState({ check_menu })
      var menu = result.data.response.data;
      console.log("menu=",menu)
      this.setState({ menu });
    });
  }
  toggleSideBar = () => {
    // document.querySelector(".cr-sidebar").classList.toggle("cr-sidebar--open");
  };
  render() {
    return (
      <aside className={bem.b()} style={{ paddingTop: 10 }}>
        <div className={bem.e("background")} />
        <div className={bem.e("content")} >
          <div style={{ textAlign: "center" }}>
            <img src={logo} alt="logo" style={{ width: "113px", height: "113px", objectFit: "contain", marginTop: "10px", marginBottom: "10px", float: "center", cursor: "pointer" }}></img>
          </div>
          <Nav vertical>
            {/*Menu cấp 1*/}
            {this.state.menu.map((item, index) => item.to === '/' ? null : (

              <div key={index}>
                {
                  item.name == "CÀI ĐẶT" && <hr />
                }
                <NavItem className={bem.e("nav-item")}>
                  <BSNavLink style={{ fontSize: "12px", color: "#4E5357" }}
                    id={`navItem-${item.name}-${index}`}
                    className={bem.e("nav-item-collapse")}
                    tag={NavLink}
                    to={(!item.children || item.children.length === 0) ? item.to : "#"}
                    activeClassName="active"
                    onClick={e => {
                      if (item.children === undefined || item.children.length === 0) {
                        this.toggleSideBar();
                      } else {
                        item.isOpen = !item.isOpen;
                      }
                    }}
                    exact={false}
                  >
                    {/* bao gồm icon + name +mdarrow mở rộng nếu có thư mục con */}
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div className="d-flex">
                        <IconTranslate icon={item.icon} />
                        <span className=" align-self-start">{item.name}</span>
                      </div>

                      {!item.children || item.children.length === 0 ? (
                        ""
                      ) : (
                          <div>
                            <MdKeyboardArrowDown
                              className={bem.e("nav-item-icon")}
                              style={{
                                padding: 0,
                                transform: item.isOpen
                                  ? "rotate(0deg)"
                                  : "rotate(-90deg)",
                                transitionDuration: "0.3s",
                                transitionProperty: "transform"
                              }}
                            />
                          </div>
                        )}
                    </div>
                  </BSNavLink>
                </NavItem>
                {/*Menu cấp 2*/}
                {!item.children ? (
                  ""
                ) : (
                    <Collapse isOpen={item.isOpen}>
                      {item.children.map((child, idx) => (
                        <div key={idx}>
                          <NavItem className={bem.e("nav-item")}>
                            <BSNavLink style={{  fontSize: "12px", color: "#4E5357" }}
                              id={`navItem-${child.name}-${idx}`}
                              className="nav-item-collapse sub-sidebar"
                              tag={NavLink}
                              to={(!child.children || child.children.length === 0) ? child.to : "#"}
                              activeClassName="active"
                              onClick={e => {
                                if (child.children === undefined || child.children.length === 0) {
                                  this.toggleSideBar();
                                } else {
                                  child.isOpen = !child.isOpen;
                                }
                              }}
                              exact={child.exact}
                            >
                              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <div className="d-flex">
                                  <div style={{ width: "24", height: "16" }}>
                                    <IconTranslate icon={child.icon} />
                                  </div>
                                  <span className=" align-self-start">{child.name}</span>
                                </div>
                                {!child.children || child.children.length === 0 ? (
                                  ""
                                ) : (
                                    <div>
                                      <MdKeyboardArrowDown
                                        className={bem.e("nav-item-icon")}
                                        style={{
                                          padding: 0,
                                          transform: child.isOpen
                                            ? "rotate(0deg)"
                                            : "rotate(-90deg)",
                                          transitionDuration: "0.3s",
                                          transitionProperty: "transform"
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            </BSNavLink>
                          </NavItem>
                          {/*Menu cấp 3*/}
                          {!child.children ? (
                            ""
                          ) : (
                              <Collapse isOpen={child.isOpen}>
                                {child.children.map((child2, idxx) => (
                                  <div key={idxx}>
                                    <NavItem className={bem.e("nav-item")}>
                                      <BSNavLink style={{ fontSize: "12px", color: "#4E5357", marginLeft: 30 }}
                                        id={`navItem-${child2.name}-${idxx}`}
                                        className="nav-item-collapse"
                                        tag={NavLink}
                                        to={(!child2.children || child2.children.length === 0) ? child2.to : "#"}
                                        activeClassName="active"
                                        onClick={e => {
                                          if (child2.children === undefined || child2.children.length === 0) {
                                            this.toggleSideBar();
                                          } else {
                                            child2.isOpen = !child2.isOpen;
                                          }
                                        }}
                                        exact={child2.exact}
                                      >
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                          <div className="d-flex">
                                            <div style={{ width: "24", height: "16" }}>
                                              <IconTranslate icon={child2.icon} />
                                            </div>

                                            <span className=" align-self-start">{child2.name}</span>
                                          </div>
                                          {!child2.children || child2.children.length === 0 ? (
                                            ""
                                          ) : (
                                              <div>
                                                <MdKeyboardArrowDown
                                                  className={bem.e("nav-item-icon")}
                                                  style={{
                                                    padding: 0,
                                                    transform: child2.isOpen
                                                      ? "rotate(0deg)"
                                                      : "rotate(-90deg)",
                                                    transitionDuration: "0.3s",
                                                    transitionProperty: "transform"
                                                  }}
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </BSNavLink>
                                    </NavItem>
                                    {/* Menu cấp 4 */}
                                    {!child2.children ? (
                                      ""
                                    ) : (
                                        <Collapse isOpen={child2.isOpen}>
                                          <ul >
                                            {
                                              child2.children.map(({ to, name, exact, icon }, idxxx) => (

                                                <div key={idxxx}>
                                                  <NavItem className={bem.e("nav-item")}>
                                                    <BSNavLink
                                                      id={`navItem-${name}-${idxxx}`}
                                                      className="sub-sidebar"
                                                      tag={NavLink}
                                                      to={to}
                                                      activeClassName="active"
                                                      onClick={this.toggleSideBar}
                                                      exact={exact}
                                                    >
                                                      <div>
                                                        <IconTranslate icon={icon} />
                                                        <span className=" align-self-start">{name}</span>
                                                      </div>
                                                    </BSNavLink>
                                                  </NavItem>
                                                </div>
                                              ))}
                                          </ul>
                                        </Collapse>
                                      )
                                    }
                                  </div>
                                ))}
                              </Collapse>
                            )}
                        </div>
                      ))}
                    </Collapse>
                  )}
                {/* Kết thúc menu cấp 2 */}
                {
                  item.to == "/rules" && <hr />
                }
              </div>
            ))}
          </Nav>
          <div style={{ height: 100 }}></div>
        </div>

      </aside>
    );
  }
}

export default withApollo(Sidebar);

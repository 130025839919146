import React, { useState, useEffect } from 'react'
import { withApollo } from 'react-apollo'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import { Card, CardHeader, Button, CardTitle } from 'reactstrap'
import 'moment/locale/vi';
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { GET_FILE_DETAIL, UPDATE_DOC_FILE, DELETE_DOC_FILE } from '../query';
import authCheck from 'utils/authCheck';

import PDFViewer from 'components/Widget/NTD/PDFViewer'
import FileSaver from 'file-saver';
import printJS from 'print-js'

import printIcon from 'assets/icons/print_icon.png'
import downloadIcon from 'assets/icons/download_icon.png'
import editIcon from 'assets/icons/edit_icon.png'
import deleteIcon from 'assets/icons/delete_icon.png'

import { getToken, getUser } from "Constants";
import _ from 'lodash';
import { ViewControl } from './ViewControl';
import { EditControl } from './EditControl';


const axios = require('axios');

function VBPQDetailPage(props) {
    const [user] = useState(getUser());
    const [data, setData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    function handleDownloadFile(pdfUrl, fileName) {

        var token = getToken();
        var accessToken = "";
        try {
            accessToken = token.accessToken;
            axios({
                url: pdfUrl,
                method: 'GET',
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                json: true,
                responseType: 'blob', // important
            }).then((response) => {
                FileSaver.saveAs(new Blob([response.data]), `${fileName}`);
            });
        } catch (err) { }
    }
    //print document
    function handlePrintFile(pdfUrl) {
        var token = getToken();
        var accessToken = "";
        try {
            accessToken = token.accessToken;
            printJS({ printable: `${pdfUrl}?access_token=${accessToken}`, type: 'pdf', showModal: true })
        } catch (err) { }
    }
    useEffect(() => {
        const { docId } = props.match.params;
        if (docId) {
            props.client.query({
                query: GET_FILE_DETAIL,
                variables: { _id: docId }
            }).then(result => {
                if (authCheck(result.data)) {
                    setData(result.data.response.data);
                }
            });
        }
    }, [props])
    function handleDeleteDoc() {

        BaseControl.showConfirmAlert(
            "Thông báo",
            `Bạn có chắc muốn xóa văn bản "${data.name}"?`,
            "Đồng ý",
            "Hủy",
            () => {//success
                try {
                    if (data) {
                        props.client.query({
                            query: DELETE_DOC_FILE,
                            variables: { _id: data._id }
                        }).then(result => {
                            authCheck(result.data)
                            window.history.back()
                        })
                    }
                } catch (error) {
                    console.log("===> delete doc files error: ", error)
                }
            },
            () => {//cancel

            }
        )
    }
    function handleSave(form) {
        setEditMode(false);
        var fields = ["fileNumber", "department", "issuedBy", "issuedDate", "name", "decisionNumber", "decisionDate", 'docType', 'content'];
        props.client.query({
            query: UPDATE_DOC_FILE,
            variables: { doc: _.omit(form, ["createdTime", "extension", "fileUrl", "pdfUrl", "path", "updatedTime", "account", "permissions", "parent", 'type','relateDepartments']), fields: fields }
        }).then(result => {
            if (authCheck(result.data)) {
                BaseControl.showSuccessToast("Cập nhật thành công")
                setData(result.data.response.data);
            }

            // fetchDocumentDetail()
        })
    }

    return (
        <div>
            <PageTitle onClick={props.history.goBack} title="VĂN BẢN PHÁP QUY"></PageTitle>
            {
                data && <div id="info" style={{ width: "100%", backgroundColor: "white", margin: "auto", marginTop: "35px", marginBottom: "40px", color: "#4E5357", fontSize: "14px", borderRadius: "4px", }}>
                    <Card style={{ maxWidth: "1280px", backgroundColor: "white", margin: "auto", marginTop: "35px", color: "#4E5357", fontSize: "14px", borderRadius: "4px" }}>
                        <CardHeader>
                            {
                                editMode ?
                                    <CardTitle style={{ marginTop: "15px" }}>Chỉnh sửa chi tiết</CardTitle>
                                    : <div style={{ textAlign: "left" }}>
                                        <Button onClick={() => handlePrintFile(data.pdfUrl)} outline color="" ><img src={printIcon} alt="print" style={{ objectFit: "cover" }}></img></Button>
                                        <Button outline color="" ><img src={downloadIcon} alt="download" style={{ objectFit: "cover" }} onClick={() => {
                                            handleDownloadFile(data.pdfUrl, data.fileNumber)
                                        }}></img></Button>
                                        {(user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.edit === true)) && <Button onClick={() => setEditMode(true)} outline color="" ><img src={editIcon} alt="edit" style={{ objectFit: "cover" }}></img></Button>}
                                        {(user.roleId == "quan-tri-he-thong" || (data.permission.permission && data.permission.delete === true)) && <Button onClick={handleDeleteDoc} outline color="" ><img src={deleteIcon} alt="delete" style={{ objectFit: "cover" }}></img></Button>}
                                    </div>
                            }
                        </CardHeader>
                        {
                            editMode ? <EditControl data={data} onCancel={() => setEditMode(false)} onSave={handleSave} /> :
                                <ViewControl data={data} />
                        }

                    </Card>
                    {editMode ? null :
                        <Card id="content" style={{ width: "100%", backgroundColor: "white", margin: "auto", marginTop: "35px", color: "#4E5357", fontSize: "16px", borderRadius: "4px" }}>
                            <CardHeader>
                                <CardTitle style={{ marginTop: "15px" }}>NỘI DUNG</CardTitle>
                            </CardHeader>
                            <PDFViewer
                                fileUrl={`${data.pdfUrl}`}
                                downloadEnable={(user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.download))}
                                printEnable={(user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.print))}
                                onDownload={() => {
                                    handleDownloadFile(data.pdfUrl, data.fileNumber)
                                }}
                                onPrint={() => {
                                    handlePrintFile(data.pdfUrl)
                                }}
                                page={1}
                                pages={1}
                                zoom={1.0}
                            >
                            </PDFViewer>
                        </Card>
                    }
                </div>
            }

        </div>
    )
}

export default withApollo(VBPQDetailPage)
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Tooltip, Typography } from '@material-ui/core'
import ReactTable from "react-table-custom";
import Moment from 'moment'

const getDocType = (options) => {

    var type = ""
    try {
        if (options) {
            type = JSON.parse(options).type
        }
    } catch (e) {
        return type
    }

    return type
}
const VBPQTable = ({ parentId, data, pages, pageSize, loading, fetchData, filtered, onFilteredChange, onDetail }) => {
    const width = 1168
    const [page, setPage] = useState(0)

    return <ReactTable
        manual
        noDataText="Không có dữ liệu"
        // filtered={filtered}
        filterable={true}
        onFilteredChange={onFilteredChange}
        defaultSorted={[{ id: "createdTime", desc: true }]}
        data={data}
        pages={pages}
        loading={loading} // Display the loading overlay when we need it
        onFetchData={fetchData} // Request new data when things change
        defaultPageSize={20}
        pageSize={pageSize}
        onPageChange={setPage}
        // className       ="-striped -highlight"
        style={{ color: "#4E5357", }}
        columns={[
            {
                Header: () => (
                    <div style={{ display: "grid" }}>
                        <i className="fa-tasks" /> STT
                    </div>
                ),
                width: 50,
                className: "text-center",
                filterable: false,
                Cell: row => <span>
                    {row.index + 1 + (page * pageSize)}
                </span>
            },
            {
                Header: () => (
                    <div style={{ display: "grid" }}>
                        <span>
                            <i className="fa-tasks" /> LOẠI VĂN BẢN
                        </span>

                    </div>
                ),
                accessor: 'docType',
            },
            {
                Header: () => (
                    <div style={{ display: "grid" }}>
                        <span>
                            <i className="fa-tasks" /> SỐ KÝ HIỆU
                        </span>
                    </div>
                ),
                accessor: "fileNumber",
                Cell: row => <span style={{ textDecoration: "underline", textDecorationColor: "#C6C6C6", cursor: "pointer" }} onClick={() => onDetail(row.original._id)}>{row.value}</span>
            },
            {
                Header: () => (
                    <div style={{ display: "grid" }}>
                        <span>
                            <i className="fa-tasks" /> ĐƠN VỊ BAN HÀNH
                        </span>
                    </div>
                ),

                accessor: "issuedBy",
            },

            {
                Header: () => (
                    <div style={{ display: "grid" }}>
                        <span>
                            <i className="fa-tasks" /> NGÀY BAN HÀNH
                        </span>
                    </div>
                ),
                width: 150,
                filterable: false,
                accessor: "issuedDate",
                Cell: row => <span>{Moment(row.value).utc(7).add(Moment().utcOffset(), 'm').format("DD/MM/YYYY")}</span>
            },
            {
                Header: () => (
                    <div style={{ display: "grid" }}>
                        <i className="fa-tasks" /> NGƯỜI TẠO
                    </div>
                ),
                accessor: "account.base.fullName",
                style: { textAlign: "left" },
                filterable: false,
            },
            {
                Header: () => (
                    <div style={{ display: "grid" }}>
                        <span>
                            <i className="fa-tasks" /> Đã xem
                        </span>
                    </div>
                ),
                width: 80,
                accessor: 'viewers',
                Cell: row =>
                    <Tooltip title={<div>{row.value && row.value.map(r => <Typography style={{fontSize:12}}>{r.readerName}</Typography>)}</div>}>
                        <Typography>
                            {row.value ? row.value.length : 0}
                        </Typography>
                    </Tooltip>
                ,
                sortable: false,
                filterable: false
            },
            {
                Header: () => (
                    <div style={{ display: "grid" }}>
                        <i className="fa-tasks" /> CHỨC NĂNG
                    </div>
                ),
                filterable: false,
                width: 120,
                Cell: row =>
                    <Button outline size="sm" color="success" style={{ fontSize: "13px", }} onClick={() => onDetail(row.original._id)}>Chi tiết</Button>
                // <Button size="ms" color="" onClick={()=>onDetail(row.original._id)}><img src={iconDetail} alt=""></img></Button>
            }
        ]}
    />
}
export default VBPQTable;
import React, { useState } from 'react';
import { Button, ButtonGroup, Col, Row, FormGroup, } from 'reactstrap';
import { Typography, Tooltip, Link } from '@material-ui/core';
import { MdEdit, MdRemove } from 'react-icons/lib/md';
import { useDispatch ,useSelector} from 'react-redux';
import ReactTable from "react-table-custom";
import moment from 'moment'
import { showQuickTableDialog } from 'components/QuickTableDialog';

const columns =
    [
        {
            Header: "Tài khoản",
            accessor: "readerName",
            filterable:true
        },
        {
            Header: "Tên",
            accessor: "reader.base.fullName",
            filterable:true
        },
        {
            Header: "Mã tài khoản",
            accessor: "reader.base.code",
            filterable:true
        },
        {
            Header: "Phòng/ban",
            accessor: "reader.base.department.Name",
            filterable:true
        },
        {
            Header: "Xem gần nhất",
            accessor: "updatedTime",
            Cell: row => <Typography className="text-description">{moment(row.value).format("HH:mm DD/MM/YYYY")}</Typography>
        }
    ];
const QTQDTable = ({ parentId, data, pages, pageSize, loading, fetchData, filtered, onFilteredChange, onDetail }) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    
    function showViewers(selected) {
        dispatch(showQuickTableDialog({
            rootClass: "h-full w-1/2",
            className:"h-full",
            subtitle: selected.name,
            title: "Danh sách đã xem",
            columns,
            initData:{data:selected.viewers,pages:1}
        }))
    }
    return (
        <div>
            <ReactTable
                manual
                noDataText="Không có dữ liệu"
                sortable={false}
                filterable={true}
                filtered={filtered}
                onFilteredChange={onFilteredChange}
                defaultSorted={[{ id: "issuedDate", desc: true }, { id: "createdTime", desc: true }]}
                data={data}
                pages={pages}
                loading={loading} // Display the loading overlay when we need it
                onFetchData={fetchData} // Request new data when things change
                defaultPageSize={20}
                pageSize={pageSize}
                onPageChange={setPage}
                className="table-vbpq"
                style={{ color: "#4E5357" }}
                columns={[
                    {
                        Header: () => (
                            <div style={{ display: "grid" }}>
                                <i className="fa-tasks" /> STT
                            </div>
                        ),
                        width: 50,
                        className: "text-center",
                        filterable: false,
                        Cell: row => <span>
                            {
                                row.index + 1 + (page * pageSize)}
                        </span>
                    },
                    {
                        Header: () => (
                            <div style={{ display: "grid" }}>
                                <i className="fa-tasks" /> Tên văn bản
                            </div>
                        ),
                        accessor: 'name',
                        minWidth: 200,
                        style: { textAlign: "left" },
                        Cell: row => <span style={{ textAlign: "left", paddingLeft: "10px", textDecoration: "underline", textDecorationColor: "#C6C6C6", cursor: "pointer" }} onClick={() => onDetail(row.original._id)}>
                            {row.value}
                        </span>
                    },
                    {
                        Header: () => (
                            <div style={{ display: "grid" }}>
                                <span>
                                    <i className="fa-tasks" /> Phiên bản
                        </span>
                            </div>
                        ),
                        width: 80,
                        accessor: 'version',
                        sortable: true,

                    },
                    {
                        Header: () => (
                            <div style={{ display: "grid" }}>
                                <span>
                                    <i className="fa-tasks" /> Mã tài liệu
                        </span>
                            </div>
                        ),
                        width: 120,
                        accessor: "fileNumber",
                        Cell: row => <span style={{ textAlign: "left", paddingLeft: "10px", textDecoration: "underline", textDecorationColor: "#C6C6C6", cursor: "pointer" }} onClick={() => onDetail(row.original._id)}>
                            {row.value}
                        </span>
                    },

                    {
                        Header: () => (
                            <div style={{ display: "grid", }}>
                                <span>
                                    <i className="fa-tasks" /> NƠI BAN HÀNH
                        </span>

                            </div>
                        ),
                        width: 150,
                        style: { textAlign: "left" },
                        accessor: 'department',
                    },
                    {
                        Header: () => (
                            <div style={{ display: "grid", textAlign: "center" }}>
                                <span>
                                    <i className="fa-tasks" /> NGÀY BAN HÀNH
                        </span>

                            </div>
                        ),
                        width: 120,
                        sortable: true,
                        accessor: "issuedDate",
                        filterable: false,
                        Cell: row => <span >{moment(row.value).utc(7).add(moment().utcOffset(), 'm').format("DD/MM/YYYY")}</span>
                    },
                    {
                        Header: () => (
                            <div style={{ display: "grid", }}>
                                <span>
                                    <i className="fa-tasks" /> NGƯỜI TẠO
                                </span>

                            </div>
                        ),
                        width: 120,
                        style: { textAlign: "left" },
                        accessor: 'account.base.fullName',
                        filterable: false,
                    },
                    {
                        Header: () => (
                            <div style={{ display: "grid" }}>
                                <span>
                                    <i className="fa-tasks" /> Đã xem
                                </span>
                            </div>
                        ),
                        width: 120,
                        accessor: 'viewers',
                        Cell: row =>
                            <Tooltip title="Nhấn để xem danh sách chi tiết">
                                <Link onClick={()=>showViewers(row.original)}>
                                    {row.value ? row.value.length : 0} người xem
                                </Link>
                            </Tooltip>
                        ,
                        sortable: false,
                        filterable: false
                    },
                    {
                        Header: () => (
                            <div style={{ display: "grid" }}>
                                <i className="fa-tasks" /> CHỨC NĂNG
                            </div>
                        ),
                        width: 90,
                        filterable: false,
                        Cell: row => <div>
                            <ButtonGroup>
                                <Button outline onClick={() => onDetail(row.original._id)} color="success" style={{ fontSize: "11px" }}>Chi tiết</Button>
                            </ButtonGroup>
                        </div>
                    }
                ]}
            />

        </div>
    )
}
export default QTQDTable;
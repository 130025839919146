import React, { useEffect, useState } from 'react';
import * as Actions from './actions';
import { getUser } from 'Constants';
import authCheck from 'utils/authCheck';
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { useDispatch, useSelector } from 'react-redux';


export default function Auth({ children, ...props }) {
    ///try to register
    const dispatch = useDispatch();
    const [user] = useState(getUser());
    
    useEffect(() => {
        const cachedDevice = localStorage.getItem('device');        
        if (cachedDevice&&window.location.href.indexOf("/login")<0) {
            //register
            const device = JSON.parse(cachedDevice);
            Actions.register_device({ ...device, userId: user.sub, platform: "WEB" }).then(response => {
                if (authCheck(response.data)) {
                    BaseControl.showSuccessToast("Đã đăng ký nhận thông báo");
                }
            })
        } 
        dispatch(Actions.load_notify());
        dispatch(Actions.get_unread_notify_num());
    },[]);
    return (
        <div>
            {children}
        </div>
    )
}

import React from 'react'
import { Label, Input, Button } from 'reactstrap';
import { Typography, TextField } from '@material-ui/core';
import * as BaseControl from 'utils/PVT/VTBaseControl.js';

class SendFeedbackWidget extends React.Component
{
    state = {
        section     : "",
        subSection  : "",
        comment     : "",
    }
    constructor(props){
        super(props)
        this.setState({

        })
        this.handleSendComment = this.handleSendComment.bind(this)
    }
    handleSendComment()
    {
        const { section, subSection, comment } = this.state
        if(comment.lenght === 0){
            BaseControl.showErrorToast("Vui lòng nhập nội dung góp ý")
            return
        }
        this.props.onSendComment({section:section,subSection:subSection,comment:comment})
        this.setState({
            section     : "",
            subSection  : "",
            comment     : "",
        })
    }
    render(){
        const { section, subSection, comment } = this.state
        return(
            <div style = {{border:"1px solid #E8E9EA",borderRadius:"4px", backgroundColor:"white", paddingBottom:"15px", ...this.props.style}}>
                <div style = {{display:"flex", paddingTop:"20px", marginBottom:"15px"}}>
                    <Label style = {{fontSize:"16px", fontWeight:"bold", color:"#4E5357", marginLeft:"35px", paddingRight:"10px", paddingTop:"10px", textAlign:"left", width:"140px"}}>Nội dung</Label>
                    <Input value={comment} onChange={e=>{this.setState({comment:e.target.value})}}   type="textarea" name="content" id="txtContent" placeholder="Nội dung góp ý" style = {{marginRight:"35px", minHeight:"85px"}}/>
                </div>
                <div style = {{display:"flex", paddingTop:4,paddingLeft:150, textAlign:"left"}}>
                    {/* <Typography style={{ color: "#4E5357" }}>Mục (không bắt buộc)</Typography> */}
                    <TextField value={section}
                        onChange={e => { this.setState({ section: e.target.value }) }}
                        name="muc"
                        helperText="không bắt buộc"
                        placeholder="Mục"
                        variant="outlined"
                        size="small"
                    />

                    {/* <Typography style = {{color:"#4E5357"}}>Tiểu mục (không bắt buộc)</Typography> */}
                    <TextField value={subSection} onChange={e => { this.setState({ subSection: e.target.value }) }}
                        name="tieumuc"
                        placeholder="Tiểu mục"
                        helperText="không bắt buộc"
                        variant="outlined"
                        style={{ paddingLeft: 10 }}
                        size="small"
                    />
                </div>
                <div style = {{textAlign:"right"}}>
                    <Button onClick={this.handleSendComment} color = "success" style = {{fontSize:"18px", marginRight:"35px"}}>Gửi góp ý</Button>
                </div>
            </div>
        )
    }
}
export default SendFeedbackWidget
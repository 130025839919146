import gql from 'graphql-tag';
export const TOTAL_LOGIN=gql`
  query totalLogin{
    response:totalLogin{
      code
      message
      data
    }
  }
`
export const QUERY_SIDEMENU = gql`
{
  response:myMenus(showRootIfNotExist:true,showdir:true){
    code
    message
    data{
      name
      to
      icon
      children{
        name
        to
        icon
        children{
          name
          to
          icon
          children{
            name
            to
            icon
          }
        }
      }
    }
  }
}

`
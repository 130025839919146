import React, { useState,useEffect } from 'react';
import { Card, CardBody, CardHeader, Button, Row, Col, Input } from 'reactstrap';
import { getToken, getUser } from "Constants";
import moment from 'moment';
import _ from 'lodash';
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { GET_FILE_DETAIL, UPDATE_DOC_FILE, DELETE_DOC_FILE } from '../query';
import { client } from 'utils/apolloConnect';
import {  ALL_SLUG_BY_TYPE} from 'GraphQL/PVT/Slug';
import authCheck from 'utils/authCheck';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import UploadFileForm from 'components/Widget/PVT/UploadFileForm'
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/vi';
import { SLUG_DOCUMENT_TYPE, SLUG_DVH } from 'Constants'
import AddSlugForm from 'components/Widget/PVT/AddSlugForm'
import { useForm } from 'hooks';

export function EditControl({ data, onSave, onCancel, ...props }) {
    const { form, setForm, setInForm, handleChange } = useForm(data);
    const [docTypes, setDocTypes] = useState([]);
    const [showAddDocTypeForm, setShowAddDocTypeForm] = useState(false);
    const [showAddDVBHForm, setShowAddDVBHForm] = useState(false);
    const [dvbhs, setDvbhs] = useState([]);
    function fetchtAllDocumentTypes() {
        client.query({
            query: ALL_SLUG_BY_TYPE,
            variables: { type: SLUG_DOCUMENT_TYPE }
        }).then(result => {
            authCheck(result.data)

            if (result.data.response.data) {
                setDocTypes(result.data.response.data.map(item => { return { value: item._id, label: item.name } }));
            }
        })
    }
    function fetchAllDVBHs() {
        client.query({
            query: ALL_SLUG_BY_TYPE,
            variables: { type: SLUG_DVH }
        }).then(result => {
            authCheck(result.data)
            if (result.data.response.data) {
                setDvbhs(result.data.response.data.map(item => { return { value: item._id, label: item.name } }));
            }
        })
    }
    useEffect(() => {
        fetchtAllDocumentTypes();
        fetchAllDVBHs();
    }, []);
    
   
    return (
        <div>
            <CardBody>
                <div style={{ padding: "20px" }}>
                    <Row >
                        <Col >
                            <Row style={{ marginTop: "20px" }}>
                                <Col sm={4} style={{ marginTop: "10px" }}>
                                    <span>Loại văn bản</span>
                                </Col>
                                <Col sm={8}>
                                    <div style={{ display: "flex" }}>
                                        <div className="w-full" style={{pointerEvents: "all"}}>
                                            <Select
                                                value={
                                                    docTypes.find(d=>d.label==form.docType)
                                                }
                                                onChange={value=>setInForm('docType',value&&value.label)}
                                                options={docTypes}
                                                placeholder={"Chọn loại văn bản"}
                                                isClearable={true}
                                            />
                                        </div>
                                        <Button onClick={() => { setShowAddDocTypeForm(true) }} color="success" style={{ fontSize: "14px", marginLeft: "5px" }}>+</Button>
                                        <AddSlugForm isOpen={showAddDocTypeForm}
                                            type={SLUG_DOCUMENT_TYPE}
                                            onSuccess={() => {
                                                fetchtAllDocumentTypes()
                                                setShowAddDocTypeForm(false);
                                            }}
                                            onCancel={() => {
                                                setShowAddDocTypeForm(false);
                                            }}
                                        >
                                        </AddSlugForm>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row style={{ marginTop: "20px" }}>
                                <Col sm={4} style={{ marginTop: "10px" }}>
                                    <span style={{ paddingLeft: "30px" }}>Số hiệu</span>
                                </Col>
                                <Col sm={8}>
                                    <div style={{ display: "flex" }}>
                                        
                                         <Input type="text" name="fileNumber" placeholder="Số hiệu" value={form.fileNumber} onChange={handleChange }/>
                                        
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Row 2 */}
                    <Row >
                        <Col sm={6}>
                            <Row style={{ marginTop: "20px" }}>
                                <Col sm={4} style={{ marginTop: "10px" }}>
                                    <span>Ngày ban hành</span>
                                </Col>
                                <Col sm={8}>
                                    <div style={{ pointerEvents: "all" }}>
                                        <DayPickerInput
                                            style={{ width: "100%", }}
                                            value={moment(form.issuedDate).format('DD/MM/YYYY')}
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            format="DD/MM/YYYY"
                                            placeholder={`${formatDate(new Date())}`}
                                            onDayChange={day => {
                                                if (day) {
                                                    setInForm('issuedDate',day)
                                                }
                                            }}                                           
                                            component={props => <Input {...props} />}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>                        
                    </Row>
                    <Row >
                        <Col sm={6}>
                            <Row style={{ marginTop: "20px" }}>
                                <Col sm={4} style={{ marginTop: "10px" }}>
                                    <span>Đơn vị ban hành</span>
                                </Col>
                                <Col sm={8}>
                                    <div style={{ display: "flex" }}>
                                        <div className="w-full" style={{pointerEvents: "all"}}>
                                            <Select
                                                value={
                                                    dvbhs.find(d=>d.label==form.issuedBy)
                                                }
                                                onChange={value=>setInForm('issuedBy',value&&value.label)}
                                                options={dvbhs}
                                                placeholder={"Chọn đơn vị ban hành"}
                                                isClearable={true}
                                            />
                                        </div>
                                        <Button onClick={() => {
                                            setShowAddDVBHForm(true);
                                        }} color="success" style={{ fontSize: "14px", marginLeft: "5px" }}>+</Button>
                                        <AddSlugForm isOpen={showAddDVBHForm}
                                            type={SLUG_DVH}
                                            onSuccess={() => {
                                                fetchAllDVBHs()
                                                setShowAddDVBHForm(false);
                                            }}
                                            onCancel={() => {
                                                setShowAddDVBHForm(false);
                                            }}
                                        >
                                        </AddSlugForm>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row >
                        <Col >
                            <Row style={{ marginTop: "20px" }}>
                                <Col sm={2} style={{ marginTop: "10px" }}>
                                    <span>Nội dung văn bản</span>
                                </Col>
                                <Col sm={10}>
                                    <div style={{ display: "flex", pointerEvents: "all" }}>
                                        <Input type="textarea" name="content" placeholder="Nội dung văn bản" value={form.content} onChange={handleChange} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </CardBody>

            <Row>
                <Col sm={2}></Col>
                <Col sm={10}>
                    <div style={{ marginTop: "20px", display: "flex" }}>
                        <UploadFileForm
                            server={window.env.UPLOADURL}
                            onUpdateFiles={
                                fileId => {
                                    setInForm('fileId', fileId);
                                }
                            }
                            allowMultiple={false} maxFiles={1}
                            style={{ width: "100%"}}>

                        </UploadFileForm>
                    </div>
                    <div style={{ textAlign: "right", marginTop: "25px", marginRight: "25px", marginBottom: "50px" }}>
                        <Button onClick={()=>onSave(form)} color="success" style={{ fontSize: "15px" }}>XÁC NHẬN</Button>
                        <Button onClick={onCancel} color="danger" style={{ fontSize: "15px", marginLeft: "15px" }}>HỦY</Button>
                    </div>
                </Col>
            </Row>

        </div>
    )
}
import gql from "graphql-tag"

export const CREATE_DIRECTORY=gql`
mutation($dir:DocDirectoryInput!){
  response: createDir(dir:$dir){
    code
    message
  }
}
`
export const QUERY_ROOT_DIR=gql`
query($filtered:[FilteredInput]){
  response: directories(filtered:$filtered){
    code
    message
    data{
      _id
      name
      type
      path
      slug
      parent{
        name
        _id
        path
      }
      children{
        _id
        name
        path
      }
    }
  }
}
`

export const QUERY_DIR=gql`
query($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
  response:directories(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
    code
    message
    pages
    records
    data{
      _id
      name
      path
      createdTime
      priority
      hidden
      parentPath
      slug
      type
        children{
        _id
        name
        path
      }
    }
  }
}
`

export const QUERY_DIRECTORY=gql`
query($_id: String!){
  response:directory(_id: $_id){
    code
    message
    data{
      _id
      hidden
      name
      parentPath
      path
      priority
      slug
      type
      permissions{
        delete
        deleteFeedback
        download
        edit
        feedback
        print
        write
      }
    }
  }
}
`
export const DELETE_DIR = gql`
mutation($_id: String!){
    response: deleteDir(_id: $_id){
        code
        message
    }
}
`
export const UPDATE_DIR = gql`
mutation($dir:DocDirectoryInput!){
    response: updateDir(dir:$dir){
        code
        message
    }
}
`
import React from 'react'
import backIcon from 'assets/icons/back_icon.png'
import { Button } from 'reactstrap'

const BackButton=({onClick, style = null})=>{
    return(
        <Button onClick = {onClick} color="success" style = {{width:"95px", height:"35px", backgroundColor:"#0CA532", borderRadius:"2px", display:"flex", ...style}}>
            <img src={backIcon} alt="back" style = {{width:"18px", height:"17px",objectFit:"cover", marginLeft:"-7px", marginTop:"2px"}}></img>
            <span style = {{color:"white", marginLeft:"3px", marginTop:"3px", fontSize: "12px"}}>QUAY LẠI</span>
        </Button>
    )
}
export default BackButton;
import gql from 'graphql-tag';

export const UPDATE_SHARING = gql`
mutation($sharing: DocShareInput!){
    response: updateSharing(sharing: $sharing){
        code
        message
    }
}
`
export const DELETE_SHARING = gql`
mutation($_id: String!){
    response: deleteSharing(_id: $_id){
        code
        message
    }
}
`

export const QUERY_ROOT_DIR=gql`
query($accountIds:[String]){
  response:directories(filtered:[{id:"parentPath",value:"",operation:"=="}]){
    code
    message
    data{
      _id
      name
      slug
      children(hierarchy:true){
        _id
        name
        children{
          _id
        }
        parent{
          _id
          name
        }
        sharing(accountIds:$accountIds){
          _id
          docId
          to
          type
          permission{
            delete
            edit
            write
            download
            feedback
            deleteFeedback
          }
        }
      }
          
    }
  }
}
`

export const MUTATION_SAVE_ACCOUNT = gql`
  mutation ($account: AccountInput!) {
    response:updateAccount(account: $account) {
      code
      message
    }
  }
`;

export const QUERY_DEPARTMENTS = gql`
{
  response:departments{
    code
    message
    data{
      _id
      name
    }
  }
}
`
export const QUERY_SELECTABLE_ACCOUNT = gql`
query{
	response:authorizeAccounts{
    code
    message
    data{
      sub
      fullName
      code
    }
  }  
}
`
export const QUERY_MENUTREES = gql`
  query AdminQuery{
    response:menutrees{
      data{      
        label:name
        value:_id
        children
        {
          label:name
          value:_id,
          children{
            label:name
            value:_id
          }
        }
      }
    }
          
  }
`;

export const QUERY_ACCOUNT = gql`
  query($_id: String!) {
    response:account(_id: $_id) {
      data{
        _id
        roleName
        roleId
        accountGroups{
          _id
          groupId
          groupName
          isAdmin
        }
        base{
          sub
          address
          birthdate
          code
          department
          email
          fullName
          userName
          gender
          picture
          phoneNumber
          isRoot
          mariaged
        }
        menuIds
      }
    }
  }
`;

export const QUERY_AUTHORIZE_ACCOUNTS = gql`
query($key:String,$page:Int,$pageSize:Int,$departmentId:String,$role:String){
  response:authorizeAccounts(key:$key,page:$page,pageSize:$pageSize,departmentId:$departmentId,role:$role){
    code
    message
    pages
    records
    data{
      sub
      address
      birthdate
      code
      department
      email
      fullName
      userName
      gender
      picture
      phoneNumber
      isRoot
      mariaged
      account{
        roleName
      }
    }
  }
}
`

export const LIST_IMPORTED_ACCOUNT = gql`
query Accounts($page:Int,$pageSize:Int,$sorted:[SortedInput],$filtered:[FilteredInput]){
    response:accounts(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      page
      pages
      data{
          _id
        roleName
        roleId
        accountGroups{
          _id
          groupId
          groupName
          isAdmin
        }
        base{
            address
            birthdate
            code
            department
            email
            fullName
            userName
            gender
            picture
            phoneNumber
            isRoot
            mariaged
        }
        sharing{
          _id
          createdTime
          docId
          permission{
            delete
            deleteFeedback
            download
            edit
            feedback
            print
            write
          }
          publicUrl
          to
          type
        }
      }
    }
  }    
`

export const LIST_ACCOUNT_SHARE_MANAGER = gql`
query Accounts($page:Int,$pageSize:Int,$sorted:[SortedInput],$filtered:[FilteredInput]){
    response:shared_accounts(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      page
      pages
      code
      message
      records
      data{
          _id
        base{
            userName
            department
            gender
            phoneNumber
            email
            fullName
        }
        roleName
        roleId
      }
    }
  }
    
`
export const LIST_ACCOUNT_SHARE_MANAGER_INDEX = gql`
query Accounts($filtered:[FilteredInput]){
    response:shared_accounts(filtered:$filtered){
      code
      message
      data{
        roleName
        departmentName
        roleId
        base{
          userName
            department
            gender
            phoneNumber
            email
            fullName
        }
      }
    }
  }
    
`
import Page from "components/Page";
import React from "react";
import { Button, Input, Row, Col, Label, Form, FormGroup, Card, CardBody, CardHeader, ButtonGroup } from "reactstrap";
import { withApollo } from "react-apollo";
import authCheck from "utils/authCheck";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { ALL_SLUG_BY_TYPE } from 'GraphQL/slug';
import { MdAdd } from "react-icons/lib/md";
import showSlugModal from 'Modals/SlugModal'
import { CREATE_FILE, UPDATE_FILE, GET_FILE_DETAIL,QUERY_DOCUMENT_DETAIL } from 'GraphQL/document'
import moment from 'moment';
import VBPQ from './FileForms/VBPQ'


import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';
  
  import 'moment/locale/vi';

class VBPQEdit extends React.Component {

    state = {
        slugs: [],
        _id: null,
        file: null,
        slug: "",
        options: "",
        name: "",
        content: "",
        publishDate: new Date(),
        fileNumber: "",
        parentId: "",
        parentPath:"",
        parentName:""
    }
    constructor(props) {
        super(props);
    }
    fetchParentDetail=(docId)=>{
        this.props.client.query({
            query:QUERY_DOCUMENT_DETAIL,
            variables:{_id:docId}
        }).then(result=>{
            if(authCheck(result.data.response)){
                var data=result.data.response.data;
                this.setState({parentPath:data.path,parentName:data.name,slug:data.slug})
            }
        })
    }
    fetchAllFileSlug = () => {
        this.props.client.query({
            query: ALL_SLUG_BY_TYPE,
            variables: { type: "file" }
        }).then(result => {
            authCheck(result.data);
            this.setState({ slugs: result.data.response.data });
        })
    }
    fetchFileDetail = (_id) => {
        this.props.client.query({
            query: GET_FILE_DETAIL,
            variables: { _id }
        }).then(result => {
            if (authCheck(result.data.response)) {
                var data=result.data.response.data;
                if(data.publishDate)
                    data.publishDate=moment(data.publishDate).toDate();
                this.setState(data);
            }
        })
    }
    componentDidMount() {
        var parentId = this.props.match.params.parentId;
        var _id = this.props.match.params._id;
        if(parentId){
            this.fetchParentDetail(parentId);
        }        
        if (_id) {
            this.setState({_id});
        }
    }
    

    render = () => {
        const { slug,parentName,_id,parentPath,parentId } = this.state;
        return (
            <Page
                title={"Sửa văn bản"}
                breadcrumbs={[{ name: parentName, active: true }, { name: `Văn bản ${_id}`, active: false }]}
            >
                {
                    slug=="van-ban-phap-quy"?<VBPQ {...{slug,parentId,parentName,parentPath,_id}} { ...this.props}/>:slug
                }

            </Page>
        )
    }
}
export default withApollo(VBPQEdit);
import React from 'react'
import { Button,Typography } from '@material-ui/core'
function PVTTestPage(props) {
    
    return (
        <div style={{ textAlign: "center", }}>
            <Typography>Test Page</Typography>


            <Button variant="contained" color="primary">Show Dialog</Button>
        </div>
    )

}
export default PVTTestPage;
import React, { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import { FormGroup, Button, Input, Row, Col, Label } from 'reactstrap'
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import AddSlugForm from 'components/Widget/PVT/AddSlugForm'
import moment from 'moment';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/vi';
import { useForm } from 'hooks';


import UploadFileForm from 'components/Widget/PVT/UploadFileForm'

import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { CREATE_DOC_FILE } from 'GraphQL/PVT/FileManager';
import { ALL_SLUG_BY_TYPE } from 'GraphQL/PVT/Slug';
import { QUERY_DIRECTORY } from 'GraphQL/directory'
import authCheck from 'utils/authCheck';

import { SLUG_DOCUMENT_TYPE, SLUG_DVH } from 'Constants'

const initialForm = {
    fileNumber: '',
    issuedBy: '',
    docType: '',
    name:''
}
function AddVBPQPages(props) {
    
    const { form, setForm, setInForm, handleChange } = useForm(initialForm);
    const [dir, setDir] = useState(null);
    const [docTypes, setDocTypes] = useState([]);
    const [dvbhs, setDvbhs] = useState([]);
    const [showAddDocTypeForm, setShowAddDocTypeForm] = useState(false);
    const [showAddDVBHForm, setShowAddDVBHForm] = useState(false);
    const fetchDir = (id) => {
        props.client.query({
            query: QUERY_DIRECTORY,
            variables: { _id: `${id}` }
        }).then(result => {
            authCheck(result.data)
            const data = result.data.response.data;
            setDir(data);
            setForm({
                parentPath: data.path,
                parentId: data._id
            });
        })
    }
   
    //fetch data
    function fetchtAllDocumentTypes() {
        props.client.query({
            query: ALL_SLUG_BY_TYPE,
            variables: { type: SLUG_DOCUMENT_TYPE }
        }).then(result => {
            authCheck(result.data)

            if (result.data.response.data) {
                setDocTypes(result.data.response.data.map(item => { return { value: item._id, label: item.name } }));
            }
        })
    }
    function fetchAllDVBHs() {
        props.client.query({
            query: ALL_SLUG_BY_TYPE,
            variables: { type: SLUG_DVH }
        }).then(result => {
            authCheck(result.data)
            if (result.data.response.data) {
                setDvbhs(result.data.response.data.map(item => { return { value: item._id, label: item.name } }));
            }
        })
    }
    useEffect(() => {
        const { id } = props.match.params;
        if (id) {
            fetchDir(id);
        }
    }, [props]);
    useEffect(() => {
        fetchtAllDocumentTypes();
        fetchAllDVBHs();
    },[]);
    
    function handleAccept() {
        // const { files, selectedDate, sohieu, content, selectedDVBH, selectedDocType, parentPath } = this.state;

        if (!form.docType ||form.docType.length==0) {
            BaseControl.showErrorToast("Vui lòng chọn loại văn bản")
            return
        }
        if (!form.issuedBy||form.issuedBy.length==0) {
            BaseControl.showErrorToast("Vui lòng chọn đơn vị ban hành")
            return
        }
        if (!form.issuedDate) {
            BaseControl.showErrorToast("Vui lòng chọn ngày ban hành")
            return
        }
        if (form.fileNumber.length === 0) {
            BaseControl.showErrorToast("Vui lòng nhập số hiệu văn bản")
            return
        }
        if (!form.fileId) {
            BaseControl.showErrorToast("Vui lòng tải tài liệu")
            return
        }
        // const submitData = {
        //     ...form,issuedDate:moment(form.issuedDate).format("YYYY-MM-DD"),slug:"vbpqs"
        // }
        const submitData = {
            ...form,issuedDate:form.issuedDate,slug:"vbpqs"
        }
        props.client.query({
            query: CREATE_DOC_FILE,
            variables: { doc: submitData }
        }).then(result => {
            authCheck(result.data)
            BaseControl.showSuccessToast("Thêm mới văn bản thành công!")
            props.history.push(`/vbpq-detail/${result.data.response.data._id}`)
        })
    }
    function handleCancel() {
        BaseControl.showConfirmAlert(
            "Thông báo",
            "Bạn có chắc muốn hủy bỏ thao tác tạo văn bản?",
            "Đồng ý",
            "Hủy",
            () => {//success
                window.history.back()
            },
            () => {//cancel

            }
        )
    }

    // const documentTypes = ["Công văn","Thông tư", "Quyết định","Nghị định","Thông báo","Hướng dẫn","Báo cáo","Chỉ thị","Kế hoạch","Thông cáo" ]

    // const { selectedDocType, selectedDVBH, selectedDate, sohieu, content, docTypes, dvbhs,} = this.state
    // const { name } = this.state
    return (
        <div>
            {
                dir && <PageTitle onClick={e => { window.history.back() }} title={"THÊM MỚI VĂN BẢN PHÁP QUY"} subTitle={dir.name}></PageTitle>
            }
            <div id="input" style={{ width: "100%", backgroundColor: "transparent", margin: "auto", marginTop: "35px", color: "#4E5357", fontSize: "16px", padding: "10px" }}>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Loại văn bản</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "90%",zIndex:10 }}>
                                        <Select
                                            value={
                                                docTypes.find(d => d.label == form.docType)
                                            }
                                            onChange={(value)=>setInForm('docType',value&&value.label)}
                                            options={docTypes}
                                            placeholder={"Chọn loại văn bản"}
                                            isClearable={true}
                                        />
                                    </div>
                                    <Button onClick={e => setShowAddDocTypeForm(true)} color="success" style={{ fontSize: "14px", marginLeft: "5px" }}>+</Button>
                                    <AddSlugForm isOpen={showAddDocTypeForm}
                                        type={SLUG_DOCUMENT_TYPE}
                                        onSuccess={() => {
                                            fetchtAllDocumentTypes();
                                            setShowAddDocTypeForm(false);
                                        }}
                                        onCancel={() => setShowAddDocTypeForm(false)}
                                    >
                                    </AddSlugForm>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Đơn vị ban hành</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "90%" ,zIndex:10 }}>
                                        <Select
                                            name="issuedBy"
                                            value={
                                                dvbhs.find(d=>d.label==form.issuedBy)
                                            }
                                            onChange={value=>setInForm('issuedBy',value&&value.label)}
                                            options={dvbhs}
                                            placeholder={"Chọn đơn vị ban hành"}
                                            isClearable={true}
                                        />
                                    </div>
                                    <Button onClick={e => setShowAddDVBHForm(true)} color="success" style={{ fontSize: "14px", marginLeft: "5px" }}>+</Button>
                                    <AddSlugForm isOpen={showAddDVBHForm}
                                        key={"dvbh"}
                                        type={SLUG_DVH}
                                        onSuccess={() => {
                                            fetchAllDVBHs();
                                            setShowAddDVBHForm(false);
                                        }}
                                        onCancel={() => setShowAddDVBHForm(false)}
                                    >
                                    </AddSlugForm>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* row 2  */}
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Số hiệu</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="fileNumber" placeholder="Số hiệu" value={form.fileNumber} onChange={handleChange} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Ngày ban hành</span>
                            </Col>
                            <Col sm={8}>
                                <DayPickerInput
                                    style={{ width: "100%" }}
                                    value={form.issuedDate}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    format="DD/MM/YYYY"
                                    placeholder={`${formatDate(new Date())}`}
                                    onDayChange={day => {
                                        if (day) {
                                            setInForm("issuedDate", day);
                                        }
                                    }}
                                    component={props => <Input {...props} />}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Row 3 */}
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Nội dung văn bản</span>
                            </Col>
                            <Col sm={10}>
                                <div style={{ display: "flex" }}>
                                    <Input type="textarea" name="content" placeholder="Nội dung văn bản" value={form.content} onChange={handleChange} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={10}>
                        <div style={{ marginTop: "20px", display: "flex", animationName: "stretch", animationDuration: "2.5s" }}>
                            <UploadFileForm
                                server={window.env.UPLOADURL}
                                onUpdateFiles={
                                    fileId => {
                                        setInForm('fileId', fileId);
                                    }
                                }
                                allowMultiple={false}
                                maxFiles={1}
                                style={{ width: "100%"}}>
                            </UploadFileForm>
                        </div>
                        <div style={{ textAlign: "right", marginTop: "25px" }}>
                            <Button onClick={handleAccept} color="success" style={{ fontSize: "15px" }}>XÁC NHẬN</Button>
                            <Button onClick={handleCancel} color="danger" style={{ fontSize: "15px", marginLeft: "15px" }}>HỦY</Button>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )

}

export default withApollo(AddVBPQPages);
import React from 'react'
import { Button } from 'reactstrap'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import TutorialTable from 'pages/Documents/FileTables/PVT/TutorialTable'
import {  GET_TUTORIALES,GET_TUTORIAL} from './query';
import authCheck from 'utils/authCheck';
import { withApollo } from 'react-apollo'


class TutorialesPage extends React.Component{
    state = {
        parentId    : "", 
        name        : "",
        parentPath  : "",
        parentName  : "",
        id          : "",
        pages       : 1,
        pageSize    : 20,
        data        : [],
        loading     : false,
        filtered    : [],
    }
    constructor(props){
        super(props)
        this.setState({

        })
        this.onShowDetail   = this.onShowDetail.bind(this)
        this.onEditArticle = this.onEditArticle.bind(this)
        this.fetchData      = this.fetchData.bind(this)
        this.handleFilterdChange   = this.handleFilterdChange.bind(this)
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        this.fetchData({filtered:[], sorted:[], pageSize: 20, page:0})
    }

    componentWillReceiveProps(props) {
    }

    fetchData(state){
        const {filtered, pageSize, sorted, page} = state
        this.props.client.query({
            query       : GET_TUTORIALES,
            variables   : {  filtered:  filtered , pageSize:pageSize, sorted: sorted, page: page}
        }).then(result =>{
            this.setState({loading:false})
            if(authCheck(result.data)){
                console.log("==> tutorial: ", result.data.response.data)
                if(result.data&&result.data.response&&result.data.response.data){
                    this.setState({
                        data    :result.data.response.data,
                        pages   :result.data.response.pages,
                        pageSize: pageSize
                    })
                }
            }
        })
    }

    onShowDetail(docId){
        this.props.history.push(`/article/${docId}`)
    }
    onEditArticle(docId)
    {        
        this.props.history.push(`/article-edit/${docId}`)
    }

    handleFilterdChange(filter){
        console.log("filter change: ", filter)
        this.setState({filtered:filter})
    }
    render(){
        const { id, data, pages,filtered, pageSize,loading  } = this.state
        return(
            <div style = {{textAlign:"center"}}>
                <PageTitle title={"HƯỚNG DẪN SỬ DỤNG"} subTitle={""}></PageTitle>
                <div style={{maxWidth:"1280px", margin:"auto"}}>
                    <div style = {{textAlign:"left"}}>
                        <Button color = "success" style = {{fontSize:"14px", marginTop:"10px", marginLeft:"10px"}} onClick = {e => this.props.history.push(`/article-add`)}>Thêm mới</Button>
                    </div>
                    <div style = {{marginTop:"20px"}}>
                        <TutorialTable 
                            data = {data} 
                            pages = {pages} 
                            pageSize={pageSize} 
                            loading={loading} 
                            fetchData={this.fetchData} 
                            filtered={filtered}
                            onFilteredChange={this.handleFilterdChange}
                            onDetail = {this.onShowDetail}
                            onEditArticle = {this.onEditArticle}
                        >
                        </TutorialTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withApollo(TutorialesPage);
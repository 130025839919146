import history from '@history';
export const AUTH_TOKEN = 'auth-token'
export const USER = "user-info";
export const USER_ID = "user-id";
export const LINKS_PER_PAGE = 10
export const apiHost = "api.host95.elsaga.net";

export function getUser() {
    try {
        return JSON.parse(localStorage.getItem(USER));
    } catch (err) {
        return null;
    }
}
export function setUser(user) {
    if (user) {
        localStorage.setItem(USER, JSON.stringify(user));
    }
}
export const getToken = () => {
    const tokenStr = localStorage.getItem(AUTH_TOKEN);
    return tokenStr;
}
export const setToken = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
}

export const setUserId = (userId) => {
    localStorage.setItem(USER_ID, userId)
}
export const getUserId = () => {
    const userId = localStorage.getItem(USER_ID);
    return userId;
}

export const SignOut = () => {
    console.log("signout from adminConstant");
    // localStorage.setItem(AUTH_TOKEN, undefined);
    localStorage.removeItem(AUTH_TOKEN);
    if (!history.location.pathname.startsWith('/login'))
        window.location.href = '/login';
}
//bat buoc phai co thuoc tinh nay de react-ndn,react-tag hoat dong
export const ItemTypes = {
    KNIGHT: 'knight'
};

//trongpv
export const SLUG_DOCUMENT_TYPE = "slug_document_type"
export const SLUG_DVH = "slug_dvbh"
export const SLUG_DEPARTMENT = "slug_departments"
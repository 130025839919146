//ham nay chuyen xu ly loi
import confirm from 'components/Confirmation';
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import history from '@history';

const authCheck=(data)=>{
    
    if(!data) return false;
    if(data.data)
        data=data.data;
    if(data.response)
        data=data.response;
    if(!data.code)
        return true;
    if (data.code == 2) {        
        BaseControl.showErrorToast(data.message);
        window.location.href = '/login';
        // history.push('/login');
        
    }else if(data.code!=0){
        // window.location.href = '/login';
        confirm(data.message,{hideCancel:true,title:""});
        // return false;
    }else
    return true;
}
export default authCheck
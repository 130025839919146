import React, { Fragment } from "react";
import { Row, Col, Input, CardBody, Label, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Button } from 'reactstrap'
import { withApollo } from "react-apollo";
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import authCheck from "utils/authCheck";
import confirm from 'components/Confirmation';
import { MdAdd, MdDelete, MdSave, MdArrowBack } from 'react-icons/lib/md'
import ToggleButton from "react-toggle-button";
import showSlugModal from 'Modals/SlugModal'
import { ALL_SLUG_BY_TYPE } from 'GraphQL/slug';
import { CREATE_DIRECTORY, QUERY_ROOT_DIR, QUERY_DIRECTORY, UPDATE_DIR } from "GraphQL/directory";
export const array_priority = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];


class DirectoryDetail extends React.Component {
    initState = (isInit) => {
        this.state = {
            _id: null,
            name: "",
            priority: 1,
            hidden: false,
            parentPath: "",
            slug: "",
            slugs: [],
            parentData: []

        }
        if (isInit) {
            this.setState(this.state);
        }
    }
    constructor(props) {
        super(props);
        this.onBack = this.onBack.bind(this)
        this.initState(true);
    }
    componentDidMount() {
        this.fetchArray_Parent();
        var _id = this.props.match.params._id;
        if (_id) {
            this.fetchData(_id);
        }
        //fetch all directory slug here
        this.props.client.query({
            query: ALL_SLUG_BY_TYPE,
            variables: { type: "loai-van-ban" }
        }).then(result => {
            authCheck(result.data);
            this.setState({ slugs: result.data.response.data });
        })
    }
    fetchArray_Parent() {
        const { parentData } = this.state;
        this.props.client.query({
            query: QUERY_ROOT_DIR,
            variables: { filtered: [{ id: "parentPath", value: "", operation: "== || !=" }] }
        }).then(result => {
            authCheck(result);
            if (result.data.response.code == 0) {
                this.setState({ parentData: result.data.response.data })
            }
            else {
                confirm(result.data.response.message, { hideCancel: true });
            }
        })
    }
    fetchData = (_id) => {
        this.props.client.query({
            query: QUERY_DIRECTORY,
            variables: { _id }
        }).then(result => {
            authCheck(result.data);
            if (result.data.response.code == 0) {
                if (result.data.response.data == null) {
                    this.initState();
                }
                else {
                    this.setState(result.data.response.data);
                }
            }
            else {
                this.initState();
            }
        })
    }
    onSave = () => {
        const { _id, name, priority, hidden, parentPath, slug } = this.state;
        if (_id && _id != null) {
            this.props.client.mutate({
                mutation: UPDATE_DIR,
                variables: { dir: { _id, name, priority, hidden, parentPath, slug } }
            }).then(result => {
                authCheck(result.data);
                if (result.data.response.code == 0) {
                    confirm("Cập nhật thư mục thành công, bạn có muốn cập nhật lại không?").then(_ => {
                        this.fetchData(_id);
                    },
                        _ => {
                            this.props.history.goBack();
                        })
                }
                else {
                    confirm(result.data.response.message, { showCancel: false });
                }
            })
        } else {
            this.props.client.mutate({
                mutation: CREATE_DIRECTORY,
                variables: { dir: { _id, name, priority, hidden, parentPath, slug } }
            }).then(result => {
                if (result.data.response.code == 0) {
                    confirm("Thêm mới thư mục thành công! Bạn có muốn tiếp tục thêm không?").then(_ => {
                        this.initState(true);
                        this.fetchArray_Parent();
                        this.props.client.query({
                            query: ALL_SLUG_BY_TYPE,
                            variables: { type: "loai-van-ban" }
                        }).then(result => {
                            authCheck(result.data);
                            this.setState({ slugs: result.data.response.data });
                        })
                    },
                        _ => {
                            this.props.history.goBack();
                        })
                }
                else {
                    confirm(result.data.response.message, { showCancel: false });
                }
            })
        }
    }
    onBack() {
        this.props.history.goBack();
    }
    render = () => {
        const { name, slugs, _id, priority, hidden, parentPath, slug, parentData } = this.state;
        return (
            <div>
                <div style={{ fontSize: "15px" }}>
                    <PageTitle onClick={e => this.onBack} title="CẬP NHẬT THÔNG TIN THƯ MỤC"></PageTitle>
                    <div style={{ marginTop: "20px" }}></div>
                    <CardBody>
                        <Form className="m-4">
                            <FormGroup row>
                                <Label md={3} sm={5} className="text-right">
                                    Tên thư mục
                                </Label>
                                <Col md={6} sm={7}>
                                    <Input type="text" value={name} onChange={e => this.setState({ name: e.target.value })} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3} sm={5} className="text-right">
                                    Loại thư mục
                                </Label>
                                <Col md={6} sm={7}>
                                    {/* <Input type = "text" value = {slug} onChange = {e=>this.setState({slug: e.target.value})}/> */}
                                    <Input type="select" value={slug} onChange={e => { this.setState({ slug: e.target.value }) }}>
                                        <option value="">
                                            Chọn loại thư mục
                                        </option>
                                        {
                                            slugs && slugs.map((item, index) =>
                                                <option key={index} value={item.name}>
                                                    {item.name}
                                                </option>)
                                        }
                                    </Input>
                                </Col>
                                <Col style={{ paddingLeft: 0 }}>
                                    <Button color="success" style={{ marginTop: "2px" }} onClick={e => {
                                        showSlugModal("TẠO LOẠI THƯ MỤC MỚI", { type: "directory" }).then(result => {
                                            console.log("create result=", result);
                                            slugs.splice(0, 0, result);
                                            this.setState({ slugs, slug: result.name });
                                        })
                                    }}><MdAdd style={{ fontSize: "20px" }} /></Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3} sm={5} className="text-right">
                                    Thư mục cha
                                </Label>
                                <Col md={6} sm={7}>
                                    <Input type="select" value={parentPath} onChange={e => this.setState({ parentPath: e.target.value })}>
                                        <option value="">Chọn thư mục cha</option>
                                        {
                                            parentData && parentData.map((item, index) => <option key={index} value={item.path}>{item.parent&&item.parent.name}/{item.name} - {item.slug}</option>)
                                        }
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3} sm={5} className="text-right">
                                    Thứ tự ưu tiên
                                </Label>
                                <Col md={6} sm={7}>
                                    <Input type="select" value={priority} onChange={e => this.setState({ priority: e.target.value })}>
                                        {array_priority.map((item, index) => <option key={index}>{item}</option>)}
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3} sm={5} className="text-right">
                                    Ẩn thư mục
                                </Label>
                                <Col md={6} sm={7} style={{ paddingTop: "10px" }}>
                                    <ToggleButton value={hidden} onToggle={e => this.setState({ hidden: !e })} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3} sm={5}></Label>
                                <Col md={6} sm={7}>
                                    <div style={{ float: "right" }}>
                                        <Button color="success" style={{ fontSize: "16px" }} onClick={this.onSave}>XÁC NHẬN</Button>
                                        <span> </span>
                                        <Button color="danger" style={{ fontSize: "16px" }} onClick={() => this.props.history.push("/dir-manager")}>HỦY</Button>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </div>
            </div>
        );
    }
}
export default withApollo(DirectoryDetail);
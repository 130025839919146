import React, { useState } from 'react';
import { Button, ButtonGroup,Col,Row,FormGroup, Input} from 'reactstrap';
import ReactTable from "react-table-custom";

const TutorialTable=({data,pages, pageSize, loading,fetchData, filtered,onFilteredChange, onDetail, onEditArticle})=>{
    const width = 1168
    const[page,setPage]=useState(0);
    return <ReactTable
        manual
        noDataText="Không có dữ liệu"
        sortable        ={false}
        filterable      ={false}
        filtered        ={filtered}
        onFilteredChange={onFilteredChange}
        data            ={data}
        pages           ={pages}
        loading         ={loading} // Display the loading overlay when we need it
        onFetchData     ={fetchData} // Request new data when things change
        defaultPageSize ={20}
        pageSize        = {pageSize}
        onPageChange    ={setPage}
        className       ="table-vbpq"
        style = {{color:"#4E5357", fontSize:"14px", minHeight:"85px"}}
        columns={[
            {
                Header: () => (
                    <div style = {{marginTop:"10px"}}>
                        <i className="fa-tasks" /> STT
                    </div>
                  ),
                width: 50,
                className: "text-center",
                filterable: false,
                Cell: row => <span>
                    {row.index + 1 + (page * pageSize)}
                </span>
            },
            {
                Header: () => (
                    <div style = {{marginTop:"10px",}}>
                        <i className="fa-tasks" /> TIÊU ĐỀ
                    </div>
                  ),
                accessor: 'name',
                filterable: false,
                style:{textAlign:"left"},
                width:(width)*0.3,
                Cell:row=><span style={{textAlign:"left",paddingLeft:"10px", textDecoration:"underline",textDecorationColor:"#C6C6C6", cursor:"pointer"}} onClick={()=>onDetail(row.original._id)}>
                    {row.value}
                </span>
            },  
            {
                Header: () => (
                    <div style={{display:"grid"}}>
                        <span>
                            <i className="fa-tasks" /> MÔ TẢ
                        </span>
                    </div>
                  ),
                accessor:"shortDesc",
                style:{textAlign:"left"},
                filterable: false,
                Cell: row => <span style={{textAlign:"left",paddingLeft:"10px", textDecoration:"underline",textDecorationColor:"#C6C6C6", cursor:"pointer"}} onClick={()=>onDetail(row.original._id)}>
                     {row.value}
                </span>
            },
            {
                Header: () => (
                    <div style = {{marginTop:"10px"}}>
                        <i className="fa-tasks" /> LOẠI
                    </div>
                  ),
                width:180,
                accessor:"slug",
                Filter: ({ filter, onChange }) => (
                    <Input
                      type="select"
                      onChange={e => onChange(e.target.value)}
                      bsSize="sm"
                      value={filter ? filter.value : "Tất cả"}
                    >
                      <option value="">Tất cả</option>
                      <option value="tutorial">Hướng dẫn</option>
                      <option value="rules">Nội quy</option>
                    </Input>
                  ),
                Cell: row => <div>
                    {
                        (row.value==="tutorial")?"Hướng dẫn":"Nội quy"
                    }
                </div>
            },
            {
                Header: () => (
                    <div style = {{marginTop:"10px"}}>
                        <i className="fa-tasks" /> TRẠNG THÁI
                    </div>
                ),
                accessor:"disable",
                Cell: row => <div>
                    {row.value?"Ẩn":"Hoạt động"}
                </div>
            },
            {
                Header: () => (
                    <div style = {{marginTop:"10px"}}>
                        <i className="fa-tasks" /> CHỨC NĂNG
                    </div>
                  ),
                width:120,
                filterable:false,
                Cell: row => <div>
                    <ButtonGroup>
                        <Button outline onClick={()=>onEditArticle(row.original._id)} color = "success" style = {{fontSize:"13px"}}>Sửa</Button>
                    </ButtonGroup>
                </div>
            }
        ]}
    />
}
export default TutorialTable;
import React from 'react';
import { Button, ButtonGroup,Col,Row,FormGroup} from 'reactstrap';
import { MdEdit, MdRemove } from 'react-icons/lib/md';
import ReactTable from "react-table-custom";
import Moment from 'moment'


const VBPQTable=({parentId,data,pages,onEdit,onDelete})=>{
    return <ReactTable
        manual
        noDataText="Không có dữ liệu"
        sortable={false}
        defaultSorted={[{ desc: true }]}
        data={data}
        sortable={false}
        pages={pages} 
        className="table-document"
        columns={[
            {
                Header: "STT",
                width: 50,
                className: "text-center",
                filterable: false,
                Cell: row => <span>
                    {row.index + 1}
                </span>
            },
            {
                Header: "Loại văn bản", 
                accessor: 'options',
                Cell:row=><span>
                    {row.value&&JSON.parse(row.value).type}
                </span>
            },                          
            {
                Header: "Số kí hiệu",
                accessor: "fileNumber"
            },
            {
                Header: "Ngày ban hành",
                accessor: "issuedDate",
                Cell: row => <span>{Moment(row.value).format("DD/MM/YYYY")}</span>
            },
            {
                Header: "Nội dung văn bản",
                accessor: "content"
            },
            {
                Header: "Tác vụ",
                width:80,
                Cell: row => <div>
                    <ButtonGroup>
                        <Button color="primary" outline size="sm" onClick={e => {onEdit(`/file-edit/${parentId}/${row.original._id}`)}}><MdEdit /></Button>
                        <Button color="danger" outline size="sm" onClick={() => onDelete(row.original._id)}><MdRemove /></Button>
                    </ButtonGroup>
                </div>
            }
        ]}
    />
}
export default VBPQTable;
import React from 'react';
import Page from 'components/Page';
import { Button, ButtonGroup,Col,Row,FormGroup, Input} from 'reactstrap';
import { QUERY_DIR, DELETE_DIR, QUERY_DIRECTORY } from 'GraphQL/directory'
import authCheck from '../../../utils/authCheck';
import { withApollo, Mutation } from 'react-apollo'
import editIcon from 'assets/icons/edit_icon.png'
import deleteIcon from 'assets/icons/delete_icon.png'
import ReactTable from "react-table-custom";
import Moment from 'moment'
import confirm from 'components/Confirmation';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class DirectoryManager extends React.Component {
    state = {
        key: "",
        data:[],
        pages:1,
        parentName: "",
        parentId:"",
    }
    constructor(props) {
        super(props);
    }
    componentWillMount(){
        var _id = this.props.match.params._id;
        if(_id){
            this.state.parentId = _id
            this.fetchName(_id)
        }
    }
    componentDidMount() {
        var _id = this.props.match.params._id;
        if(_id){
            this.state.parentId = _id
            this.fetchName(_id)
        }
    }
    componentWillReceiveProps(props){
        var {parentId} = this.state;
        var _id = props.match.params._id;
        if(_id!==parentId){
            this.state.parentId = _id
            this.fetchData({ page: 0, pageSize: 20, filtered: [], sorted: [{ id: "priority", desc: false }] });
            this.fetchName(_id)
        }
    }
    fetchName(_id){
        this.props.client.query({
            query:  QUERY_DIRECTORY,
            variables: {_id: `${_id}`}
        }).then(result =>{
            authCheck(result);
            if(result.data.response==null){
                this.setState({parentName:""})
            }else{
                this.setState({parentName: result.data.response.data.name})
            }
        })
    }
    reload=()=>{
        this.fetchData({sorted:[{ id: "priority", desc: false }],filtered:[],pageSize:20,page:0})
    }
    fetchData = (state) =>{
        var { page, pageSize,sorted, filtered } = state;
        let { key ,parentId} = this.state;
        filtered = filtered.filter((item)=>{
            return item.id!=="parentPath";
        })
        if(parentId){
            filtered.push({ id: "parentPath", value: `${parentId}$` });
        }else{
            filtered.push({ id: "parentPath", value: "",operation:"==" });
        }
        this.props.client.query({
            query: QUERY_DIR,
            variables: { filtered, sorted, page, pageSize }
        }).then(result =>{
            authCheck(result);
            if(result.data.response.code == 0){
                this.setState({data: result.data.response.data, pages: result.data.response.pages})
            }
            else{
                confirm(result.data.response.message, { hideCancel: true })
            }
        })
    }
    onEdit = (_id) =>{
        this.props.history.push("/DirDetail/"+_id);
    }
    onDelete = (_id, name) => {
        confirm(`Bạn có chắc chắn muốn xóa thư mục ${name}?`, { showCancel: false }).then(kq =>{
            this.props.client.mutate({
                mutation: DELETE_DIR,
                variables: { _id }
            }).then(result =>{
                authCheck(result);
                if(result.data.response.code == 0){
                    confirm(`Đã xóa thư mục ${name} thành công`, { hideCancel: true }).then(_=>{
                        this.reload();
                    })
                }
                else{
                    confirm(result.data.response.message, { showCancel: false })
                }
            })
        })
    }
    render = () => {
        return (
            <Page
                className="CustomerPage">
                <div style = {{textAlign:"center"}}>
                    <h2 style = {{marginTop:"15px",fontWeight:"bold", color:"#08AC50"}}>QUẢN LÝ THƯ MỤC</h2>
                    <div style = {{textAlign:"left"}}>
                        <Button color = "success" style = {{fontSize:"16px", marginTop:"10px", marginLeft:"10px"}} onClick = {e => this.props.history.push(`/DirDetail/new/${this.state.parentId}`)}>Thêm mới</Button>
                    </div>
                </div>
                <div style = {{marginTop:"20px"}}></div>
                <ReactTable
                    manual
                    style= {{color:"#4E5357", fontSize:"14px", minHeight:"80px"}}
                    noDataText="Không có dữ liệu"
                    sortable={false}
                    defaultSorted={[{ id: "priority", desc: false }]}
                    filterable
                    filtered={this.state.filtered}
                    onFilteredChange={filtered => this.setState({ filtered })}
                    defaultPageSize={20}
                    pages={this.state.pages}
                    onFetchData={this.fetchData} 
                    data={this.state.data}
                    className="-striped -highlight"
                    columns={[
                        {
                            Header: "STT",
                            width: 50,
                            className: "text-center",
                            filterable: false,
                            Cell: row => <span>
                                {row.index + 1}
                            </span>
                        },
                        {
                            Header: "Tên thư mục",
                            accessor: "name",
                            Cell:row=><Button color="link" onClick={e=>this.props.history.push(`/dir-manager/${row.original._id}`)}>
                                {row.value}
                            </Button>
                        },                          
                        {
                            Header: "Slug",
                            accessor: "slug",
                            Cell: row=><div style ={{ paddingTop: "3px" }}>{row.original.slug}</div>
                        },
                        {
                            Header: "Ngày tạo",
                            accessor: "createdTime",
                            Cell: row => <div style ={{ paddingTop: "3px" }}>{Moment(row.value).format("DD/MM/YYYY")}</div>,
                            filterable: false
                            // Filter: ({ filter, onChange }) =>
                            //     <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />
                        },
                        {
                            Header: "Độ ưu tiên",
                            accessor: "priority",
                            Cell: row =><div style ={{ paddingTop: "3px" }}>{row.original.priority}</div>
                        },
                        {
                            Header: "Tác vụ",
                            filterable: false,
                            Cell: row => <div style ={{ textAlign: "center" }}>
                                <ButtonGroup>
                                    <Button onClick={() => this.onEdit(row.original._id)} color="" ><img src = {editIcon} alt = "edit" style = {{objectFit:"cover", width: "20px"}}></img></Button>
                                    <Button onClick={() => this.onDelete(row.original._id, row.original.name)} color="" ><img src = {deleteIcon} alt = "delete" style = {{objectFit:"cover", width: "20px"}}></img></Button>
                                </ButtonGroup>
                            </div>
                        }
                    ]}
                />
            </Page>
        );
    }
}
export default withApollo(DirectoryManager);
import React, { useState, useEffect } from 'react'
import { Button, Input } from 'reactstrap'
import Page from 'components/Page';
import ReactTable from "react-table-custom";
import authCheck from 'utils/authCheck';
import 'moment/locale/vi';
import { withApollo } from 'react-apollo';
import { QUERY_DEPARTMENTS, QUERY_AUTHORIZE_ACCOUNTS } from './query'
import { LIST_ROLE_SHORT } from 'GraphQL/role'


const UserManager = (props) => {
    // state = {
    //     key: "",
    //     data: [],
    //     pages: 1,
    //     records: 0,
    //     departments:[],
    //     roles:[]
    // }
    const [departments, setDepartments] = useState([]);
    const [tableFilter, setTableFilter] = useState({ page: 0,pageSize:20 });
    const [pages, setPages] = useState(1);
    // const [] = useState(0);
    const [data, setData] = useState([]);
    // const [] = useState("");
    const [roles,setRole] = useState([]);

    const fetchDepartment = () => {
        props.client.query({
            query: QUERY_DEPARTMENTS
        }).then(result => {
            if (authCheck(result.data.response)) {
                setDepartments(result.data.response.data);
            }
        })
    }
    const fetchRoles = () => {
        
        props.client.query({
            query: LIST_ROLE_SHORT,
            variables: {}
        }).then(result => {
            if (authCheck(result.data.response)) {
                // result.data.response.data.forEach(function (item) {
                //     if (item.id != "quan-tri-he-thong") {
                //         roles.push(item)
                //     }
                // })
                setRole(result.data.response.data);
            }
        })
    }
    useEffect(() => {
        fetchDepartment();
        fetchRoles();
    },[]);
    useEffect(() => {
        fetchData();
    },[tableFilter]);

    function onTableChange(state) {
        const { page, pageSize, filtered } = state;
        console.log("filtered=", filtered);
        const params = {};
        filtered.forEach(function (item) {
            params[item.id] = item.value; 
        });
        setTableFilter({...tableFilter,...params,page,pageSize})
    }
    const fetchData = () => {
        
        props.client.query({
            query: QUERY_AUTHORIZE_ACCOUNTS,
            variables: tableFilter
        }).then(result => {
            if (authCheck(result.data.response)) {
                setData(result.data.response.data);
                setPages(result.data.response.pages);                
            }
        })
    }

    return (
        <Page
            className="CustomePage">
            <div style={{ textAlign: "center" }}>
                <h2 style={{ marginTop: "15px", fontWeight: "bold", color: "#08AC50" }}>QUẢN LÝ TÀI KHOẢN</h2>
                <div style={{ textAlign: "left" }}>
                    <Button color="success" style={{ fontSize: "16px", marginTop: "10px", marginLeft: "10px" }} onClick={() => props.history.push("/user-edit")}>Thêm mới</Button>
                </div>
            </div>
            <div style={{ marginTop: "20px" }}></div>
            <ReactTable
                manual
                noDataText="Không có dữ liệu"
                sortable={false}
                data={data}
                defaultPageSize={20}
                filterable
                pages={pages}
                style={{ color: "#4E5357", fontSize: "14px", minHeight: "80px" }}
                onFetchData={onTableChange}
                className="-striped -highlight"
                columns={[
                    {
                        Header: "STT",
                        width: 50,
                        className: "text-center",
                        filterable: false,
                        Cell: row => <span>
                            {row.index + 1}
                        </span>
                    },
                    {
                        Header: "Tên đăng nhập",
                        accessor: "userName",
                        filterable:false
                    },
                    {
                        id: "key",
                        Header: "Nhân viên",
                        accessor: d => d.title != null ? d.title + "." + d.fullName : d.fullName
                    },                    
                    {
                        Header: "Phòng ban",
                        id:"department._d",
                        accessor: "department.Name",                        
                        type: 'select',
                        options: departments.map(d => ({
                            value:d._id,label:d.name
                        }))
                        // Filter: ({ filter, onChange }) =>
                        //     <Input bsSize="sm" type="select" value={filter ? filter.value : ""} onChange={e => onChange(e.target.value)}>
                        //         <option value="">Chọn phòng ban</option>
                        //         {
                        //             departments.map((item, index) =>
                        //                 <option value={item._id} key={index}>{item.name}</option>)
                        //         }
                        //     </Input>
                    },
                    {
                        Header: "Quyền",
                        id:'role',
                        accessor: "account.roleName",                        
                        type: 'select',
                        options:roles.map(r=>({value:r.id,label:r.name}))
                        // Filter: ({ filter, onChange }) =>
                        //     <Input bsSize="sm" type="select" value={filter ? filter.value : ""} onChange={e => onChange(e.target.value)}>
                        //         <option value="">Chọn quyền</option>
                        //         {
                        //             roles.map((item, index) =>
                        //                 <option value={item.id} key={index}>{item.name}</option>)
                        //         }
                        //     </Input>
                    },
                    {
                        Header: "Chức năng",
                        filterable: false,
                        width: 100,
                        Cell: row => (
                            <div style={{ textAlign: "center", paddingRight: "5px" }}>
                                <Button outline color="success" onClick={() => props.history.push("/user-edit/" + row.original.sub)}>Chi tiết</Button>
                            </div>
                        )
                    }
                ]}
            />
        </Page>
    );

}

export default withApollo(UserManager);
import gql from 'graphql-tag';


export const GET_DOC_COMMENTS=gql`
    query getDocComments($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int)
    {
        response:docComments(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
            code
            message
            page
            pages
            records
            data{
                _id
                account{                                        
                    base{
                        fullName                        
                        department
                    }
                }
                children{
                    _id
                    account{
                        base{
                            fullName
                            department
                        }
                    }
                    comment
                    createdTime
                    docId
                    name
                    parentId
                    section
                    subSection
                    updatedTime                    
                    read
                    docFile{
                        name
                    }
                }
                comment
                createdTime
                docId
                name
                parentId
                section
                subSection
                updatedTime                
                read
                docFile{
                    name
                }
            }
        }
    }
`
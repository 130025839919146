import gql from 'graphql-tag';


export const QUERY_DIRECTORY=gql`
query($_id: String!){
  response:directory(_id: $_id){
    code
    message
    data{
      _id
      hidden
      name
      parentPath
      path
      priority
      slug
      type
      parent{
          _id
          name
          path
      }
      permissions{
        delete
        deleteFeedback
        download
        edit
        feedback
        print
        write
      }
    }
  }
}
`

export const UPDATE_DOC_FILE=gql`
    mutation updateDocFile($doc:DocFileInput!, $fields:[String])
    {
        response:updateDocFile(doc:$doc, fields: $fields){
            code
            message
            data{
                _id
                content
                createdTime
                extension
                fileNumber
                fileUrl
                pdfUrl:fileUrl(ispdf:true)
                fileId
                hidden
                name
                docType
                parentPath
                path
                priority
                issuedBy
                issuedDate
                slug
                type
                updatedTime
                account{
                    base{
                      fullName
                      userName
                    }
                  }
                permissions{
                        delete
                        deleteFeedback
                        download
                        edit
                        feedback
                        print
                        write
                    }
            }
        }
    }
`

export const DELETE_DOC_FILE=gql`
    mutation deleteDocFile($_id:String!)
    {
        response:deleteDocFile(_id:$_id)
        {
            code
            message
        }
    }
`

export const GET_FILE_DETAIL=gql`
    query getFileDetail($_id:String!){
        response:file(_id:$_id){
                code
                message
                data{
                    _id
                    content
                    createdTime
                    extension
                    fileNumber
                    fileUrl
                    pdfUrl:fileUrl(ispdf:true)
                    fileId
                    hidden
                    name
                    parentPath
                    path
                    priority
                    issuedBy
                    issuedDate
                    slug
                    type
                    docType
                    updatedTime
                    version
                    department
                    departmentId
                    relateDepartmentIds
                    relateDepartments{
                      _id
                      name
                    }
                    decisionDate
                    decisionNumber
                    type
                    account{
                        base{
                          fullName
                          userName
                        }
                      }
                    permissions{
                        delete
                        deleteFeedback
                        download
                        edit
                        feedback
                        print
                        write
                    }
                    parent{
                        name
                        _id
                        path
                    }
                }
        }
    }
`

export const GET_ALL_FILES=gql`
    query getAllFiles($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int)
    {
        response:files(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
            code
            message
            page
            records
            pages
            data{
                _id
                viewers{
                  readerName
                }
                content
                createdTime
                extension
                fileNumber
                fileId
                hidden
                name
                options
                parentPath
                path
                priority
                issuedBy
                issuedDate
                slug
                type
                docType
                updatedTime
                version
                department
                departmentId
                decisionDate                
                decisionNumber
                account{
                    base{
                      fullName
                      userName
                    }
                  }
            }
        }
    }
`
import React, { useState, useEffect } from 'react'
import { useForm } from 'hooks';
import { FormGroup, Button, Input, Row, Col, Label, CardBody } from 'reactstrap';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { client } from 'utils/apolloConnect';
import { GET_DEPARTMENTS} from '../query';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/vi';


export function EditForm({ data, onCancel, onSave, ...props }) {
    const { form, setForm, setInForm, handleChange } = useForm(data);
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        client.query({
            query: GET_DEPARTMENTS,
        }).then(result => {
            if (result.data.response.data) {
                setDepartments(result.data.response.data.map(item => ({ value: item._id, label: item.name })))
            }
        })
    },[]);
    function findSelectedDepartments() {
        const value = departments.filter(option => form.relateDepartmentIds && form.relateDepartmentIds.includes(option.value));
        
        return value;
    }
    return (
        <CardBody >
            <div id="input" style={{ maxWidth: "1280px", backgroundColor: "transparent", margin: "auto", marginTop: "35px", marginBottom: "30px", color: "#4E5357", fontSize: "16px", padding: "10px" }}>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Tên văn bản</span>
                            </Col>
                            <Col sm={10}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="name" id="txtName" placeholder="Tên văn bản" value={form.name} onChange={handleChange} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Mã tài liệu</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="fileNumber" id="txtMaTaiLieu" placeholder="Mã tài liệu" value={form.fileNumber} onChange={handleChange} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Phiên bản</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="version" disabled placeholder="Phiên bản" value={form.version} onChange={handleChange} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* //Row 3 */}
                <Row>
                    <Col sm={6}>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Khoa phòng</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex"}}>
                                    <div style={{ width: "100%" ,zIndex:10 }}>
                                        <Select
                                            value={
                                                departments&&departments.find(d=>d.label==form.department)
                                            }
                                            onChange={value => {
                                                setInForm('department', value && value.label);
                                                setInForm('departmentId', value && value.value);
                                            }}
                                            options={departments}
                                            placeholder={"Chọn khoa phòng"}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Ngày ban hành</span>
                            </Col>
                            <Col sm={8}>
                                <DayPickerInput
                                    style={{ width: "100%" }}
                                    value={form.issuedDate}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    format="DD/MM/YYYY"
                                    placeholder={`${formatDate(new Date())}`}
                                    onDayChange={day => {
                                        if (day) {
                                            setInForm('issuedDate', day)
                                        }
                                    }}
                                    component={props => <Input {...props} />}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Phòng ban liên quan</span>
                            </Col>
                            <Col sm={10}>
                                {/* <Input type="text" name="issuedBy" placeholder="Người ban hành" value={form.issuedBy} onChange={handleChange} /> */}
                                <div style={{ display: "flex"}}>
                                    <div style={{ width: "100%" ,zIndex:9 }}>
                                        <Select
                                            value={
                                                findSelectedDepartments()
                                            }
                                            onChange={value => {
                                                if (value&&value.find(v=>v.value=="*")) {
                                                    setInForm('relateDepartmentIds', departments.map(v => v.value));   
                                                } else {
                                                    setInForm('relateDepartmentIds', value && value.map(v => v.value));
                                                }
                                            }}
                                            options={[{value:"*",label:"Chọn hết"},...departments]}
                                            placeholder={"Chọn khoa phòng"}
                                            isClearable={true}
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>
                {/* //Row 4 */}
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Quyết định</span>
                            </Col>
                            <Col sm={10} style={{ display: "flex" }}>
                                <div style={{ display: "flex" }}>
                                    <span style={{ marginTop: "8px", marginRight: "10px" }}>Số</span>
                                    <Input type="text" name="decisionNumber" id="txtSo" placeholder="Số" value={form.decisionNumber}
                                        onChange={handleChange} />
                                </div>
                                <div style={{ width: "300px", marginLeft: 15, display: "flex" }}>
                                    <div style={{ marginTop: "8px", marginRight: 10 }}>Ngày</div>
                                    <DayPickerInput
                                        value={form.decisionDate || ""}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        format="DD/MM/YYYY"
                                        placeholder={`${formatDate(new Date())}`}
                                        onDayChange={day => {
                                            if (day) {
                                                setInForm('decisionDate', day)
                                            }
                                        }}
                                        component={props => <Input {...props} />}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ textAlign: "right" }}>
                    <div style={{ width: "100%", textAlign: "right", marginTop: "25px", marginRight: "15px" }}>
                        <Button onClick={() => onSave(form)} color="success" style={{ fontSize: "15px" }}>XÁC NHẬN</Button>
                        <Button onClick={onCancel} color="danger" style={{ fontSize: "15px", marginLeft: "15px" }}>HỦY</Button>
                    </div>
                </Row>
            </div>
        </CardBody>
    )
}
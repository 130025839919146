import {combineReducers} from 'redux';
import quickdialog from './quickdialog.reducer';
import dialog from './dialog.reducer';
import notify from './notify.reducer';
const createReducer = (asyncReducers) =>
    combineReducers({        
        quickdialog,
        notify,
        dialog,
        ...asyncReducers
    });

export default createReducer;

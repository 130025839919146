import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { confirmable, createConfirmation } from 'react-confirm';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Input, Label, Col } from 'reactstrap'; // your choice.

import { client } from 'utils/apolloConnect'
import { UPDATE_SHARING } from './query'
import authCheck from 'utils/authCheck'
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { green } from '@material-ui/core/colors';
import { QUERY_ACCOUNT } from './query';
import { useForm } from 'hooks';

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);
const intialState = {
    docId: "",
    permission: {
        delete: false,
        deleteFeedback: false,
        download: false,
        edit: false,
        feedback: false,
        print: false,
        write: false
    },
    isEdit: true,
    docName: "",
}

// const { confirmMessage, dismiss, cancel, proceed, options } = props;
//     const { dir, permission, docId, isEdit, docName } = this.state
function SharingModal({ confirmMessage, dismiss, cancel, proceed, options }) {
    const { root, _id } = options;
    const { form, setForm, setInForm, handleChange } = useForm(intialState);
    const [, setIsEdit] = useState(true);
    const { permission, docId } = form;

    function fetchShare(accountId) {
        if (_id) {
            setIsEdit(false);
            client.query({
                query: QUERY_ACCOUNT,
                variables: { _id: accountId }
            }).then(result => {
                result.data.response.data.sharing.forEach(function (item) {
                    if (item._id == options._id) {
                        setForm({ ...form, docId: item.docId, permission: item.permission, docName: item.document.name })
                        // docId = item.docId
                        // permission = item.permission
                        // docName = item.document.name
                    }
                })

            })
        }
    }


    useEffect(() => {
        fetchShare(options.to)
    }, [options.to]);
    const onCreate = () => {
        const { docId, permission } = form;
        if (docId != "") {
            client.mutate({
                mutation: UPDATE_SHARING,
                variables: { sharing: { type: options.type, to: options.to, docId, permission, _id: options._id } }
            }).then(result => {
                if (authCheck(result.data))
                    proceed(result.data.response.data);
            })
        }
        else {
            BaseControl.showErrorToast("Vui lòng chọn phòng ban!")
        }
    }

    return (
        <Modal toggle={dismiss} isOpen={true}>
            <ModalHeader toggle={dismiss} style={{ color: "#28a745", fontWeight: "bolder" }}>{confirmMessage}</ModalHeader>
            <ModalBody>
                {root &&
                    <Form>
                        <FormGroup row>
                            <Label md={4}>CHỌN PHÒNG BAN: </Label>
                            <Col md={8}>
                                {
                                    root.children &&
                                    <Input type="select" value={docId} name="docId" onChange={handleChange}>
                                        <option value="">Chọn thư mục</option>
                                        {
                                            root.children.filter(item=>(item.children.length==0)).map((item, index) =>
                                                <option key={index} value={item._id}>{item.parent._id!=root._id&&(item.parent.name+'/')}{item.name}</option>
                                            )
                                            // root.children.map((item, index) =>
                                            //     <option key={index} value={item._id}>{item.parent._id != root._id && (item.parent.name + '/')}{item.name}</option>
                                            // )
                                        }
                                    </Input>
                                }

                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Label>THÊM</Label>
                                    <GreenCheckbox checked={permission.write == true} onChange={e => { permission.write = e.target.checked ? true : false; setInForm("permission", permission) }} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Label>SỬA</Label>
                                    <GreenCheckbox checked={permission.edit == true} onChange={e => { permission.edit = e.target.checked ? true : false; setInForm("permission", permission) }} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Label>XÓA</Label>
                                    <GreenCheckbox checked={permission.delete == true} onChange={e => { permission.delete = e.target.checked ? true : false; setInForm("permission", permission) }} />
                                </div>
                            </div>
                            {confirmMessage != "PHÂN QUYỀN VBPQ" &&
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Label>TRẢ LỜI GÓP Ý</Label>
                                        <GreenCheckbox checked={permission.feedback == true} onChange={e => { permission.feedback = e.target.checked ? true : false; setInForm("permission", permission) }} />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Label>XÓA GÓP Ý</Label>
                                        <GreenCheckbox checked={permission.deleteFeedback == true} onChange={e => { permission.deleteFeedback = e.target.checked ? true : false; setInForm("permission", permission) }} />
                                    </div>
                                </div>
                            }
                        </FormGroup>

                    </Form>
                }
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button color='success' onClick={onCreate} style={{ fontSize: "15px" }}> XÁC NHẬN </Button>
                    <span> </span>
                    <Button color='danger' onClick={() => cancel && cancel()} style={{ fontSize: "15px" }}> HỦY </Button>{' '}
                </div>
            </ModalFooter>
        </Modal>
    )
}
SharingModal.propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
}
SharingModal.defaultProps = {
    options: {
        type: "",
        to: "",
        _id: ""
    }
}
const confirm = createConfirmation(confirmable(SharingModal));
export default function (confirmMessage, options = {}) {
    // You can pass whatever you want to the component. These arguments will be your Component's props
    console.log("show modal")
    return confirm({ confirmMessage, options });
}
// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
//export default confirmable(Confirmation);

import { EmptyLayout, LayoutRoute, MainLayout } from './Layout';
import LoginPage from 'pages/Login/LoginPage';
import LoginPageTest from 'pages/Login/LoginPageTest';
// pages
import ProfilePage from 'pages/ProfilePage';

import React from 'react';
import componentQueries from 'react-component-queries';
import { Redirect, Switch, BrowserRouter } from 'react-router-dom';
import 'styles/reduction.css';
import "react-table/react-table.css";
// import "react-datepicker/dist/react-datepicker.css"
// import "react-tabs/style/react-tabs.css";
import "pretty-checkbox/src/pretty-checkbox.scss"
import 'react-day-picker/lib/style.css';
// import "react-month-picker/css/month-picker.css"
///using redux store here
import Provider from 'react-redux/es/components/Provider';
import store from './store';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ApolloProvider } from 'react-apollo'
import { client } from 'utils/apolloConnect';
import { getToken } from "Constants";

import DirectoryDetail from 'pages/Setting/Directory/DirectoryDetail';
import DirectoryManager from 'pages/Setting/Directory/DirectoryManager'
import SlugManager from 'pages/Setting/Slug/SlugManager'
import SlugDetail from 'pages/Setting/Slug/SlugDetail'

import DocumentManager from 'pages/Documents/DocumentManager'
import FileEdit from './pages/Documents/FileEdit';
import MenuManager from './pages/Menus/MenuManager';
import MenuEditPage from './pages/Menus/MenuEditPage';

import RoleManager from './pages/Roles/RoleManager';
// import RoleManagerEdit from './pages/Roles/RoleManagerEdit';
import FTypeManager from './pages/Setting/FileType/FTypeManager';
import FTypeDetail from './pages/Setting/FileType/FTypeDetail';
import UnitManager from './pages/Setting/Unit/UnitManager';
import UnitDetail from './pages/Setting/Unit/UnitDetail';
import UserManager from './pages/Setting/User/UserManager';
import UserEdit from './pages/Setting/User/UserEdit';

import PvtTestPdfPage from './pages/PVT/PvtTestPdfPage'
import PVTTestPage from './pages/PVT/PVTTestPage'
import VBPQPages from './pages/PVT/VBPQ/VBPQPages'
import AddVBPQPages from './pages/PVT/VBPQ/AddVBPQPages'
import VBPQDetailPage from './pages/PVT/VBPQ/DetailPage/VBPQDetailPage'
import QTQDPages from './pages/PVT/QTQD/QTQDPages'
import AddQTQDPages from './pages/PVT/QTQD/AddQTQDPages'
import QTQDDetailPage from './pages/PVT/QTQD/DetailPage/QTQDDetailPage'
import DepartmentsPage from './pages/PVT/QTQD/DepartmentsPage'
import DepartmentFilesPage from './pages/PVT/QTQD/DepartmentFilesPage'
import Feedbacks from './pages/PVT/Feedback/Feedbacks';
import ArticleEditPage from './components/Widget/NTD/ArticleEditPage'
import TutorialesPage from './pages/PVT/Tutorial/TutorialesPage';
import ArticleDetailPage from './pages/PVT/Tutorial/ArticleDetailPage'
import RuleDetailPage from './pages/PVT/Tutorial/RulePage'
import SearchPage from './pages/PVT/Search/SearchPage'
import Auth from './pages/Auth';
const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};
class App extends React.Component {

  constructor() {

    super();
    // console.log("load AppCompany");
  }

  render() {
    var token = getToken();
    var accessToken = "";
    try {
      accessToken = token.accessToken;
    } catch (err) { }
    return (

      <BrowserRouter basename={getBasename()}>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <Auth>
              <ToastContainer autoClose={4000} position={"top-right"} />
              <Switch>
                {/* PTV Pages */}
                <LayoutRoute
                  exact
                  path="/pvttest"
                  layout={EmptyLayout}
                  component={PVTTestPage}
                />
                <LayoutRoute
                  exact
                  path="/search/:key"
                  layout={MainLayout}
                  component={SearchPage}
                />
                <LayoutRoute
                  exact
                  path="/pvttestpdf"
                  layout={EmptyLayout}
                  component={PvtTestPdfPage}
                />

                <LayoutRoute
                  exact
                  path="/vbpqs/:id"
                  layout={MainLayout}
                  component={VBPQPages}
                />

                <LayoutRoute
                  exact
                  path="/vbpq-add/:id"
                  layout={MainLayout}
                  component={AddVBPQPages}
                />
                <LayoutRoute
                  exact
                  path="/vbpq-detail/:docId"
                  layout={MainLayout}
                  component={VBPQDetailPage}
                />
                <LayoutRoute
                  exact
                  path="/qtqds/:id"
                  layout={MainLayout}
                  component={QTQDPages}
                />
                {/* vbnd */}
                <LayoutRoute
                  exact
                  path="/vbnbs/:id"
                  layout={MainLayout}
                  component={QTQDPages}
                />
                <LayoutRoute
                  exact
                  path="/vbnbs-add/:id"
                  layout={MainLayout}
                  component={AddVBPQPages}
                />
                <LayoutRoute
                  exact
                  path="/vbnbs-detail/:docId"
                  layout={MainLayout}
                  component={VBPQDetailPage}
                />
                {/* qt-qd */}
                <LayoutRoute
                  exact
                  path="/qtqd-add/:id"
                  layout={MainLayout}
                  component={AddQTQDPages}
                />
                
                <LayoutRoute
                  exact
                  path="/departmentFiles/:departmentName"
                  layout={MainLayout}
                  component={DepartmentFilesPage}
                />

                <LayoutRoute
                  exact
                  path="/qtqd-detail/:docId/:commentId?"
                  layout={MainLayout}
                  component={QTQDDetailPage}
                />
                <LayoutRoute
                  exact
                  path="/feedbacks"
                  layout={MainLayout}
                  component={Feedbacks}
                />
                <LayoutRoute
                  exact
                  path="/article-edit/:_id?"
                  layout={MainLayout}
                  component={ArticleEditPage}
                />
                <LayoutRoute
                  exact
                  path="/article-add"
                  layout={MainLayout}
                  component={ArticleEditPage}
                />
                <LayoutRoute
                  exact
                  path="/tutoriales"
                  layout={MainLayout}
                  component={TutorialesPage}
                />
                <LayoutRoute
                  exact
                  path="/article/:_id"
                  layout={MainLayout}
                  component={ArticleDetailPage}
                >
                </LayoutRoute>
                <LayoutRoute
                  exact
                  path="/rules"
                  layout={MainLayout}
                  component={RuleDetailPage}
                ></LayoutRoute>
                {/* End PVT Pages */}

                <LayoutRoute
                  exact
                  path="/login-test"
                  layout={EmptyLayout}
                  component={props => (
                    <LoginPageTest {...props} />
                  )}
                />
                <LayoutRoute
                  exact
                  path="/login"
                  layout={EmptyLayout}
                  component={props => (
                    <LoginPage {...props} />
                  )}
                />
                <LayoutRoute
                  exact
                  path="/login/:token"
                  layout={EmptyLayout}
                  component={props => (
                    <LoginPage {...props} />
                  )}
                />
                <LayoutRoute
                  exact
                  path="/profile"
                  layout={MainLayout}
                  component={ProfilePage}
                />
                <LayoutRoute
                  exact
                  path="/document/:parentId"
                  layout={MainLayout}
                  component={DocumentManager}
                />
                <LayoutRoute
                  exact
                  path="/document/:slug/:parentId"
                  layout={MainLayout}
                  component={DocumentManager}
                />
                <LayoutRoute
                  exact
                  path="/file-edit/:parentId"
                  layout={MainLayout}
                  component={FileEdit}
                />
                <LayoutRoute
                  exact
                  path="/file-edit/:parentId/:_id"
                  layout={MainLayout}
                  component={FileEdit}
                />
                <LayoutRoute
                  exact
                  path="/file-edit"
                  layout={MainLayout}
                  component={FileEdit}
                />
                <LayoutRoute
                  exact
                  path="/DirDetail"
                  layout={MainLayout}
                  component={DirectoryDetail}
                />
                <LayoutRoute
                  exact
                  path="/dir-manager"
                  layout={MainLayout}
                  component={DirectoryManager}
                />
                <LayoutRoute
                  exact
                  path="/dir-manager/:_id"
                  layout={MainLayout}
                  component={DirectoryManager}
                />
                <LayoutRoute
                  exact
                  path="/DirDetail/:_id"
                  layout={MainLayout}
                  component={DirectoryDetail}
                />
                <LayoutRoute
                  exact
                  path="/DirDetail/new/:parentId?"
                  layout={MainLayout}
                  component={DirectoryDetail}
                />
                <LayoutRoute
                  exact
                  path="/slug-manager"
                  layout={MainLayout}
                  component={SlugManager}
                />
                <LayoutRoute
                  exact
                  path="/menu-manager"
                  layout={MainLayout}
                  component={MenuManager}
                />
                <LayoutRoute
                  exact
                  path="/menu-edit"
                  layout={MainLayout}
                  component={MenuEditPage}
                />
                <LayoutRoute
                  exact
                  path="/menu-edit/:_id"
                  layout={MainLayout}
                  component={MenuEditPage}
                />
                {/* <LayoutRoute
                exact
                path="/account-manager"
                layout={MainLayout}
                component={AccountManager}
              /> */}
                {/* <LayoutRoute
                exact
                path="/account-edit"
                layout={MainLayout}
                component={AccountEdit}
              />
              <LayoutRoute
                exact
                path="/account-edit/:_id"
                layout={MainLayout}
                component={AccountEdit}
              /> */}
                <LayoutRoute
                  exact
                  path="/SlugDetail"
                  layout={MainLayout}
                  component={SlugDetail}
                />
                <LayoutRoute
                  exact
                  path="/role-manager"
                  layout={MainLayout}
                  component={RoleManager}
                />
                {/* <LayoutRoute
                exact
                path="/role-manager-edit"
                layout={MainLayout}
                component={RoleManagerEdit}
              />
              <LayoutRoute
                exact
                path="/role-manager-edit/:id"
                layout={MainLayout}
                component={RoleManagerEdit}
              /> */}
                <LayoutRoute
                  exact
                  path="/SlugDetail/:_id"
                  layout={MainLayout}
                  component={SlugDetail}
                />
                <LayoutRoute
                  exact
                  path="/documentType-manager"
                  layout={MainLayout}
                  component={FTypeManager}
                />
                <LayoutRoute
                  exact
                  path="/documentFileDetail"
                  layout={MainLayout}
                  component={FTypeDetail}
                />
                <LayoutRoute
                  exact
                  path="/documentFileDetail/:_id"
                  layout={MainLayout}
                  component={FTypeDetail}
                />
                <LayoutRoute
                  exact
                  path="/"
                  layout={MainLayout}
                  component={RuleDetailPage}
                />
                
                <LayoutRoute
                  exact
                  path="/unit-manager"
                  layout={MainLayout}
                  component={UnitManager}
                />
                <LayoutRoute
                  exact
                  path="/UnitDetail"
                  layout={MainLayout}
                  component={UnitDetail}
                />
                <LayoutRoute
                  exact
                  path="/UnitDetail/:_id"
                  layout={MainLayout}
                  component={UnitDetail}
                />
                <LayoutRoute
                  exact
                  path="/user-manager"
                  layout={MainLayout}
                  component={UserManager}
                />
                <LayoutRoute
                  exact
                  path="/user-edit/:_id"
                  layout={MainLayout}
                  component={UserEdit}
                />
                <LayoutRoute
                  exact
                  path="/user-edit"
                  layout={MainLayout}
                  component={UserEdit}
                />

                <Redirect to="/" />
              </Switch>

            </Auth>
          </ApolloProvider>
        </Provider>
      </BrowserRouter>

    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);

import React,{useState,useEffect} from 'react';
import BieuMauTable from 'pages/Documents/FileTables/PVT/BieuMauTable'
import UpdateBieuMauForm from 'components/Widget/PVT/UpdateBieuMauForm'
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { client } from 'utils/apolloConnect';
import { getUser,getToken } from 'Constants';
import { Button } from 'reactstrap';
import authCheck from 'utils/authCheck';
import FileSaver, { saveAs } from 'file-saver';
import axios from 'axios';
import printJS from 'print-js'

import { GET_APPENDIXES, DELETE_APPENDIX, UPDATE_APPENDIX, CREATE_APPENDIX } from './query';
export function TabAppendix({ data, ...props }) {
    const [user] = useState(getUser());
    const [openBieuMauForm, setOpenBieuMauForm] = useState(false);
    const [openBieuMauEditForm, setOpenBieuMauEditForm] = useState(false);
    const [appendixes, setAppendixes] = useState([]);
    const [appendixUpdateItem, setAppendixUpdateItem] = useState(null);

    function fetchAppendixes() {
        let filtered = [{ id: "docId", value: data._id }]
        client.query({
            query: GET_APPENDIXES,
            variables: { filtered: filtered, pageSize: 0 }
        }).then(result => {
            authCheck(result.data)
            // console.log("appendixes:", result.data.response.data)
            const data = result.data.response.data
            if (data) {
                setAppendixes(data);
            }
        })
    }
    useEffect(() => {
        fetchAppendixes();
    },[data]);
    function handlePrintFile(pdfUrl) {
        var token = getToken();
        var accessToken = "";
        try {
            accessToken = token.accessToken;
            printJS({ printable: `${pdfUrl}?access_token=${accessToken}`, type: 'pdf', showModal: true })
        } catch (err) { }
    }


    function handleUpdateAppendix(index) {
        const item = appendixes[index]
        
        if (item) {            
            setOpenBieuMauEditForm(true);
            setAppendixUpdateItem(item);
        }
    }
    function handlePrintAppendix(index) {
        const item = appendixes[index]
        if (item) {
            fetchAppendixes()
            let fileUrl = item.pdfUrl
            handlePrintFile(fileUrl)
        }
    }
    function handleDownloadAppendix(index) {
        //download appendix
        const item = appendixes[index]
        console.log("view appendix detail: ", item)
        if (item) {
            let fileInfo = item.fileInfo
            console.log(`fileName: ${fileInfo.name} path: ${fileInfo.filePath} extension: ${fileInfo.oriExtension}`)
            let fileUrl = fileInfo.filePath
            // let fileName = `${fileInfo.name}.${fileInfo.oriExtension}`
            let fileName = `${fileInfo.name}`
            if (fileUrl && fileName) {
                handleDownloadFile(fileUrl, fileName)
            }
        }
    }
    function handleDownloadFile(pdfUrl, fileName) {

        var token = getToken();
        var accessToken = "";
        try {
            accessToken = token.accessToken;
            axios({
                url: pdfUrl,
                method: 'GET',
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                json: true,
                responseType: 'blob', // important
            }).then((response) => {
                FileSaver.saveAs(new Blob([response.data]), `${fileName}`);
            });
        } catch (err) { }
    }
    function handleShowAppendixDetail(fileId, index) {
        const item = appendixes[index]
        if (item) {
            fetchAppendixes()
            let fileUrl = item.pdfUrl
            // console.log("get fileUrl: ", fileUrl)
            var win = window.open(fileUrl, '_blank');
            win.focus();
        }
    }
    function handleDeleteAppendix(index) {        
        BaseControl.showConfirmAlert(
            "Thông báo",
            "Bạn có chắc muốn xóa văn bản đính kèm này?",
            "Đồng ý",
            "Hủy",
            () => {//success
                try {
                    let appendix = appendixes[index]
                    if (appendix) {
                        client.query({
                            query: DELETE_APPENDIX,
                            variables: { _id: appendix._id }
                        }).then(result => {
                            authCheck(result.data)
                            fetchAppendixes()
                        })
                    }
                } catch (error) {
                    console.log("===> delete appendix error: ", error)
                }
            },
            () => {//cancel

            }
        )
    }
    return (
        <div className="w-full">
            <div style={{ margin: "auto", paddingRight: "40px", marginTop: "20px", paddingBottom: "10px", textAlign: "right" }}>
                {(user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.delete === true)) && <Button onClick={() => setOpenBieuMauForm(true)} color="success" style={{ fontSize: "15px", float: "right" }}>Thêm mới</Button>}
                <UpdateBieuMauForm isOpen={openBieuMauForm}
                    isUpdate={false}
                    server={window.env.UPLOADURL}
                    onSuccess={(appendix) => {                        
                        client.query({
                            query: CREATE_APPENDIX,
                            variables: { docId: data._id, data: [{ ...appendix, docId:data._id }] }
                        }).then(result => {
                            authCheck(result.data)
                            fetchAppendixes();
                            setOpenBieuMauForm(false);
                        })
                    }}
                    onCancel={()=>setOpenBieuMauForm(false)}
                />
            </div>
            <div style={{ margin: "auto", padding: "40px", }}>
                <BieuMauTable parentId={""}
                    data={appendixes}
                    pages={1}
                    canEdit={user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.edit)}
                    onUpdateItem={handleUpdateAppendix}
                    onDeleteItem={handleDeleteAppendix}
                    onDownloadItem={handleDownloadAppendix}
                    onPrintItem={handlePrintAppendix}
                    onDetail={handleShowAppendixDetail}>
                </BieuMauTable>
                <UpdateBieuMauForm isOpen={openBieuMauEditForm}
                    key={"updateForm"}
                    isUpdate={true}
                    index={0}
                    data={appendixUpdateItem}
                    onSuccess={(appendix, isUpdate, index) => {
                        client.query({
                            query: UPDATE_APPENDIX,
                            variables: { data: appendix }
                        }).then(result => {
                            authCheck(result.data)
                            fetchAppendixes();
                            setOpenBieuMauEditForm(false);
                            
                        })
                    }}
                    onCancel={e => setOpenBieuMauEditForm(false)}>
                </UpdateBieuMauForm>
            </div>

        </div>
    )
}
import React, { Fragment } from 'react'
import { Input, Button } from 'reactstrap'

class AnswerFeedbackInputWidget extends React.Component {
    state = {
        comment: "",
    }
    constructor(props) {
        super(props)
        this.setState({

        })
        this.handleComment = this.handleComment.bind(this)
    }
    handleComment() {
        const { comment } = this.state
        if (comment.length !== 0) {
            this.props.onSendComment(comment)
            this.setState({ comment: "" })
        }
    }

    render() {
        const { comment } = this.state
        return (
            <div className="flex" style={{ paddingTop: 8, ...this.props.style }}>
                <Input
                    value={comment}
                    onChange={e => { this.setState({ comment: e.target.value }) }}
                    name="muc" placeholder="Nhập phản hồi"
                    onKeyPress={e => {
                        if (e.charCode === 13) this.handleComment();
                      }}
                />
                <Button outline onClick={this.handleComment} color="success"style={{marginLeft:4}}>Gửi</Button>
            </div>
        )
    }
}
export default AnswerFeedbackInputWidget
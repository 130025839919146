import gql from "graphql-tag";

export const DELETE_ROLETEMPLATE=gql`
mutation ($id:String!){
  response:deleteRoleManager(id:$id){
    code
    message
  }
}
`
export const LIST_ROLE_SHORT = gql`
query{
  response: roleManagers{
    code
    message
    data{
      id
      name
    }
  }
}
`
export const QUERY_ROLETEMPLATES =gql`
query{
  response:roleManagers{
    code
    message
    data{
      id
      claims{
        type
        value
      }
      menuIds
      name
      menutrees{
        label:name
        value:_id
        children
        {
          label:name
          value:_id,
          children{
            label:name
            value:_id
          }
        }
      }
    }
  }
}
`;
export const CREATE_ROLETEMPLATE=gql`
mutation($role:RoleManagerInput!){
  response:createRoleManager(role:$role){
    code
    message
  }
}
`
export const UPDATE_ROLETEMPLATE=gql`
mutation($role:RoleManagerInput!){
  response:updateRoleManager(role:$role){
    code
    message
  }
}
`
export const QUERY_ROLETEMPLATE=gql`
query ($id:String!){
  response:roleManager(id:$id){
    code
    message
    data{
      id
      claims{
        type
        value
      }
      menuIds
      name
      menutrees{
        label:name
        value:_id
        children
        {
          label:name
          value:_id,
          children{
            label:name
            value:_id
          }
        }
      }
    }
  }
}
`

import React,{useState} from 'react';
import { Button, ButtonGroup,Col,Row,FormGroup} from 'reactstrap';
import { MdEdit, MdRemove } from 'react-icons/lib/md';
import ReactTable from "react-table-custom";
import Moment from 'moment'

import feedbackIcon from 'assets/icons/feedback_icon.png'
import answerIcon from 'assets/icons/answer_feedback_icon.png'

const getParentId = (index, data) => {
    const item = data[index]
    if(item){
        let parentId = item.parentId;
        return parentId;
    }
    return null
}
const getDocId  = (index, data) => {
    const item = data[index]
    if(item){
        let docId = item.docId
        console.log("get docId: ", docId)
        return docId
    }
    return null
}
const getSection = (index, data) =>{
    const item = data[index]
    if(item){
        let section = item.section
        return section
    }
    return ""
}
const getSubSection = (index, data) =>{
    const item = data[index]
    if(item){
        let subSection = item.subSection
        console.log("get subSection: ", subSection)
        return subSection
    }
    return ""
}
const getIsRead = (index, data) =>{
    const item = data[index]
    if(item){
        let read = item.read
        return read
    }
    return false
}
const getCellBackgroundColor = (index, data) =>{
    if(getIsRead(index, data)===true){
        return "white"
    }else{
        return "#E2FCF6"
    }
}

const FeedbacksTable=({data,pages, pageSize, loading,fetchData, filtered, onFilteredChange,onDetail,})=>{
    const[page,setPage]=useState(0);
    return <ReactTable
        manual
        noDataText      ="Không có dữ liệu"
        sortable        ={false}
        filterable      ={true}
        filtered        ={filtered}
        // defaultSorted   ={[{id:"createdTime",desc:true}]}
        onFilteredChange={onFilteredChange}
        data            ={data}
        pages           ={pages}
        loading         ={loading} // Display the loading overlay when we need it
        onFetchData     ={fetchData} // Request new data when things change
        defaultPageSize ={20}
        pageSize        = {pageSize}
        onPageChange    ={setPage}
        className="table-feedback"
        style = {{color:"#4E5357"}}
        columns={[
            {
                Header: () => (
                    <div>
                        <i className="fa-tasks" /> STT
                    </div>
                  ),
                width: 50,
                className: "text-center",
                style:{marginTop:"10px",},
                filterable: false,
                getProps: (state, rowInfo, column) => {
                    const index = rowInfo?rowInfo.index:-1
                    const read = getIsRead(index, data)
                    console.log("===> index: ", index)
                    return {
                        style: {
                            background: index!==-1&&read===false?"#E2FCF6":"white",
                        },
                    };
                },
                Cell: row => <div>
                    {row.index + 1 + (page * pageSize)}
                </div>
            },
            {
                Header: () => (
                    <div style={{display:"grid"}}>
                        <span >
                            <i className="fa-tasks" /> NGƯỜI GỬI
                        </span>
                    </div>
                  ),
                accessor: "account.base.fullName",
                filterable: false,
                getProps: (state, rowInfo, column) => {
                    const index = rowInfo?rowInfo.index:-1
                    const read = getIsRead(index, data)
                    console.log("===> index: ", index)
                    return {
                        style: {
                            background: index!==-1&&read===false?"#E2FCF6":"white",
                        },
                    };
                },
                width: 180,
                style:{marginTop:"10px"},
                Cell:row=><span style={{textAlign:"left",paddingLeft:"10px", display:"grid"}}>
                    <span>{row.original.account.title&&`${row.original.account.title}.`}{row.value}</span>
                    <span>{row.original.account.departmentName&&`${row.original.account.departmentName}`}</span>
                </span>
            },      
            {
                Header: () => (
                    <div style={{display:"grid"}}>
                        <span>
                            <i className="fa-tasks" /> Tài liệu
                        </span>
                    </div>
                  ),
                accessor: 'docFile.name',
                filterable: true,
                getProps: (state, rowInfo, column) => {
                    const index = rowInfo?rowInfo.index:-1
                    const read = getIsRead(index, data)
                    console.log("===> index: ", index)
                    return {
                        style: {
                            background: index!==-1&&read===false?"#E2FCF6":"white",
                        },
                    };
                },
                width: 180,
                style:{marginTop:"10px", textAlign:"left"},
                Cell:row=><span style={{ textDecoration:"underline",textDecorationColor:"#C6C6C6", cursor:"pointer"}} onClick={()=>onDetail(row.index)}>
                    <span >{row.value&&row.value}</span>
                </span>
            },  
            
            {
                Header: () => (
                    <div style={{display:"grid"}}>
                        <span>
                            <i className="fa-tasks" /> NỘI DUNG
                        </span>
                    </div>
                  ),
                accessor: 'comment',
                filterable: true,
                getProps: (state, rowInfo, column) => {
                    const index = rowInfo?rowInfo.index:-1
                    const read = getIsRead(index, data)
                    console.log("===> index: ", index)
                    return {
                        style: {
                            background: index!==-1&&read===false?"#E2FCF6":"white",
                        },
                    };
                },
                style:{marginTop:"10px", textAlign:"left",paddingLeft:"10px", },
                Cell:row=><span>
                    {/* {row.value} */}
                    {
                        getParentId(row.index, data)?
                            <div style={{display:"flex", marginRight:"5px"}}>
                                <div style = {{width:"30px"}}><img src={answerIcon} alt="answer" style={{objectFit:"contain", marginRight:"10px"}}></img></div>
                                <div style={{display:"grid"}}>
                                    <span style={{fontWeight:"bold"}}>Trả lời góp ý:</span>
                                    <span>{row.value}</span>
                                </div>
                            </div>
                        :
                             <div style={{display:"flex"}}>
                             <div style = {{width:"30px", }}><img src={feedbackIcon} alt="feedback" style={{objectFit:"contain", marginRight:"10px"}}></img></div>
                             <div style={{display:"grid"}}>
                                 <span style={{fontWeight:"bold"}}>{`Góp ý Mục: "${getSection(row.index, data)}" Tiểu mục: "${getSubSection(row.index, data)}"`}</span>
                                 <span>{row.value}</span>
                             </div>
                         </div>
                    }
                </span>
            },    
            {
                Header: () => (
                    <div style={{display:"grid"}}>
                        <span>
                            <i className="fa-tasks" /> NGÀY GỬI
                        </span>
                    </div>
                  ),
                accessor: 'createdTime',
                filterable: false,
                getProps: (state, rowInfo, column) => {
                    const index = rowInfo?rowInfo.index:-1
                    const read = getIsRead(index, data)
                    console.log("===> index: ", index)
                    return {
                        style: {
                            background: index!==-1&&read===false?"#E2FCF6":"white",
                        },
                    };
                },
                width: 120,
                style:{marginTop:"10px"},
                Cell:row=><span style={{textAlign:"center",display:"grid" }}>
                    <span>{Moment(row.value).format("HH:mm")}</span>
                    <span>{Moment(row.value).format("DD/MM/YYYY")}</span>
                </span>
            },  
            {
                Header: () => (
                    <div style = {{marginTop:"0px"}}>
                        <i className="fa-tasks" /> CHỨC NĂNG
                    </div>
                  ),
                  getProps: (state, rowInfo, column) => {
                    const index = rowInfo?rowInfo.index:-1
                    const read = getIsRead(index, data)
                    console.log("===> getIsRead: ", read)
                    return {
                        style: {
                            background: index!==-1&&read===false?"#E2FCF6":"white",
                        },
                    };
                },
                width: 110,
                filterable:false,
                style:{marginTop:"10px"},
                Cell: row => <div style={{margin:"auto", display:"inline-flex"}}>
                    <Button onClick={e => onDetail(row.index)} size="ms" outline color = "success">Chi tiết</Button>
                </div>
            }
        ]}
    />
}
export default FeedbacksTable;
import gql from 'graphql-tag';

export const GET_DEPARTMENTS = gql`
{
  response:departments{
    code
    message
    data{
      _id
      name
    }
  }
}
`

export const QUERY_DIRECTORY=gql`
query($_id: String!){
  response:directory(_id: $_id){
    code
    message
    data{
      _id
      hidden
      name
      parentPath
      path
      priority
      slug
      type
      parent{
          _id
          name
          path
      }
      permissions{
        delete
        deleteFeedback
        download
        edit
        feedback
        print
        write
      }
    }
  }
}
`

export const UPDATE_DOC_FILE=gql`
    mutation updateDocFile($doc:DocFileInput!, $fields:[String])
    {
        response:updateDocFile(doc:$doc, fields: $fields){
            code
            message
            data{
              _id
              content
              createdTime
              extension
              fileNumber
              fileUrl
              pdfUrl:fileUrl(ispdf:true)
              fileId
              hidden
              name
              options
              parentPath
              path
              priority
              issuedBy
              issuedDate
              slug
              type
              updatedTime
              version
              department
              decisionDate
              decisionNumber
              docType
              account{
                  base{
                    fullName
                    userName
                  }
                }
              permissions{
                  delete
                  deleteFeedback
                  download
                  edit
                  feedback
                  print
                  write
              }
              parent{
                  name
                  _id
                  path
              }
              versions{
                _id
                createdTime
                docId
                fileId
                fileUrl
                pdfUrl:fileUrl(ispdf:true)
                name
                note
                updatedTime
                accountId
                account{
                    base{
                        fullName
                    }
                }
              }
            }
        }
    }
`

export const DELETE_DOC_FILE=gql`
    mutation deleteDocFile($_id:String!)
    {
        response:deleteDocFile(_id:$_id)
        {
            code
            message
        }
    }
`

export const GET_FILE_DETAIL=gql`
    query getFileDetail($_id:String!){
        response:file(_id:$_id){
                code
                message
                data{
                    _id
                    content
                    createdTime
                    extension
                    fileNumber
                    fileUrl
                    pdfUrl:fileUrl(ispdf:true)
                    fileId
                    hidden
                    name
                    options
                    parentPath
                    path
                    priority
                    issuedBy
                    issuedDate
                    slug
                    type
                    updatedTime
                    version
                    department
                    departmentId
                    relateDepartmentIds
                    relateDepartments{
                      _id
                      name
                    }
                    decisionDate
                    decisionNumber
                    type
                    account{
                        base{
                          fullName
                          userName
                        }
                      }
                    permissions{
                        delete
                        deleteFeedback
                        download
                        edit
                        feedback
                        print
                        write
                    }
                    parent{
                        name
                        _id
                        path
                    }
                    versions{
                      _id
                      createdTime
                      docId
                      fileId
                      fileUrl
                      pdfUrl:fileUrl(ispdf:true)
                      name
                      note
                      updatedTime
                      accountId
                      account{
                          base{
                              fullName
                          }
                      }
                    }
                }
        }
    }
`

export const GET_ALL_FILES=gql`
    query getAllFiles($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int)
    {
        response:files(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
            code
            message
            page
            records
            pages
            data{
                _id                
                viewers{
                  readerName
                  updatedTime
                  reader{
                    base{
                      fullName
                      code
                      department
                    }                    
                  }
                }
                content
                createdTime
                extension
                fileNumber
                fileId
                hidden
                name
                options
                parentPath
                path
                priority
                issuedBy
                issuedDate
                slug
                type
                updatedTime
                version
                department
                departmentId
                decisionDate
                decisionNumber
                type
                account{
                    base{
                      fullName
                      userName
                    }
                  }
            }
        }
    }
`
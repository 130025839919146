import "react-table/react-table.css";
import './styles/react-table-custom.css'
import React, { Component } from 'react';
import { FormControl, Icon, IconButton, TextField, Select, Switch } from '@material-ui/core';
import ReactTable, { ReactTableDefaults } from 'react-table'

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import viLocale from "date-fns/locale/vi";
import moment from "moment"
import ClearIcon from "@material-ui/icons/Clear";


export default function (props) {

  //overide lai ham nay
  const onFetchData = (state) => {
    const { filtered } = state;
    // console.log("ori filter:", filtered);
    const modifiedFilter = filtered.map(item => ({ id: item.id, ...item.value })).filter(item => item.value && item.value != "");
    // console.log("modified filtered:", modifiedFilter);
    if (props.onFetchData) {
      props.onFetchData({ ...state, filtered: modifiedFilter })
    }
    
  }
  const onFilteredChange = (filtered)=>{
    const modifiedFilter = filtered.map(item => ({ id: item.id, ...item.value })).filter(item => item.value && item.value != "");
    
    if (props.onFilteredChange) {
      props.onFilteredChange(modifiedFilter)
    }
  }
  return (
    <ReactTable {...props} onFilteredChange={onFilteredChange} onFetchData={onFetchData} />
  )
}

class FilterComponent extends Component {
  componentDidMount() {

  }
  state = {
    operation: '',
    value: '',
    // filterMenuEl: null
  };
  changeoperation = (operation) => {
    const newState = {
      ...this.state,
      operation
    };
    // Update local state
    this.setState(newState);
    // Fire the callback to alert React-Table of the new filter
    this.props.onChange(newState);
    // this.handleFilterMenuClose();
  };

  changeFilterValue = (event) => {

    const newState = {
      ...this.state,
      value: event.target.value
    };
    // Update local state
    this.setState(newState);
    // Fire the callback to alert React-Table of the new filter
    if (this.props.onChange)
      this.props.onChange(newState);
  };
  changeFilterCheckValue = (event) => {
    const newState = {
      ...this.state,
      value: event.target.checked ? 'true' : 'false'
    };
    // Update local state
    this.setState(newState);
    // Fire the callback to alert React-Table of the new filter
    if (this.props.onChange)
      this.props.onChange(newState);
  }
  changeFilterValueDate = (event) => {
    const newState = {
      ...this.state,
      value: moment(event).format("YYYY-MM-DD")
    };
    this.setState(newState);
    if (this.props.onChange)
      this.props.onChange(newState);
  };
  handleResetDate = () => {

    const newState = {
      ...this.state,
      value: ""
    };
    this.setState(newState);
    if (this.props.onChange)
      this.props.onChange(newState);

  };

  renderSelectFilter = () =>
    <FormControl margin="dense" variant="outlined" className="table-filter" style={{width:"100%"}}>
      <Select
        native
        className="pr-16"
        value={this.state.value}
        onChange={this.changeFilterValue}
      >
        <option value="">Tất cả</option>
        {
          this.props.column.options.map((item, index) => <option value={item.value}>{item.label}</option>)
        }
      </Select>
    </FormControl>

  renderDateFilter = () =>
    <div style={{ display: "flex" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
        <DatePicker
          disableToolbar
          variant="inline"
          helperText={null}
          fullWidth
          autoOk
          inputVariant="outlined"
          value={this.state.value ? moment(this.state.value).format("YYYY-MM-DD") : new Date()}
          onChange={this.changeFilterValueDate}
          format="dd/MM/yyyy"
          margin="dense"
        />
      </MuiPickersUtilsProvider>
      <div style={{ paddingTop: "15px", paddingLeft: "5px" }} onClick={this.handleResetDate}>
        <ClearIcon fontSize="inherit" />
      </div>
    </div>
  renderSwitchFilter = () =>
    <div style={{ display: "flex" }}>
      <Switch
        checked={this.state.value === "true"} onChange={this.changeFilterCheckValue}
      />
    </div>

  renderTextFilter = () =>
    <FormControl margin="dense" style={{width:"100%"}}>
      <TextField
        type="text"
        fullWidth
        variant="filled"
        // helperText="Lọc"
        // label="từ khóa"
        onChange={this.changeFilterValue}
        value={this.state.value}
        className="w-full"
        inputProps={{
          placeholder: 'Từ khóa',
          shrink: true,
          style:{fontSize:12}
        }}
      />
    </FormControl>


  render() {
    return (
      <div className="filter flex flex-col" >
        {
          this.props.column.type ? (
            this.props.column.type === "select" && this.props.column.options ?
              this.renderSelectFilter() :
              this.props.column.type === "date" ?
                this.renderDateFilter() :
                this.props.column.type === "switch" ?
                  this.renderSwitchFilter() :
                  this.renderTextFilter()
          ) : this.renderTextFilter()
        }
      </div>
    );
  }
}

const defaultFilterMethod = (filter, row) => {
  const id = filter.pivotId || filter.id;

  // console.log("filter=", filter);
  // Pivoted rows won't contain the column.
  //  If that's the case, we set the to true (allowing us to only filter on the current column)

  let rowValue = row[id];
  if (rowValue == undefined) {
    // return false;
    rowValue = '';
  }
  rowValue = rowValue.toString().toLowerCase();
  const operation = filter.value.operation;
  const value = filter.value.value.toLowerCase() || '';
  // console.log("value=", value,"rowvalue=",rowValue);
  switch (operation) {

    case '>=':
      return rowValue > value;
    case '<=':
      return rowValue < value;
    case '==':
      return rowValue === value;
    case '':
    default:
      return rowValue.indexOf(value) > -1;
  }
};

/**
 * React Table Defaults
 */
Object.assign(ReactTableDefaults, {
  PreviousComponent: (props) => (
    <IconButton {...props}>
      <Icon>chevron_left</Icon>
    </IconButton>
  ),
  NextComponent: (props) => (
    <IconButton {...props}>
      <Icon>chevron_right</Icon>
    </IconButton>
  ),
  FilterComponent: (props) => (
    <FilterComponent {...props} />
  ),
  defaultFilterMethod
});

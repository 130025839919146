import gql from 'graphql-tag';

//lấy danh sách các folder được phép truy cập trong vòng 2 cấp --> dùng cái này để xử lý thêm vào danh sách menu
export const QUERY_ROOT_DIR=gql`
query($filtered:[FilteredInput]){
  response: directories(filtered:$filtered){
    code
    message
    data{
      _id
      name
      type
      path
        children{
        _id
        name
        path
      }
    }
  }
}
`
export const QUERY_LIST_DOCFILE=gql`
query($filtered:[FilteredInput]){
  response:files(filtered:$filtered){
    code
    message
    page
    records
    data{
      _id
      content
      createdTime
      extension
      fileNumber
      fileId
      fileUrl
      hidden
      name
      options
      parentPath
      path
      priority
      issuedDate
      slug
      updatedTime
    }
  }
}
`


export const QUERY_LIST_DOCUMENT = gql`
query($filtered:[FilteredInput]){
  response:documents(filtered:$filtered){
    code
    message
    pages
    records
    data{
      _id
      name
      path
      parentPath
      type
      createdTime
      hidden
      priority
    }
  }
}
`
export const QUERY_DOCUMENT_DETAIL=gql`
query($_id:String!){
  response:document(_id:$_id){
    code
    message
    data{
      _id
      path
      name
      slug
    }
  }
}
`

export const GET_FILE_DETAIL=gql`
query($_id:String!){
  response:file(_id:$_id){
    code
    message
    data{
      _id
      content
      createdTime
      extension
      fileNumber
      fileId
      hidden
      name
      options
      parentPath
      path
      priority
      issuedDate
      slug
      type
      updatedTime
    }
  }
}
`
export const DELETE_DOC_FILE=gql`
mutation($_id: String!){
  response: deleteDocFile(_id: $_id){
    code
    message
  }
}
`

export const CREATE_FILE=gql`
mutation($doc:DocFileInput!){
  response:createFile(doc:$doc){
    code
    message
  }
}
`
export const UPDATE_FILE=gql`
mutation($doc:DocFileInput!){
  response:updateFile(doc:$doc){
    code
    message
  }
}
`

import Page from "components/Page";
import React, { Fragment } from "react";
import { Button, Input, Col, Label, Form, FormGroup } from "reactstrap";
import { withApollo } from "react-apollo";
import authCheck from "utils/authCheck";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import ToggleButton from "react-toggle-button";
import {LIST_ALL_PARENT_MENU} from "GraphQL/menu"
import { client } from 'utils/apolloConnect';
import { icon_folder } from 'assets/img/icon/index'

export const QUERY_DETAIL = gql`
  query AdminQuery($_id: String) {
    menu(_id: $_id) {
      code
      message
      data{
        _id
        name
        level
        description
        path
        disable
        icon
        parentId
        sort
      }
    }
  }
`;
export const MUTATION_CREATE_MENU = gql`
  mutation Mutation($menu: MenuInput) {
    createMenu(menu: $menu) {
      code
      message
      data{_id}
    }
  }
`;
export const MUTATION_EDIT_MENU = gql`
  mutation Mutation($menu: MenuInput) {
    updateMenu(menu: $menu) {
      code
      message
      data{_id}
    }
  }
`;


class MenuEditPage extends React.Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      name: "",
      level: 1,
      description: "",
      path: "",
      sort: 1,
      disable: false,
      icon: "",
      parents: []
    };
  }

  fetchAllMenu = () => {
    client.query({
      query: LIST_ALL_PARENT_MENU
    }).then(result => {
      if (authCheck(result.data.response)) {        
        this.setState({ parents: result.data.response.data })
      }
    })
  }
  
  
  componentDidMount() {
    this.fetchAllMenu();
    const _id = this.props.match.params._id;
    if (_id && _id.length > 0) {
      try {
        client
          .query({
            query: QUERY_DETAIL,
            variables: { _id: _id }
          })
          .then(result => {
            authCheck(result);
            var menu = result.data.menu.data;
            if (menu !== null) {
              this.setState(menu);
            }
          });
      } catch (err) {
        authCheck(err);
      }
    }
  }
  createOrUpdate = mutation => { };
  onCancel = () => {
    this.props.history.goBack();
  };
  render = () => {
    const { _id, icon, isAdmin, disable, name, description, level, parentId, path, sort, parents } = this.state;
    return (
      <Page className="CompanyPage" style={{ fontSize: "15px" }}>
          <div style = {{textAlign:"center"}}>
              <h2 style = {{marginTop:"15px",fontWeight:"bold", color:"#08AC50"}}>CẬP NHẬT MENU</h2>
          </div>
          <div style = {{marginTop:"20px"}}></div>
        <Form className="m-4">
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Disable
            </Label>
            <Col md={6} sm={7} style={{ paddingTop: "10px" }}>
              <ToggleButton
                value={disable}
                onToggle={value => {
                  this.setState({ disable: !disable });
                }}
              />
            </Col>
          </FormGroup>
          
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Tên Menu
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={name}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Đường dẫn
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={path}
                onChange={e => this.setState({ path: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Menu cha
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={parentId}
                onChange={e => this.setState({ parentId: e.target.value })}
                type="select"
              >
                <option value="">Menu gốc</option>
                {
                  parents.map((item, index) => <option key={index} value={item._id}>
                    {item.name.toUpperCase()}
                  </option>)
                }
              </Input>
            </Col>
          </FormGroup>
        <FormGroup row>
          <Label md={3} sm={5} className="text-right">
            Icon
            </Label>
          <Col md={6} sm={7}>
            <Input
              type="select"
              value={icon}
              onChange={e => this.setState({ icon: e.target.value })}
            >
              <option value="">No Icon</option>
              <option value="icon_folder">Icon Folder</option>
              <option value="icon_group">Icon Group</option>
              <option value="icon_setup">Icon Setup</option>
              <option value="MdCall">MdCall</option>
              <option value="MdMultilineChart">MdMultilineChart</option>
              <option value="MdSystemUpdate">MdSystemUpdate</option>
              <option value="MdKeyboardArrowDown">MdKeyboardArrowDown</option>
              <option value="MdExitToApp">MdExitToApp</option>
              <option value="MdVerifiedUser">MdVerifiedUser</option>
              <option value="MdSettings">MdSettings</option>
              <option value="MdAccountCircle">MdAccountCircle</option>
              <option value="MdEmail">MdEmail</option>
              <option value="MdWork">MdWork</option>
              <option value="MdHome">MdHome</option>
              <option value="rules_icon">Icon Nội quy</option>
              <option value="folder_icon">Icon thư mục cấp 1</option>
              <option value="group_icon">Icon thư mục cấp 2</option>
              <option value="icon_dichvu">Icon Dịch vụ</option>
              <option value="icon_report">Icon Report</option>
              <option value="icon_thongke">Icon Thống kê</option>
              <option value="icon_customer">Icon Khách hàng</option>
              <option value="icon_employee">Icon Nhân viên</option>
              <option value="icon_notification">Icon Thông báo</option>
              <option value="icon_location">Icon Vị trí</option>
              <option value="icon_money">Icon Tiền</option>
              <option value="icon_warning">Icon Cảnh báo</option>
              <option value="icon_working">Icon Công việc</option>
              <option value="icon_coupon">Icon Khuyến mãi</option>
              <option value="icon_news">Icon Tin tức</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={3} sm={5} className="text-right">
            Cấp độ
            </Label>
          <Col md={6} sm={7}>
            <Input
              type="select"
              value={level}
              onChange={e => this.setState({ level: e.target.value })}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={3} sm={5} className="text-right">
            Thứ tự
            </Label>
          <Col md={6} sm={7}>
            <Input
              type="select"
              value={sort}
              onChange={e => this.setState({ sort: e.target.value })}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={3} sm={5} className="text-right">
            Ghi chú
            </Label>
          <Col md={6} sm={7}>
            <Input
              value={description}
              onChange={e => this.setState({ description: e.target.value })}
            />
          </Col>
        </FormGroup>
        
        <FormGroup row>
          <Label md={3} sm={5} />
          <Col md={6} sm={7}>
            <Mutation
              mutation={
                _id === undefined || _id.length === 0
                  ? MUTATION_CREATE_MENU
                  : MUTATION_EDIT_MENU
              }
              variables={{
                menu: { _id, name, path, description, icon, disable, level, parentId, sort, isAdmin }
              }}
              onCompleted={result => {
                this.onCancel();
              }}
            >
              {mutation => (
                  
                  <Button color = "success" style = {{fontSize:"16px", marginTop:"10px", marginLeft:"10px"}} onClick = {mutation}>XÁC NHẬN</Button>
              )}
            </Mutation>
              <Button color = "danger" style = {{fontSize:"16px", marginTop:"10px", marginLeft:"10px"}} onClick = {this.onCancel}>HỦY</Button>
          </Col>
        </FormGroup>
        </Form>
      </Page >
    );
  };
}
export default MenuEditPage;

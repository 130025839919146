import React from "react";
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import 'filepond/dist/filepond.min.css';
import icon_upload from 'assets/icons/upload_icon.png'
import {Typography } from '@material-ui/core';
registerPlugin(FilePondPluginFileEncode);


function UploadFileForm({server,maxFiles,allowMultiple,onUpdateFiles,style,files,title,icon,...props}) {
    
    
    return (
        <div id="uploadContainer" style={{ minWidth: "300px", backgroundColor: "white", borderRadius: "5px", textAlign: "center", ...style }}>
            <Typography variant="h6" style={{ color: "#08AC50", paddingTop: "20px" }}>{title||"Upload file"}</Typography>
            <div style={{ width: "540px",  marginTop: "15px", marginBottom: "20px", borderRadius: "3px", borderColor: "#08AC50", borderStyle: "dashed", borderWidth: "1.5px", display: "inline-block" }}>
            {/* <img src={icon_upload} alt="upload icon" style={{ width: "75px", height: "75px", marginTop: "10px", marginBottom: "10px", objectFit: "contain" }}></img> */}
                {icon}
                <FilePond
                    // server={server}
                    server={{
                        url: server,
                        process: {
                            onload: onUpdateFiles
                        }
                    }}
                    allowMultiple={allowMultiple}
                    maxFiles={maxFiles}
                    onupdatefiles={(fileItems) => {
                        // console.log("--->uploaded file: ", fileItems.map(file => file.file))
                        // onUpdateFiles(fileItems)
                    }}
                    // files={this.state.files}
                    style={{ maxHeight: "120px" }}
                />
            </div>
        </div>
    )

}
// UploadFileForm.propTypes = {
//     server: PropTypes.string,
//     onUpdateFiles: PropTypes.func,
//     allowMultiple: PropTypes.bool,
//     maxFiles: PropTypes.number
// };

// UploadFileForm.defaultProps = {
//     allowMultiple: false,
//     maxFiles: 1,
// };

export default UploadFileForm;

import React from 'react';
import Page from 'components/Page';
import { Button, FormGroup} from 'reactstrap';
import {  QUERY_LIST_DOCFILE, DELETE_DOC_FILE} from 'GraphQL/document';
import { QUERY_DIRECTORY } from 'GraphQL/directory'
import authCheck from '../../utils/authCheck';
import { withApollo } from 'react-apollo'
import confirm from 'components/Confirmation';
import VBPQTable from './FileTables/VBPQTable';


class Document extends React.Component {
    state = {
        key: "",
        data:[],
        pages:1,
        name: "",
        parentPath: "",
        parentName: ""
    }
    constructor(props) {
        super(props);
        this.state = {
            parentId: "",
            slug: ""
        }
        this.onDelete=this.onDelete.bind(this);
    }

    componentDidMount(){        
        var parentId = this.props.match.params.parentId;
        var slug = this.props.match.params.slug;
        if(parentId||slug){
            
            //nếu sử dụng urlencode cái parentId thì tại bước này nhớ là cần xử lý urldecode để nó quay lại đúng chuỗi có /
            this.setState({ parentId, slug })
            this.process(slug,parentId);    
            this.fetchName(parentId)    
        }
    }
    componentWillReceiveProps(props) {
        var parentId = props.match.params.parentId;
        var slug = props.match.params.slug;
        if(parentId!=this.state.parentId||slug!=this.state.slug){
            this.setState({ parentId, slug })
            this.process(slug,parentId);
            this.fetchName(parentId)
        }
    }
    fetchParentName = () =>{
        var parent = this.state.parentPath.replace(/\//g,"")
        this.props.client.query({
            query: QUERY_DIRECTORY,
            variables: { _id: `${parent}` }
        }).then(result =>{
            authCheck(result.data);
            this.setState({ parentName: result.data.response.data.name })
        })
    }

    fetchName = (parentId) =>{
        this.props.client.query({
            query: QUERY_DIRECTORY,
            variables: {  _id:  `${parentId}` }
        }).then(result =>{
            authCheck(result.data)
            this.setState({name: result.data.response.data.name, parentPath: result.data.response.data.parentPath })
            this.fetchParentName();
        })
    }
    process = (slug, parentId) => {
        this.props.client.query({
            query: QUERY_LIST_DOCFILE,
            variables: { filtered: [{ id: "parentPath", value: `${parentId}$` }] }
        }).then(result => {
            authCheck(result.data);
            this.setState({ data: result.data.response.data });
        });
    }
    onDelete(_id){
        const { slug, parentId } = this.state
        confirm(`Bạn có chắc chắn muốn xóa văn bản ${_id} không?`).then(()=>{
            this.props.client.mutate({
                mutation: DELETE_DOC_FILE,
                variables: { _id }
            }).then(result =>{
                if(result.data.response.code == 0){
                    confirm("Đã xóa văn bản thành công!", { showCancel: false }).then (_=>{
                        this.process(slug,parentId);
                    })
                }
                else{
                    confirm(result.data.response.message, { showCancel: false });
                }
            })
        })
    }

    render = () => {
        const{parentId,slug}=this.state;
        return (
            <Page 
                title={this.state.name}
                breadcrumbs={[{ name: this.state.parentName, active: true }, { name: this.state.name, active:true }]}
                className="CustomerPage">
                <FormGroup className = "button-header">
                    <Button color="success" outline onClick={()=>this.props.history.push("/file-edit/"+this.props.match.params.parentId)}>Tạo văn bản</Button>
                    <span> </span>
                    <Button color="primary" outline onClick={()=>this.props.history.push("/DirDetail")}>Tạo thư mục</Button>
                </FormGroup>

                {
                    slug=="van-ban-phap-quy"&&<VBPQTable
                        parentId={parentId} data={this.state.data} pages={this.state.pages} onEdit={this.props.history.push} onDelete={this.onDelete}
                    />
                }
                {/* <ReactTable
                    manual
                    noDataText="Không có dữ liệu"
                    sortable={false}
                    defaultSorted={[{ desc: true }]}
                    data={this.state.data}
                    pages={this.state.pages} 
                    className="table-document"
                    columns={[
                        {
                            Header: "STT",
                            width: 50,
                            className: "text-center",
                            filterable: false,
                            Cell: row => <span>
                                {row.index + 1}
                            </span>
                        },
                        {
                            Header: "Loại văn bản", 
                            accessor: 'slug'
                        },                          
                        {
                            Header: "Số kí hiệu",
                            accessor: "fileNumber"
                        },
                        {
                            Header: "Nội dung văn bản",
                            accessor: "content"
                        },
                        {
                            Header: "Ngày ban hành",
                            accessor: "publishDate",
                            Cell: row => <span>{Moment(row.value).format("DD/MM/YYYY")}</span>
                        },
                        {
                            Header: "Tác vụ",
                            Cell: row => <div>
                                <ButtonGroup>
                                    <Button color="primary" outline size="sm" onClick={e => {this.props.history.push(`/file-edit/${this.props.match.params.parentId}/${row.original._id}`)}}><MdEdit /></Button>
                                    <Button color="danger" outline size="sm" onClick={() => this.onDelete(row.original._id)}><MdRemove /></Button>
                                </ButtonGroup>
                            </div>
                        }
                    ]}
                /> */}
                {/* {this.props.match.params.slug === "slug"&&
                <ReactTable
                    manual
                    noDataText="Không có dữ liệu"
                    sortable={false}
                    defaultSorted={[{ desc: true }]}
                    data={this.state.data}
                    pages={this.state.pages}
                    onFetchData={this.fetchData} 
                    className="table-document"
                    columns={[
                        {
                            Header: "ID",
                            accessor: "_id"
                        },
                        {
                            Header: "Tên thư mục",
                            accessor: "name"
                        },                          
                        {
                            Header: "Slug",
                            accessor: "slug"
                        },
                        {
                            Header: "Ngày tạo",
                            accessor: "createdTime",
                            Cell: row => <span>{Moment(row.value).format("DD/MM/YYYY")}</span>
                        },
                        {
                            Header: "Độ ưu tiên",
                            accessor: "priority"
                        },
                        {
                            Header: "Tác vụ",
                            Cell: row => <div>
                                <ButtonGroup>
                                    <Button color="primary" outline size="sm" onClick={() => this.onEdit(row.original._id)}><MdEdit /></Button>
                                    <Button color="danger" outline size="sm" onClick={() => this.onDelete(row.original.stt)}><MdRemove /></Button>
                                </ButtonGroup>
                            </div>
                        }
                    ]} */}
            </Page>
        );
    }
}
export default withApollo(Document);
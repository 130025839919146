import React from 'react'
import { Row,Col, Button } from 'reactstrap'

import timelineIcon from 'assets/icons/timeLineIcon.png'
import downloadIcon from 'assets/icons/download_icon.png'
import viewIcon from 'assets/icons/eye_icon.png'
import Moment from 'moment'


const VersionWidget=({onDownload, onPreview, canDownload, canPreview, version, time, userName, content,isLasted=false, style = null})=>{
    return(
        <Row style={{display:"flex", minHeight:"166px", textAlign:"center", border:"1px solid #D8D8D8", borderBottom:`${isLasted===true?'1px solid #D8D8D8':'0px solid #D8D8D8'}`, ...style}}>
            <Col sm={2} style={{textAlign:"right",minHeight:"166px", backgroundColor:"white", borderRight:"1px solid #D8D8D8"}}>
                <div style ={{width:"100%", height:"90px", textAlign:"center", paddingTop:"20px"}}>
                    <div style={{width:"50px", height:"50px", borderRadius:"25px", backgroundColor:"#08AC50", color:"white", margin:"auto",}}>
                        <span style={{display:"inline-block", verticalAlign:"middle",lineHeight:"50px",fontSize:"20px",color:"white"}}>{version}</span>
                    </div>
                </div>
                <div style ={{width:"100%", height:"50px", textAlign:"center", marginTop:"-15px", display:"flex"}}>
                    {canDownload&&<Button onClick={onDownload} color="" style={{textAlign:"center", width:"50px", height:"50px"}}><img src={downloadIcon} alt="download" style={{objectFit:"contain"}}></img></Button>}
                    {canPreview&&<Button onClick={onPreview} color="" style={{textAlign:"center", width:"50px", height:"50px"}}><img src={viewIcon} alt="view" style={{objectFit:"contain", marginLeft:"0px", marginTop:"10px"}}></img></Button>}
                </div>
            </Col>
            <Col sm={10} style={{textAlign:"left",minHeight:"166px", backgroundColor:"white ", }}>
                <div style={{width:"20px", height:"20px", borderRadius:"10px", backgroundColor:"white", marginTop:"-10px", marginLeft:"-25px",float:"left", textAlign:"center", position:"relative"}}>
                    <img src={timelineIcon} alt="" style={{margin:"auto", position:"absolute",top:"15%", left:"21%"}}></img>
                </div>
                <div style={{marginTop:"15px"}}>
                    <span style={{marginLeft:"10px", marginTop:"20px", color:"#1F9415", fontSize:"16px"}}>{
                        Moment(`${time}`).utc(7).add(Moment().utcOffset(),'m').format("HH:mm DD/MM/YYYY")
                    }</span>
                    <div style={{marginLeft:"10px", marginTop:"8px", display:"grid", textAlign:"left"}}>
                        <span style={{color:"#6B6B6B", fontSize:"15px"}}>Gửi bởi:</span>
                        <span style={{color:"#4E5357", fontSize:"16px", marginLeft:"20px"}}>{userName}</span>

                        <span style={{color:"#6B6B6B", fontSize:"15px"}}>Nội dung:</span>
                        <span style={{color:"#4E5357", fontSize:"16px", marginLeft:"20px", marginBottom:"15px"}}>{content}</span>
                    </div>
                </div>
            </Col>
        </Row>
    )
}
export default VersionWidget;
import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import {Button,Modal,ModalHeader,ModalBody,ModalFooter,FormGroup,Form,Input} from 'reactstrap'; // your choice.
import { createConfirmation } from 'react-confirm';
import {client} from 'utils/apolloConnect'
import Typography from 'components/Typography';
import {CREATE_SLUG} from 'GraphQL/slug'
import authCheck from 'utils/authCheck'

class SlugModal extends React.Component{
    state={
        name:""
    }
    onCreate = ()=>{
        const{name}=this.state;
        const {proceed,options}=this.props;
        console.log("onCreate",name);
        client.mutate({
            mutation:CREATE_SLUG,
            variables:{slug:{type:options.type,name}}
        }).then(result=>{
            authCheck(result.data);
            proceed(result.data.response.data);
        })
    }
    render=()=>{
        const {confirmMessage,dismiss,cancel,proceed,options}=this.props;
        return (
            <Modal toggle={dismiss} isOpen={true}>   
                <ModalHeader toggle={dismiss}>{confirmMessage}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Input placeholder="Nhập tên" value={this.state.name} onChange={e=>{this.setState({name:e.target.value})}}/>
                        </FormGroup>
                    </Form>
                </ModalBody>            
                <ModalFooter>                    
                    <div>
                        <Button color='success' onClick={this.onCreate}  style = {{ fontSize: "15px" }}> XÁC NHẬN </Button>  
                        <span> </span>
                        <Button color='danger' onClick={cancel} style = {{ fontSize: "15px" }}> HỦY </Button>{' '}
                    </div>     
                </ModalFooter>
            </Modal>
        )
    }
}
// const SlugModal = ({ proceed, dismiss, cancel, confirmMessage, options}) => {
//     var onCreate = (name)=>{
//         console.log("onCreate",name);
//         client.mutate({
//             mutation:CREATE_SLUG,
//             variables:{slug:{type:options.type,name}}
//         }).then(result=>{
//             authCheck(result.data);
//             proceed(result.data.response.data);
//         })
//     }
//     var name="";
//     return (
//         <Modal toggle={dismiss} isOpen={true}>   
//             <ModalHeader toggle={dismiss}>{confirmMessage}</ModalHeader>
//             <ModalBody>
//                 <Form>
//                     <FormGroup>
//                         <Input placeholder="Nhập tên" value={this.state.name} onChange={e=>{this.setState({name})}}/>
//                     </FormGroup>
//                 </Form>
//             </ModalBody>            
//             <ModalFooter>
//                 {options.hideCancel?(<Button color='primary' onClick={proceed}>ĐÓNG</Button>  ):(
//                     <div>
//                         <Button color='default' onClick={cancel}> Bỏ qua </Button>{' '}
//                         <Button color='primary' onClick={e=>{onCreate(name)}}>Xác nhận</Button>  
//                     </div>    
//                 )}
                          
//             </ModalFooter>
//         </Modal>
//     )
// }
 
SlugModal.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  confirmation: PropTypes.string,  // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}
SlugModal.defaultProps={
    options:{type:""}
}
const confirm = createConfirmation(confirmable(SlugModal));
export default function(confirmMessage, options = {}) {
// You can pass whatever you want to the component. These arguments will be your Component's props
    return confirm({ confirmMessage, options });
}
// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
//export default confirmable(Confirmation);
import React, { useState, useEffect } from 'react';
import { useForm } from 'hooks';
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import { Card, CardHeader, CardBody, Button, Row, Col, Input, CardTitle, Label, FormGroup } from 'reactstrap'
import authCheck from 'utils/authCheck';
import Select from 'react-select';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import Avatar from 'react-avatar';
import { Typography } from '@material-ui/core';
import editIcon from 'assets/icons/edit_icon.png'
import deleteIcon from 'assets/icons/delete_icon.png'
import ReactTable from 'react-table'
import { withApollo } from 'react-apollo';
import moment from 'moment';
import confirm from 'components/Confirmation';
import showDirModal from './SharingModal'
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { client } from 'utils/apolloConnect';

import { MdAdd } from "react-icons/lib/md"
import { QUERY_ROOT_DIR, MUTATION_SAVE_ACCOUNT, QUERY_ACCOUNT, QUERY_SELECTABLE_ACCOUNT, QUERY_MENUTREES, UPDATE_SHARING } from './query'
import { QUERY_ROLETEMPLATES } from 'GraphQL/role'
import { DELETE_SHARING } from './query'
// import {
//     MdCheckBoxOutlineBlank,
//     MdExpandMore,
//     MdExpandLess,
//     MdCheckBox,
//     MdFolder,
//     MdExplore,
//     MdClose,
//     MdFolderOpen,

// } from "react-icons/lib/md";
// import "react-checkbox-tree/lib/react-checkbox-tree.css";
// import CheckboxTree from 'react-checkbox-tree';
import CheckBoxTree from './CheckBoxTree';
import { ButtonGroup } from '@material-ui/core';


const useStyles = makeStyles({
    root: {
        height: 216,
        flexGrow: 1,
        maxWidth: 400,
    },
});

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);


function UserEdit(props) {

    const [base, setBase] = useState(null);
    const [authorizedAccounts, setAuthorizedAccounts] = useState([]);
    const { form, setForm, setInForm, handleChange } = useForm({});
    //danh sách folder gốc để tính toán số bảng có thể hiển thị
    const [rootFolder, setRootFolder] = useState([]);

    const [roles, setRoles] = useState([]);

    const [nodes, setNodes] = useState([]);
    const classes = useStyles();
    function fetchRootFolder() {
        if (base && base.sub) {
            client.query({
                query: QUERY_ROOT_DIR,
                variables: { accountIds: [base.sub] }
            }).then(result => {
                if (authCheck(result.data)) {
                    setRootFolder(result.data.response.data);
                }
            })
        }
    }
    function fetchSelectableAccount() {
        client.query({
            query: QUERY_SELECTABLE_ACCOUNT
        }).then(result => {
            if (authCheck(result.data)) {
                setAuthorizedAccounts(result.data.response.data);
            }
        })
    }
    function loadDetailAccount(_id) {
        client.query({
            query: QUERY_ACCOUNT,
            variables: { _id }
        }).then(result => {
            if (authCheck(result)) {
                var account = result.data.response.data;
                setBase(account.base);
                const { _id, menuIds, roleId, roleName } = account;
                setForm({ _id, menuIds, roleId, roleName });
            }
        })
    }
    function fetchTreeMenu() {
        client.query({
            query: QUERY_MENUTREES
        }).then(result => {
            if (authCheck(result.data.response)) {
                if (result.data.response.data !== null) {
                    setNodes(result.data.response.data);
                }
            }
        })
    }

    const fetchListRoleTemplate = () => {
        client.query({
            query: QUERY_ROLETEMPLATES
        }).then(result => {
            if (authCheck(result))
                setRoles(result.data.response.data);
        });
    }
    useEffect(() => {
        //load authorizedAccount from root system
        fetchSelectableAccount();
        fetchTreeMenu();
        fetchListRoleTemplate();
        const { _id } = props.match.params;
        if (_id) {
            loadDetailAccount(_id);
        }
    }, []);
    useEffect(() => {
        if (base && base.sub) {
            fetchRootFolder();
        }
    }, [base]);

    function onSelectedAccount(item) {
        if (item)
            loadDetailAccount(item.value);
    }

    function onSaveAccount() {
        client.mutate({
            mutation: MUTATION_SAVE_ACCOUNT,
            variables: { account: form }
        }).then(result => {
            if (authCheck(result.data)) {
                confirm("Cập nhật tài khoản thành công", { hideCancel: true });
                // props.history.goBack();
            }
        })
    }
    function handleAdd(root) {
        showDirModal(`PHÂN QUYỀN ${root.name}`, { type: "account", to: base.sub, root }).then(result => {
            loadDetailAccount(base.sub);
        })
    }
    function handleUpdatePermission(docShare) {
        console.log("change sharing:", docShare);
        client.mutate({
            mutation: UPDATE_SHARING,
            variables: { sharing: docShare }
        }).then(result => {
            if (authCheck(result))
                fetchRootFolder();
        })
    }

    const upDateSharing = (_id, root) => {
        showDirModal(`PHÂN QUYỀN ${root.name}`, { _id, type: "account", to: base.sub, root }).then(result => {
            loadDetailAccount(base.sub);
        })
    }

    function deleteSharing(_id) {
        BaseControl.showConfirmAlert(
            "Thông báo",
            "Bạn có chắc muốn xóa phân quyền này không?",
            "Đồng ý",
            "Hủy",
            () => {//success
                try {
                    client.mutate({
                        mutation: DELETE_SHARING,
                        variables: { _id }
                    }).then(result => {
                        if (result.data.response.code == 0) {
                            if (authCheck(result.data.response)) {
                                BaseControl.showSuccessToast("Xóa phân quyền thành công");
                                fetchRootFolder();
                            }
                        }
                    })
                } catch (error) {
                    // console.log("===> delete appendix error: ", error)
                }
            },
            () => {//cancel

            }
        )
    }

    return (
        <div>
            <PageTitle onClick={props.history.goBack} title={form._id ? "CẬP NHẬT THÔNG TIN TÀI KHOẢN" : "CHI TIẾT TÀI KHOẢN"}></PageTitle>
            <div style={{ width: "100%", margin: "auto", marginTop: "35px", marginBottom: "15px", color: "#4E5357", borderRadius: "4px" }}>
                <div id="info" style={{ width: "100%", backgroundColor: "white", margin: "auto", marginTop: "35px", marginBottom: "15px", color: "#4E5357", fontSize: "16px", borderRadius: "4px" }}>
                    <Card style={{ border: "none", WebkitBoxShadow: "0px 0px 8px 7px rgba(241,241,241,1)", marginRight: "14px", marginLeft: "14px", paddingBottom: "20px" }}>

                        <CardBody>
                            <div style={{ textAlign: 'center' }}>
                                <Row>
                                    <Col >
                                        <Row style={{ marginTop: "20px" }}>
                                            <Col sm={2} style={{ marginTop: "10px" }}>
                                                <span>Tài khoản</span>
                                            </Col>
                                            <Col sm={10}>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ width: "92.5%", pointerEvents: 'all' }}>
                                                        <Select
                                                            value={base && base._id}
                                                            onChange={onSelectedAccount}
                                                            placeholder={"Chọn tài khoản"}
                                                            options={authorizedAccounts.map(item => ({ value: item.sub, label: item.fullName + ' - ' + item.code || '' }))}
                                                            isClearable
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div id="info" style={{ width: "100%", margin: "auto", marginTop: "35px", marginBottom: "15px", color: "#4E5357", fontSize: "16px", borderRadius: "4px" }}>
                    {base &&
                        <div style={{ display: "flex" }}>
                            <Col sm={4}>
                                <Card style={{ width: "100%", border: "none", color: "#4E5357", float: "left", borderRadius: "4px", WebkitBoxShadow: "0px 0px 8px 7px rgba(241,241,241,1)" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <Avatar size={80} round={true} style={{ marginTop: "15px" }} src={base.image} name={base.fullName} />
                                    </div>
                                    <div style={{ textAlign: "center" }}>

                                        <Label style={{ fontSize: "25px", marginTop: "15px" }}>
                                            Họ và tên: {base.title}. {base.fullName}
                                        </Label>

                                    </div>

                                    <div style={{ marginTop: "25px", marginLeft: "30px" }}>
                                        <Row className="info-row">
                                            <Col sm={4}>
                                                <Label className="label-name">
                                                    Tên đăng nhập:
                                                </Label>
                                            </Col>
                                            <Label>
                                                {base.userName}
                                            </Label>
                                        </Row>
                                        <Row className="info-row">
                                            <Col sm={4}>
                                                <Label className="label-name">
                                                    Số điện thoại:
                                                </Label>
                                            </Col>
                                            <Label>
                                                {base.phoneNumber}
                                            </Label>
                                        </Row>
                                        <Row className="info-row">
                                            <Col sm={4}>
                                                <Label className="label-name">
                                                    Email:
                                                </Label>
                                            </Col>
                                            <Label>
                                                {base.email}
                                            </Label>
                                        </Row>
                                        <Row className="info-row">
                                            <Col sm={4}>
                                                <Label className="label-name">
                                                    Ngày sinh:
                                                </Label>
                                            </Col>
                                            <Label>
                                                {moment(base.birthday).format("DD/MM/YYYY")}
                                            </Label>
                                        </Row>
                                        <Row className="info-row">
                                            <Col sm={4}>
                                                <Label className="label-name">
                                                    Giới tính:
                                                </Label>
                                            </Col>
                                            <Label>
                                                <span>{base.gender == "male" ? "Nam" : "Nữ"}</span>
                                            </Label>
                                        </Row>
                                        <Row className="info-row">
                                            <Col sm={4}>
                                                <Label className="label-name">
                                                    Địa chỉ:
                                                </Label>
                                            </Col>
                                            <Label>
                                                {base.address}
                                            </Label>
                                        </Row>
                                    </div>
                                </Card>
                                <Card style={{ marginTop: 12, width: "100%", border: "none", color: "#4E5357", float: "left", borderRadius: "4px", WebkitBoxShadow: "0px 0px 8px 7px rgba(241,241,241,1)" }}>
                                    <CardHeader style={{ fontSize: "15px", fontWeight: "bold", display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between" }} >
                                        <div style={{ paddingTop: 12 }}>
                                            Phân quyền hệ thống
                                        </div>
                                        <Button color="success" style={{ marginTop: "10px", marginLeft: "10px" }} onClick={onSaveAccount}>Cập nhật</Button>

                                    </CardHeader>
                                    <CardBody style={{ margin: 16, fontSize: 12 }}>
                                        <FormGroup>
                                            <Label>
                                                Chọn từ mẫu quyền có sẵn:
                                            </Label>

                                            <Input name="roleId" value={form.roleId} type="select" onChange={e => {
                                                var role = roles.find(r => r.id === e.target.value);
                                                if (role)
                                                    setForm({ ...form, menuIds: role.menuIds, roleId: role.id, roleName: role.name });
                                            }}>
                                                <option value="-1">------------------</option>
                                                {
                                                    roles.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)
                                                }
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>
                                                Tên chức danh:
                                            </Label>
                                            <Input name="roleName" value={form.roleName} onChange={handleChange} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>
                                                Menu truy cập:
                                            </Label>
                                            {/* <TreeView
                                                className={classes.root}
                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                defaultExpandIcon={<ChevronRightIcon />}
                                                expanded={expanded}
                                                selected={selected}
                                                onNodeToggle={handleToggle}
                                                onNodeSelect={handleSelect}
                                            >
                                                <TreeItem nodeId="1" label={CheckedTreeItem({ _id: "1", title: "Application", checked: true, checkBoxClicked: () => { } })}>
                                                    <TreeItem nodeId="2" label="Calendar" />
                                                    <TreeItem nodeId="3" label="Chrome" />
                                                    <TreeItem nodeId="4" label="Webstorm" />
                                                </TreeItem>
                                                <TreeItem nodeId="5" label="Documents">
                                                    <TreeItem nodeId="6" label="Material-UI">
                                                        <TreeItem nodeId="7" label="src">
                                                            <TreeItem nodeId="8" label="index.js" >
                                                                <Input type="checkbox" />
                                                            </TreeItem>
                                                            <TreeItem nodeId="9" label="tree-view.js" />
                                                        </TreeItem>
                                                    </TreeItem>
                                                </TreeItem>
                                            </TreeView> */}
                                            <CheckBoxTree
                                                nodes={nodes}
                                                checked={form.menuIds}
                                                onChecked={(checked) => setInForm("menuIds", checked)}
                                            />
                                            {/* <CheckboxTree
                                                nodes={nodes}
                                                checked={form.menuIds}
                                                expanded={expanded}
                                                expandOnClick
                                                showExpandAll
                                                noCascade
                                                onCheck={checked => { console.log("checked:", checked); setInForm("menuIds", checked) }}
                                                onExpand={expanded => { console.log("expanded:", expanded); setExpanded(expanded) }}
                                                onClick={clicked => setClicked(clicked)}
                                                icons={{
                                                    check: <MdCheckBox className="rct-icon rct-icon-check" icon="check-square" />,
                                                    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" icon={['far', 'square']} />,
                                                    halfCheck: "",
                                                    expandClose: <MdExpandLess className="rct-icon rct-icon-expand-close" icon="chevron-right" />,
                                                    expandOpen: <MdExpandMore className="rct-icon rct-icon-expand-open" icon="chevron-down" />,
                                                    expandAll: <MdExplore className="rct-icon rct-icon-expand-all" icon="plus-square" />,
                                                    collapseAll: <MdClose className="rct-icon rct-icon-collapse-all" icon="minus-square" />,
                                                    parentClose: <MdFolder className="rct-icon rct-icon-parent-close" icon="folder" />,
                                                    parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" icon="folder-open" />,
                                                    leaf: ""
                                                }}
                                            /> */}
                                        </FormGroup>

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={8}>
                                <div id="info" style={{ width: "100%", backgroundColor: "white", margin: "auto", color: "#4E5357", fontSize: "16px", borderRadius: "4px", float: "right" }}>
                                    {rootFolder.map(folder => (
                                        <div id="info" style={{ width: "100%", backgroundColor: "white", margin: "auto", color: "#4E5357", fontSize: "16px", borderRadius: "4px" }}>
                                            <Card style={{ border: "none", color: "#4E5357", fontSize: "12px", borderRadius: "4px", WebkitBoxShadow: "0px 0px 8px 7px rgba(241,241,241,1)" }}>
                                                <CardHeader>
                                                    <CardTitle style={{ marginTop: "15px" }}>
                                                        <span>{folder.name}</span>
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody >
                                                    <ReactTable
                                                        style={{ width: "100%" }}
                                                        data={folder.children}
                                                        defaultPageSize={5}
                                                        noDataText="Không có dữ liệu"
                                                        columns={[
                                                            {
                                                                Header: "STT",
                                                                width: 50,
                                                                className: "text-center",
                                                                filterable: false,
                                                                Cell: row => <span>
                                                                    {row.index + 1}
                                                                </span>
                                                            },
                                                            {
                                                                Header: "Thư mục",
                                                                accessor: "parent",
                                                                Cell: row => (
                                                                    <div>
                                                                        {row.value && (row.value.name + ' >> ')}{row.original.name}
                                                                    </div>
                                                                ),
                                                                filterMethod: (filter, row, column) =>
                                                                    row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                                                ,
                                                                filterable: true
                                                            },
                                                            {
                                                                Header: "Thêm",
                                                                accessor: "sharing",
                                                                width: 60,
                                                                Cell: row => (
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <GreenCheckbox onChange={e => {
                                                                            handleUpdatePermission(row.value.length > 0 ? { ...row.value[0], permission: { ...row.value[0].permission, write: e.target.checked } } : { permission: { write: true }, type: "account", to: base.sub, docId: row.original._id })
                                                                        }} checked={row.value.length > 0 && row.value[0].permission.write == true} />
                                                                    </div>
                                                                )
                                                            },
                                                            {
                                                                Header: "Sửa",
                                                                width: 60,
                                                                accessor: "sharing",
                                                                Cell: row => (
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <GreenCheckbox checked={row.value.length > 0 && row.value[0].permission.edit == true}
                                                                        onChange={e => {
                                                                            handleUpdatePermission(row.value.length > 0 ? { ...row.value[0], permission: { ...row.value[0].permission, edit: e.target.checked } } : { permission: { edit: true }, type: "account", to: base.sub, docId: row.original._id })
                                                                        }}/>
                                                                    </div>
                                                                )
                                                            },
                                                            {
                                                                Header: "Xóa",
                                                                width: 60,
                                                                accessor: "sharing",
                                                                Cell: row => (
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <GreenCheckbox checked={row.value.length > 0 && row.value[0].permission.delete == true}
                                                                            onChange={e => {
                                                                                handleUpdatePermission(row.value.length > 0 ? { ...row.value[0], permission: { ...row.value[0].permission, delete: e.target.checked } } : { permission: { delete: true }, type: "account", to: base.sub, docId: row.original._id })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            },
                                                            {
                                                                Header: "Bình luận",
                                                                width: 80,
                                                                accessor: "sharing",
                                                                Cell: row => (
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <GreenCheckbox checked={row.value.length > 0 && row.value[0].permission.feedback == true}
                                                                            onChange={e => {
                                                                                handleUpdatePermission(row.value.length > 0 ? { ...row.value[0], permission: { ...row.value[0].permission, feedback: e.target.checked } } : { permission: { feedback: true }, type: "account", to: base.sub, docId: row.original._id })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            },
                                                            {
                                                                Header: "Xóa bình luận",
                                                                width: 100,
                                                                accessor: "sharing",
                                                                Cell: row => (
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <GreenCheckbox checked={row.value.length > 0 && row.value[0].permission.deleteFeedback == true}
                                                                        onChange={e => {
                                                                            handleUpdatePermission(row.value.length > 0 ? { ...row.value[0], permission: { ...row.value[0].permission, deleteFeedback: e.target.checked } } : { permission: { deleteFeedback: true }, type: "account", to: base.sub, docId: row.original._id })
                                                                        }}
                                                                        />
                                                                    </div>
                                                                )
                                                            },
                                                            // {
                                                            //     Header: "",
                                                            //     Cell: row => (
                                                            //         <ButtonGroup>
                                                            //             <Button color="" ><img src={editIcon} alt="edit" style={{ objectFit: "cover", width: "20px" }} onClick={() => upDateSharing(row.original._id, folder)} ></img></Button>
                                                            //             <Button color="" onClick={() => deleteSharing(row.original._id)}><img src={deleteIcon} style={{ objectFit: "cover", width: "20px" }}></img></Button>

                                                            //         </ButtonGroup>
                                                            //     )
                                                            // }
                                                        ]}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </div>

                                    ))}
                                </div>
                            </Col>
                        </div>
                    }
                </div>
            </div>
        </div >
    );

}
export default withApollo(UserEdit);
import React, { useState, useEffect } from 'react';
import PDFViewer from 'components/Widget/NTD/PDFViewer'
import { getUser, getToken } from 'Constants';
import FileSaver, { saveAs } from 'file-saver';
import axios from 'axios';
import printJS from 'print-js'

export function TabDetail({ data, ...props }) {
    const [user] = useState(getUser());
    function handleDownloadFile(pdfUrl, fileName) {

        var token = getToken();
        var accessToken = "";
        try {
            accessToken = token.accessToken;
            axios({
                url: pdfUrl,
                method: 'GET',
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                json: true,
                responseType: 'blob', // important
            }).then((response) => {
                FileSaver.saveAs(new Blob([response.data]), `${fileName}`);
            });
        } catch (err) { }
    }
    //print document
    function handlePrintFile(pdfUrl) {
        var token = getToken();
        var accessToken = "";
        try {
            accessToken = token.accessToken;
            printJS({ printable: `${pdfUrl}?access_token=${accessToken}`, type: 'pdf', showModal: true })
        } catch (err) { }
    }

    return (
        <PDFViewer
            fileUrl={`${data.pdfUrl}`}
            downloadEnable={(user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.download))}
            printEnable={(user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.print))}
            onDownload={e => {
                handleDownloadFile(data.pdfUrl, `${data.fileNumber}.pdf`)
            }}
            onPrint={e => {
                handlePrintFile(data.pdfUrl)
            }}
            page={1}
            pages={1}
            zoom={1.0}
        >
        </PDFViewer>
    )
}
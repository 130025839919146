import Moment from 'moment';
export const ValidFormDate=(date,format)=>{
    
    if(!date||date==null||date==''||Moment(date).toDate()<new Date(1901,1,1))
        return " ..... / ..... / ..........";
    else{
        if(!format)
            return " "+Moment(date).format("DD/MM/YYYY");
        else
            return " "+Moment(date).format(format);
    }
}

export const ValidFormDateTime=(date,format)=>{
    
    if(!date||date==null||date==''||Moment(date).toDate()<new Date(1901,1,1))
        return " ..... / ..... / ..........";
    else{
        if(!format)
            return " "+Moment(date).format("HH:mm DD/MM/YYYY");
        else
            return " "+Moment(date).format(format);
    }
}
export const ValidFormTime=(date,format)=>{
    
    if(!date||date==null||date==''||Moment(date).toDate()<new Date(1901,1,1))
        return " ..... / ..... ";
    else{
        if(!format)
            return " "+Moment(date).format("HH giờ mm'");
        else
            return " "+Moment(date).format(format);
    }
}
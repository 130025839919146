import gql from 'graphql-tag';

export const GET_ALL_FILES=gql`
    query getAllFiles($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int)
    {
        response:files(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
            code
            message
            page
            records
            pages
            data{
                _id
                content
                createdTime
                extension
                fileNumber
                fileId
                hidden
                name
                options
                parentPath
                path
                priority
                issuedBy
                issuedDate
                slug
                type
                updatedTime
                account{
                    base{
                      fullName
                      userName
                    }
                  }
            }
        }
    }
`
export const CREATE_DOC_FILE=gql`
    mutation createDocFile($doc:DocFileInput!)
    {
        response:createDocFile(doc:$doc){
            code
            message
            data{
                fileUrl
                _id
            }
        }
    }
`


import React from 'react'
import { Button } from 'reactstrap'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import VBPQTable from 'pages/Documents/FileTables/PVT/VBPQTable'
import {  GET_ALL_FILES} from './query';
import { QUERY_DIRECTORY } from 'GraphQL/directory'
import authCheck from 'utils/authCheck';
import { withApollo } from 'react-apollo'
import { getUser } from 'Constants';

class VBPQPages extends React.Component{
    state = {
        id          : "", 
        name        : "",
        parentPath  : "",
        parentName  : "",
        pages       : 1,
        pageSize    : 20,
        data        : [],
        loading     : false,
        filtered    : [],
        permission  : null,
        records     : 0,

    }
    constructor(props){
        super(props)
        this.setState({

        })
        this.user = getUser();
        this.onShowDetail   = this.onShowDetail.bind(this)
        this.fetchData      = this.fetchData.bind(this)
        this.handleFilterdChange = this.handleFilterdChange.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        var id = this.props.match.params.id;
        if(id!==this.state.id){
            this.setState({ id })
            this.fetchName(id)
        }
    }

    componentWillReceiveProps(props) {
        var id = props.match.params.id;
        if(id!==this.state.id){
            this.setState({ id })
            this.fetchName(id)
        }
    }
    fetchParentName = (parentPath) =>{
        var parent = parentPath.replace(/\//g,"")
        this.props.client.query({
            query: QUERY_DIRECTORY,
            variables: { _id: `${parent}` }
        }).then(result =>{
            authCheck(result.data);
            this.setState({ parentName: result.data.response.data.name })
        })
    }

    fetchName = (id) =>{
        // const { parentPath } = this.state;
        this.props.client.query({
            query: QUERY_DIRECTORY,
            variables: {  _id:  `${id}` }
        }).then(result =>{
            if(authCheck(result.data))
            {
                var name = result.data.response.data.name
                var parentPath = result.data.response.data.parentPath
                var permission = result.data.response.data.permissions[0]
                this.setState({name: name, parentPath: parentPath, permission:permission })
                this.fetchParentName(parentPath);
                this.fetchData({filtered:[], sorted:[], pageSize: 20, page:0})
            }
        })
    }

    fetchData(state){
        this.setState({loading:true})
        const { id } = this.state
        const {filtered, pageSize, sorted, page} = state
        if(id && id!== undefined && id!== null){
            var copy = [...filtered, {id:"parentPath",value:`${id}$`}]
            this.props.client.query({
                query       : GET_ALL_FILES,
                variables   : {  filtered:  copy , pageSize: pageSize, sorted: sorted,page:page}
            }).then(result =>{
                this.setState({loading:false})
                if(authCheck(result.data)){
                    console.log("===> data: ", result.data)
                    this.setState({
                        data    :result.data.response.data,
                        pages   :result.data.response.pages,
                        records : result.data.response.records,
                        pageSize: pageSize,
                    })
                    // if(result.data&&result.data.response&&result.data.response.data){
                    // }else{
                    //     this.setState({
                    //         data:[]
                    //     })
                    // }
                }
            })
        }
    }
    handleFilterdChange(filter){
        this.setState({filtered:filter})
    }
    onShowDetail(docId){
        this.props.history.push(`/vbpq-detail/${docId}`)
    }
    
    render(){
        const { id, parentName, name, filtered, pageSize, permission, records } = this.state
        return(
            <div style = {{textAlign:"center",}}>
               <PageTitle title={parentName} subTitle={name}></PageTitle>
                <div style={{width:"100%", margin:"auto"}}>
                    {
                        (this.user.roleId=="quan-tri-he-thong"||(permission&&permission.write===true))&&
                            <div style = {{textAlign:"left"}}>
                                <Button color = "success" style = {{fontSize:"16px", marginTop:"10px", marginLeft:"0px"}} onClick = {e => this.props.history.push(`/vbpq-add/${id}`)}>Thêm mới</Button>
                            </div>
                    }
                    <div style = {{textAlign:"left", marginTop:"20px", marginBottom:"-10px"}}>
                        <span style={{fontSize:"16px"}}>Tổng số: {records} tài liệu</span>
                    </div>
                    <div style = {{marginTop:"20px"}}>
                        <VBPQTable parentId = {id} 
                            data = {this.state.data} 
                            pages = {this.state.pages} 
                            pageSize={this.state.pageSize} 
                            loading={this.state.loading} 
                            fetchData={this.fetchData} 
                            filtered={filtered}
                            onFilteredChange={this.handleFilterdChange}
                            onDetail = {this.onShowDetail}></VBPQTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withApollo(VBPQPages)
//parentId,data,pages,onDetail
import gql from 'graphql-tag';


export const SEARCH_FILES=gql`
    query($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int)
    {
        response:files(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
            code
            message
            page
            records
            pages
            data{
                _id
                content
                createdTime
                extension
                fileNumber
                fileId
                hidden
                name
                
                parentPath
                path
                priority
                issuedBy
                issuedDate
                slug
                type
                updatedTime
                account{
                    base{
                      fullName
                      userName
                    }
                }
                department
                departmentId
                version
                decisionDate
                decisionNumber
                type
            }
        }
    }
`
import React, { useState } from 'react';
import { Button, ButtonGroup,Col,Row,FormGroup} from 'reactstrap';
import { MdEdit, MdRemove } from 'react-icons/lib/md';
import ReactTable from "react-table-custom";
import Moment from 'moment'

const getDocOptions = (options) => {
    
    var option = ""
    try{
        if(options){
            option = JSON.parse(options)
        }
    }catch(e){
        return option
    }
    return option
}
const DepartmentsTable=({data,loading,fetchData,onDetail})=>{
    const width = 1168
    return <ReactTable
        manual
        noDataText="Không có dữ liệu"
        sortable        ={false}
        filterable      ={false}
        defaultSorted   ={[{id:"issuedDate", desc: true}, {id:"createdTime",desc:true}]}
        data            ={data}
        loading         ={loading} // Display the loading overlay when we need it
        onFetchData     ={fetchData} // Request new data when things change
        defaultPageSize ={20}
        showPagination  ={false}
        className="table-departments"
        style = {{color:"#4E5357", fontSize:"16px", minHeight:"85px"}}
        columns={[
            {
                Header: () => (
                    <div style = {{marginTop:"10px"}}>
                        <i className="fa-tasks" /> STT
                    </div>
                  ),
                width: 50,
                className: "text-center",
                filterable: false,
                Cell: row => <span>
                    {  
                        row.index + 1 
                    }
                </span>
            },
            {
                Header: () => (
                    <div style = {{marginTop:"10px",}}>
                        <i className="fa-tasks" /> PHÒNG BAN
                    </div>
                  ),
                accessor: 'name',
                filterable: false,
                style:{textAlign:"left"},
                Cell:row=><span style={{textAlign:"left",paddingLeft:"10px", textDecoration:"underline",textDecorationColor:"#C6C6C6", cursor:"pointer"}} onClick={()=>onDetail(row.original._id)}>
                    {row.value}
                </span>
            },  
            {
                Header: () => (
                    <div style = {{marginTop:"10px"}}>
                        <i className="fa-tasks" /> CHỨC NĂNG
                    </div>
                  ),
                width:120,
                filterable: false,
                Cell: row => <div>
                    <ButtonGroup>
                        <Button outline onClick={()=>onDetail(row.original.name)} color = "success" style = {{fontSize:"13px"}}>Chi tiết</Button>
                    </ButtonGroup>
                </div>
            }
        ]}
    />
}
export default DepartmentsTable;
import React, { useState, useEffect } from 'react';
import { SignOut, getUser } from 'Constants'
import bn from 'utils/bemnames';
import clsx from 'clsx';
import { withApollo } from "react-apollo";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Icon, IconButton, Typography, Tooltip, InputBase, Divider, Paper, Link, Fade, Popper } from '@material-ui/core'
import { TOTAL_LOGIN } from './query';
import PopupState, { bindTrigger, bindToggle, bindPopper, bindMenu } from 'material-ui-popup-state';
import authCheck from 'utils/authCheck';
import 'assets/css/style.css';
import moment from 'moment';
import userOnlineIcon from 'assets/icons/user_online_icon.png'
import * as Actions from 'pages/Auth/actions';
import {
  Navbar,
  // NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  ListGroup,
  ListGroupItem,
  Badge
} from 'reactstrap';

import {
  MdNotificationsActive,
  MdPersonPin,
  MdClearAll,
  MdExitToApp,
  MdHome
} from 'react-icons/lib/md';

import Avatar from 'react-avatar';
import { UserCard } from 'components/Card';

import withBadge from 'hocs/withBadge';
import SourceLink from 'components/SourceLink';
import { useDispatch, useSelector } from 'react-redux';
const bem = bn.create('header');
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  notify_item: {
    padding: "4px 8px",
    width: 320,
  },
  time: {
    fontSize: 12,
    fontColor: "#cccccc",
    fontStyle: "italic"
  },
  notify_content: {
    fontSize: 13,
    fontColor: "#aaaaaa"
  },
  unread: {
    fontWeight:'bold'
  }
}));

function Header(props) {

  const dispatch = useDispatch();
  const notify = useSelector(({ notify }) => notify.notify);
  
  const [isNotificationConfirmed, setIsNotificationConfirmed] = useState(false);
  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);

  const [totalLogin, setTotalLogin] = useState(0);
  const [key, setKey] = useState('');
  const [user, setUser] = useState(null);
  const [, setLoading] = useState(false);
  const classes = useStyles(props);
  const toggleUserCardPopover = () => {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
  };

  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  useEffect(() => {
    var user = getUser();
    setUser(user);
    fetchLogin();
  }, []);


  function fetchLogin() {
    props.client.query({
      query: TOTAL_LOGIN,
    }).then(result => {
      if (authCheck(result.data)) {
        if (result.data && result.data.response && result.data.response.data) {
          setTotalLogin(result.data.response.data);
        }
      }
    })
  }
  //for super search

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button color="link" style={{ color: "#08AC50" }} onClick={handleSidebarControlButton}>
          <MdClearAll size={35} />
        </Button>
      </Nav>
      <Nav navbar>
        <SourceLink className="navbar-brand d-flex" href="/">

          <span style={{ margin: "3px auto 5px", color: "#08AC50" }}>
            {/* <MdHome style={{ fontSize: 25, marginTop: -5, marginRight: 5 }} /> */}
            THÔNG TIN NỘI BỘ BVPSHN
          </span>
        </SourceLink>
      </Nav>
      <Nav navbar className={bem.e('nav-right')}>
        <NavItem navbar className={bem.e('nav-left')} style={{ marginTop: "15px", marginRight: "40px", fontSize: "16px" }}>

          <div style={{ width: 350, display: "flex" }}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Nhập từ khóa cần tìm"
                inputProps={{ 'aria-label': 'Nhập từ khóa cần tìm' }}
                onChange={(e) => setKey(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    const validKey = encodeURIComponent(key);
                    props.history.push(`/search/${validKey}`);
                  }
                }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={() => {
                const validKey = encodeURIComponent(key);
                props.history.push(`/search/${validKey}`);
              }}>
                <Icon>search</Icon>
              </IconButton>
            </Paper>

          </div>
        </NavItem>

        <NavItem navbar className={bem.e('nav-left')} style={{ marginTop: "25px", marginRight: "20px", fontSize: "16px" }}>
          <Tooltip title="số người online" placement="bottom">
            <Link className="flex">
              <Badge style={{ fontSize: 14, fontColor: "grey" }}>{totalLogin || 1}</Badge>
              <img alt="" src={userOnlineIcon} style={{ objectFit: "contain", width: 18, height: 18 }}></img>
            </Link>
          </Tooltip>
        </NavItem>
        <NavItem navbar className={bem.e('nav-left')} style={{ marginTop: "25px", marginRight: "20px", fontSize: "16px" }}>
          <PopupState variant="popper" popupId="notification" style={{ zIndex: 999 }}>
            {(popupState) => (
              <React.Fragment>
                <Tooltip title={`${notify.unread || 0} thông báo chưa đọc`} placement="bottom">
                  <Link className="flex" id="notify" {...bindToggle(popupState)} >
                    <Badge style={{ fontSize: 14, fontColor: "grey" }}>{notify.unread || 0}</Badge>
                    <Icon style={{ fontSize: 20, color: "grey" }}>notifications</Icon>
                  </Link>
                </Tooltip>
                <Popper {...bindPopper(popupState)} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper style={{marginTop:80}}>
                        {
                          notify.data && notify.data.map((item, index) =>
                            <div className={classes.notify_item} key={index} onClick={() => {
                              popupState.close();                              
                              dispatch(Actions.read_notify(item._id));
                              if(item.data)
                                props.history.push(item.data.url);
                            }}>
                              <Typography className={classes.time}>{moment(item.createdTime).format("HH:mm DD/MM/YYYY")}</Typography>
                              <Typography className={clsx(classes.notify_content,item.read?'':classes.unread)}>{item.body}</Typography>
                              <Divider />
                            </div>
                          )
                        }
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </React.Fragment>
            )}
          </PopupState>

        </NavItem>
        {user &&
          <NavItem>
            <NavLink id="profile">
              <Avatar
                onClick={toggleUserCardPopover}
                src={user.picture}
                size={50}
                round={true}
                name={user.fullName}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={isOpenUserCardPopover}
              toggle={toggleUserCardPopover}
              target="profile"
              className="p-0 border-0"
              style={{ minWidth: 250 }}>
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={user.fullName}
                  subtitle={user.phoneNumber}
                  className="border-light">
                  <ListGroup flush>
                    <ListGroupItem onClick={() => { document.location.href = "/profile" }} tag="button" action className="border-light">
                      <MdPersonPin /> Hồ sơ
                  </ListGroupItem>

                    <ListGroupItem onClick={() => { SignOut() }} tag="button" action className="border-light">
                      <MdExitToApp /> Đăng xuất
                  </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        }

      </Nav>

    </Navbar>
  );

}

export default withApollo(Header);


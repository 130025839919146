import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import UploadFileForm from 'components/Widget/PVT/UploadFileForm'
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { useForm } from 'hooks';
const intialForm = {
  fileId: null,
  preFileId:null,
  name: "",
  note: "",
}
function CreateNewnameForm({isOpen, onCancel, onSuccess, ...props }) {
  //props
  //server
  const { form, handleChange, setInForm } = useForm(intialForm);

  function onAccept() {
    if (!form.name || form.name.length === 0) {
      BaseControl.showErrorToast("Vui lòng nhập phiên bản")
      return
    }
    if (!form.note || form.note.length === 0) {
      BaseControl.showErrorToast("Vui lòng nhập nội dung cập nhật")
      return
    }

    if (!form.fileId) {
      BaseControl.showErrorToast("Vui lòng tải lên file văn bản")
      return
    }
    onSuccess(form)
  }

  return (
    <Modal isOpen={isOpen} className={props.className} size="l" style={{ maxWidth: "640px", backgroundColor: "white" }}>
      <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center' }} ><span style={{ color: "#22BB03", fontWeight: "bold" }}>THÊM PHIÊN BẢN MỚI</span></ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label style={{ fontSize: "16px", fontWeight: "bold", color: "#4E5357", paddingBottom: "5px" }}>Phiên bản</Label>
          <Input type="text" name="name" id="txtname" placeholder="Phiên bản" value={form.name} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label style={{ fontSize: "16px", fontWeight: "bold", color: "#4E5357", paddingBottom: "5px" }}>Nội dung cập nhật</Label>
          <Input type="textarea" name="note" id="txtContent" placeholder="Nội dung cập nhật" value={form.note} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <UploadFileForm
            title="Trang bìa"
            server={window.env.UPLOADURL}
            onUpdateFiles={fileId=>setInForm('preFileId',fileId)}
            allowMultiple={false} maxFiles={1}
            style={{ width: "100%"}}
          />
        </FormGroup>
        <FormGroup>
          <UploadFileForm
            title="Nội dung văn bản"
            server={window.env.UPLOADURL}
            onUpdateFiles={fileId=>setInForm('fileId',fileId)}
            allowMultiple={false} maxFiles={1}
            style={{ width: "100%"}}
          />
        </FormGroup>
        <Label>Chú ý: Nếu tài liệu này gồm nhiều file, hệ thống sẽ tự động ghép các file văn bản được tải lên thành 1 file pdf.</Label>

      </ModalBody>
      <ModalFooter>
        <Button color="success" size="lg" onClick={onAccept} style={{ fontSize: "16px" }}>XÁC NHẬN</Button>{' '}
        <Button color="danger" size="lg" onClick={onCancel} style={{ fontSize: "16px" }}>HỦY</Button>
      </ModalFooter>
    </Modal>
  );

}

export default CreateNewnameForm;
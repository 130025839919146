import Page from 'components/Page';
import React from 'react';
import { Button, CardBody, Input, Col, Label, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import gql from 'graphql-tag';
import { Query, withApollo } from 'react-apollo'
import { MdArrowBack, MdAddCircle } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import { ValidFormDate } from "utils/dateHelper"
import confirm from 'components/Confirmation';
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
export const FEED_QUERY = gql`
{
    response:me{
        code
        message
        data{
            _id
            roleId
            roleName
            accountGroups{
              _id
              accountId
              groupName
              groupId
              isAdmin
              permission{
                delete
                deleteFeedback
                download
                edit
                print
                write
                feedback
              }
            }
            base{
              oauth_token
              fullName
              userName
              code
              birthdate
              email
              gender
              isRoot
              sub
              picture
              department
            }
            
          }
    }
}
`

class ProfilePage extends React.Component {
    render = () => {
        return (
            <div>
                <PageTitle title="THÔNG TIN TÀI KHOẢN"></PageTitle>
                <div style={{ marginTop: "40px", fontWeight: "bold", fontSize: "15px" }}>
                    <CardBody>
                        <Query
                            query={FEED_QUERY}>
                            {
                                ({ loading, error, data }) => {
                                    if (error) {
                                        authCheck(error);
                                        //return <div>Có lỗi load dữ liệu</div>
                                        data = {};
                                    }

                                    if (data.response) {
                                        const {
                                            roleName
                                        } = data.response.data;
                                        const {
                                            address,
                                            birthday,
                                            email,
                                            fullName,
                                            gender,
                                            department,
                                            title,
                                            image,
                                            phoneNumber,
                                            userName
                                        } = data.response.data.base;
                                        return (
                                            <Form className="m-4">
                                                <FormGroup row>
                                                    <Label md={3} sm={5} className="text-right">Tài khoản:</Label>
                                                    <Col md={6} sm={7}>
                                                        <Input value={userName} type="text" disabled={true} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={3} sm={5} className="text-right">Họ và tên:</Label>
                                                    <Col md={6} sm={7}>
                                                        <Input value={fullName} type="text" disabled={true} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={3} sm={5} className="text-right">Ngày sinh:</Label>
                                                    <Col md={6} sm={7}>
                                                        <Input value={ValidFormDate(birthday)} type="text" disabled={true} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={3} sm={5} className="text-right">Địa chỉ:</Label>
                                                    <Col md={6} sm={7}>
                                                        <Input value={address} type="text" disabled={true} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={3} sm={5} className="text-right">Số điện thoại:</Label>
                                                    <Col md={6} sm={7}>
                                                        <Input value={phoneNumber} type="text" disabled={true} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={3} sm={5} className="text-right">Email:</Label>
                                                    <Col md={6} sm={7}>
                                                        <Input value={email} type="text" disabled={true} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={3} sm={5} className="text-right">Phòng ban:</Label>
                                                    <Col md={6} sm={7}>
                                                        <Input value={department.Name} type="text" disabled={true} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={3} sm={5} className="text-right">Chức danh: </Label>
                                                    <Col md={6} sm={7}>
                                                        <Input value={title} type="text" disabled={true} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={3} sm={5} className="text-right">Quyền: </Label>
                                                    <Col md={6} sm={7}>
                                                        <Input value={roleName} type="text" disabled={true} />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        )
                                    } else
                                        return <div>Không thể load thông tin tài khoản</div>;

                                }
                            }

                        </Query>

                    </CardBody>
                </div>
            </div>
        )
    }
}


export default withApollo(ProfilePage);
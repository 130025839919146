import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Button, Row, Col, Input } from 'reactstrap';
import { getToken, getUser } from "Constants";
import moment from 'moment';

export function ViewControl({ data, ...props }) {
    const [user] = useState(getUser());
    const {fileNumber, issuedBy, issuedDate, content, docType, account } = data;

    return (

        <CardBody >
            <div style={{ padding: "20px" }}>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Loại văn bản</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input disabled value={docType} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span style={{ paddingLeft: "30px" }}>Số hiệu</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input value={fileNumber ? fileNumber : ""} disabled></Input>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Row 2 */}
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Ngày ban hành</span>
                            </Col>
                            <Col sm={8}>
                                <Input value={moment(issuedDate).format("DD/MM/YYYY")} disabled />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span style={{ paddingLeft: "30px" }}>Người tạo</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input disabled value={account&&account.base&&account.base.fullName}></Input>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row >
                    <Col sm={6}>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Đơn vị ban hành</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input disabled value={issuedBy} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Nội dung văn bản</span>
                            </Col>
                            <Col sm={10}>
                                <div style={{ display: "flex" }}>
                                    <Input type="textarea" disabled value={content} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </CardBody>

    )
}
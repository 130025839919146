import Page from 'components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,FormGroup,Label,CardHeader,CardBody} from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo'

import {MdEdit,MdAddCircle,MdDelete} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import editIcon from 'assets/icons/edit_icon.png'
import deleteIcon from 'assets/icons/delete_icon.png'
import ReactTable from "react-table-custom";
import confirm from 'components/Confirmation';
import {QUERY_ROLETEMPLATES,DELETE_ROLETEMPLATE} from './query';


class RoleManager extends React.Component{

    state={
        data: [],
        pages: 1,
        loading: true
    }
    constructor(){
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    onDelete = (row, mutation) => {
        confirm(`Bạn có chắc chắn muốn xóa chức danh '${row.name}'?`, { title: "Xóa bản ghi" }).then(
            (result) => {
                mutation();
            },
            (result) => {
                // `cancel` callback

            }
        )
    }
    onCancel=()=>{
        this.props.history.goBack();
    }
    fetchData= async(state, instance)=>{
        
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        try{
            const result = await this.props.client.query({
                query: QUERY_ROLETEMPLATES,
                variables: { filtered,pageSize,sorted,page },                
            })
            authCheck(result);
            this.setState({data:result.data.response.data,pages:result.data.response.pages,loading:false});
        }catch(err){
           authCheck(err);
        }
    }
    render=()=>{
        const {data,loading,pages}=this.state;
        return(
            <Page className="CustomerPage">   
                <div style = {{textAlign:"center"}}>
                    <h2 style = {{marginTop:"15px",fontWeight:"bold", color:"#08AC50"}}>QUẢN LÝ CHỨC DANH</h2>
                    <div style = {{textAlign:"left"}}>
                        <Button color = "success" style = {{fontSize:"16px", marginTop:"10px", marginLeft:"10px"}} onClick = {e => this.props.history.push("/role-manager-edit")}>Thêm mới</Button>
                    </div>
                </div>
                <div style = {{marginTop:"20px"}}></div>
                <ReactTable 
                    noDataText="Không có dữ liệu"
                    columns={[ 
                        {
                            Header: "STT",
                            width: 50,
                            className: "text-center",
                            filterable: false,
                            Cell: row => <span>
                                {row.index + 1}
                            </span>
                        },
                        {
                            Header:"ID",
                            accessor:"id"
                        },
                        {
                            Header:"Tên chức danh",
                            accessor:"name"
                        },  
                        {
                            Header:"Số menu truy cập",
                            accessor:"menuIds",
                            Cell:row=><span>{row.value.length}</span>
                        } ,
                        {
                            Header:"Số quyền",
                            accessor:"claims",
                            Cell:row=><span>{row.value.length}</span>
                        },
                        {
                            Header:"Tác vụ",                      
                            maxWidth:100,
                            filterable:false,
                            sortable:false,
                            Cell: row=>(
                            <div style ={{ textAlign: "center" }}>
                                <ButtonGroup>
                                    <Button onClick={()=>this.props.history.push("/role-manager-edit/"+row.original.id)} color="" ><img src = {editIcon} alt = "edit" style = {{objectFit:"cover", width: "20px"}}></img></Button>
                                    <Mutation
                                        mutation={DELETE_ROLETEMPLATE}
                                        variables={{id:row.original.id}}
                                        onCompleted={e=>{this.fetchData({filtered:[],sorted:[],page:0,pageSize:10})}}
                                    >
                                        {mutation=><Button onClick={e=>{this.onDelete(row.original,mutation)}} color="" ><img src = {deleteIcon} alt = "delete" style = {{objectFit:"cover", width: "20px"}}></img></Button>}
                                    </Mutation>
                                    
                                </ButtonGroup>
                            </div>
                            )
                        }
                    ]}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    data={data}
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchData} // Request new data when things change
                    pageSize={data.length<5?5:data.length}
                    showPagination={false}
                    className="table-document"
                >

                </ReactTable>
                    

            </Page>
        )
    }
}

export default withApollo(RoleManager);
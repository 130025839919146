import React, { Fragment } from "react";
import { Row, Col, Input,CardBody,Label,Form,FormGroup,Modal,ModalHeader,ModalBody,ModalFooter,ButtonGroup, Button } from 'reactstrap'
import { withApollo } from "react-apollo";
import authCheck from "utils/authCheck";
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import confirm from 'components/Confirmation';
import { MdAdd, MdDelete, MdSave, MdArrowBack } from 'react-icons/lib/md'
import { QUERY_SLUG, CREATE_SLUG, UPDATE_SLUG } from 'GraphQL/slug';
export const unitName = "slug_dvbh_"

class UnittDetail extends React.Component{
    initState = (isInit) => {
        this.state = {
            _id:null,
            name: "",
            type: "slug_dvbh"
        } 
        if(isInit){
            this.setState(this.state);
        }
    }
    constructor(props){
        super(props);
        this.onBack = this.onBack.bind(this)
        this.initState(true);
    }
    componentDidMount(){
        var _id = this.props.match.params._id;
        if(_id){
            this.fetchData(_id);
        }
    }
    fetchData = (_id) =>{
        this.props.client.query({
            query: QUERY_SLUG,
            variables: { _id }
        }).then(result => {
            authCheck(result);
            if(result.data.response.code == 0){
                if(result.data.response.data==null){
                    this.initState();
                }
                else{
                    this.setState(result.data.response.data);
                }
            }
            else{
                this.initState();
            }
        })
    }
    onBack(){
        this.props.history.goBack();
    }
    onSave = () => {
        const { _id, name, type} = this.state;
        if(_id&&_id!=null){
            this.props.client.mutate({
                mutation: UPDATE_SLUG,
                variables: {slug: {_id, name, type}}
            }).then(result =>{
                authCheck(result.data);
                if(result.data.response.code == 0){
                    confirm("Cập nhật đơn vị ban hành thành công, bạn có muốn cập nhật lại không?").then(_ =>{
                        this.fetchData(_id);
                    },
                    _=>{
                        this.props.history.goBack();
                    })
                }
                else{
                    confirm(result.data.response.message, { showCancel: false });
                }
            })
        }else{
            this.props.client.mutate({
                mutation: CREATE_SLUG,
                variables: {slug: {_id, name, type}}
            }).then(result =>{
                if(result.data.response.code == 0){
                    confirm("Thêm mới đơn vị ban hành thành công! Bạn có muốn tiếp tục thêm không?").then(_ =>{
                        this.initState(true);
                    },
                    _=>{
                        this.props.history.goBack();
                    })
                }
                else{
                    confirm(result.data.response.message, { showCancel: false });
                }
            })
        }
    }
    render = () => {
        const{ name, type, _id} = this.state;
        return(
            <div>
                <div  style={{ fontSize: "15px" }}>
                    <PageTitle onClick={e => this.onBack} title="CẬP NHẬT THÔNG TIN ĐƠN VỊ BAN HÀNH"></PageTitle>
                    <div style = {{marginTop:"20px"}}></div>
                    <CardBody>
                        <Form className = "m-4">
                            <FormGroup row>
                                <Label md = {3} sm = {5} className = "text-right">
                                    Tên đơn vị ban hành
                                </Label>
                                <Col md = {6} sm = {7}>
                                    <Input type = "text" value = {name} onChange = {e=>this.setState({name: e.target.value})}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3} sm={5}></Label>
                                <Col md={6} sm={7}>
                                    <div style = {{ float: "right" }}>
                                        <Button color= "success" style = {{ fontSize: "16px" }} onClick = {this.onSave}>XÁC NHẬN</Button>
                                        <span> </span>
                                        <Button color= "danger" style = {{ fontSize: "16px" }} onClick={()=>this.props.history.push("/unit-manager")}>HỦY</Button>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </div>
            </div>
        );
    }
}
export default withApollo(UnittDetail);
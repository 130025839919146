import React, {useState} from "react";
import {client} from 'utils/apolloConnect'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { SLUG_DOCUMENT_TYPE, SLUG_DVH } from 'Constants'
import { CREATE_SLUG } from 'GraphQL/PVT/Slug'
import authCheck from 'utils/authCheck';


class  AddSlugForm extends React.Component
{
    state = {
        name: ""
    }
    constructor(props){
        super(props)
        this.setState({

        })
        this.onChangeName = this.onChangeName.bind(this)
    }
    
    componentDidMount(){
    }
    componentWillReceiveProps(willProps)
    {
        this.setState({name:""})
    }

    onChangeName(e){
        this.setState({name:e.target.value})
    }

    render(){
        const {name} = this.state
        var title = this.props.type===SLUG_DOCUMENT_TYPE?"THÊM LOẠI VĂN BẢN":"THÊM ĐƠN VỊ BAN HÀNH"
        const labelTitle = this.props.type===SLUG_DOCUMENT_TYPE?"Loại văn bản":"Đơn vị ban hành"
        const placeholderTitle = this.props.type===SLUG_DOCUMENT_TYPE?"Loại văn bản":"Đơn vị ban hành"
        return (
            <Modal isOpen={this.props.isOpen} className={this.props.className} size="l" style = {{maxWidth:"640px",backgroundColor:"white",...this.props.style}}>
              <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} ><span style = {{color:"#22BB03", fontWeight:"bold"}}>{title}</span></ModalHeader>
              <ModalBody>
              <FormGroup>
                <Label style = {{fontSize:"16px", fontWeight:"bold", color:"#4E5357", paddingBottom:"5px"}}>{labelTitle}</Label>
                <Input type="text" name="name" id="txtName" placeholder={placeholderTitle} value={name} onChange={e=>{this.setState({name:e.target.value})}} />
            </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button onClick = {e => {
                    console.log("slug Name: ",name)
                    if(name&&name.length>0){
                            var slug = {
                                name: name,
                                type: this.props.type,
                            }
    
                            client.query({
                            query: CREATE_SLUG,
                            variables: {  slug: slug}
                        }).then(result =>{
                            if(authCheck(result.data)){
                                console.log("create Slug success: ", result.data)
                                this.props.onSuccess()
                            }
                        })
                    }
                }} color="success" size="lg" style = {{fontSize:"16px"}}>XÁC NHẬN</Button>{' '}
                <Button color="danger" size="lg" onClick={this.props.onCancel} style = {{fontSize:"16px"}}>HỦY</Button>
              </ModalFooter>
            </Modal>
        );
    }
}

export default AddSlugForm
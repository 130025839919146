import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import QTQDable from 'pages/Documents/FileTables/PVT/QTQDTable'
import { QUERY_DIRECTORY, GET_ALL_FILES } from './query'
import authCheck from 'utils/authCheck';
import { withApollo } from 'react-apollo';
import { getUser } from 'Constants';


function QTQDPages(props) {
    const [filterOption, setFilterOption] = useState({ page: 0, pageSize: 20, filtered: [], sorted: [{ id: 'createdTime', desc: true }] });
    const [directory, setDirectory] = useState(null);
    const [fileData, setFileData] = useState({ pages: 1, records: 0, data: [] });
    const [loading, setLoading] = useState(false);
    const [user] = useState(getUser());
    const [selected, setSelected] = useState(null);
    const fetchDir = (id) => {
        // const { parentPath } = state;
        props.client.query({
            query: QUERY_DIRECTORY,
            variables: { _id: `${id}` }
        }).then(result => {
            if (authCheck(result.data)) {
                setDirectory(result.data.response.data);
            }
        })
    }

    function fetchFile() {
        const { filtered, sorted, pageSize, page } = filterOption;

        var copy = [...filtered, { id: "parentId", value: `${directory._id}` }]
        props.client.query({
            query: GET_ALL_FILES,
            variables: { filtered: copy, pageSize: pageSize, sorted: sorted, page: page }
        }).then(result => {
            authCheck(result.data)
            setLoading(false);
            if (result.data && result.data.response && result.data.response.data) {
                setFileData(result.data.response);
            }
        })
    }
    useEffect(() => {
        var id = props.match.params.id;
        if (id) {
            fetchDir(id);
        }
    }, [props]);
    useEffect(() => {
        if (directory && directory._id) {
            fetchFile();
        }
    }, [directory, filterOption]);

    function handleFilterdChange(filtered) {        
        setFilterOption({ ...filterOption, filtered })
    }
    function onShowDetail(docId) {
        props.history.push(`/qtqd-detail/${docId}`)
    }

    return (
        directory ? <div style={{ textAlign: "center" }}>
            <PageTitle title={directory.parent && directory.parent.name} subTitle={directory.name}></PageTitle>
            <div style={{ width: "100%", margin: "auto" }}>
                {(user.roleId == "quan-tri-he-thong" || (directory.permissions && directory.permissions.length > 0 && directory.permissions[0].write === true)) &&
                    <div style={{ textAlign: "left" }}>
                        <Button color="success" style={{ fontSize: "16px", marginTop: "10px", marginLeft: "00px" }} onClick={e => props.history.push(`/qtqd-add/${directory._id}`)}>Thêm mới</Button>
                    </div>
                }

                <div style={{ textAlign: "left", marginTop: "20px", marginBottom: "-10px" }}>
                    <span style={{ fontSize: "16px" }}>Tổng số: {fileData.records} tài liệu</span>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <QTQDable parentId={""}
                        data={fileData.data}
                        pages={fileData.pages}
                        pageSize={filterOption.pageSize}
                        loading={loading}
                        // fetchData={fetchData}
                        // filtered={filterOption.filtered}//thang nay se gay loi ne
                        onFilteredChange={handleFilterdChange}

                        onDetail={onShowDetail}></QTQDable>
                </div>
            </div>
            
        </div> :
            <div>Thư mục không tồn tại</div>
    )

}

export default withApollo(QTQDPages);
//parentId,data,pages,onDetail
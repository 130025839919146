import gql from 'graphql-tag';

export const ALL_SLUG_BY_TYPE=gql`
query($type:String!){
    response:slugs(filtered:[{id:"type",value:$type}]){
        code
        message
        data{
            _id
            name
        }
    }
}
`

export const GET_LIST_SLUG=gql`
query($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
  response:slugs(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
    code
    message
    pages
    page
    records
    data{
      _id
      name
      type
    }
  }
}
`

export const CREATE_SLUG=gql`
mutation($slug:SlugInput!){
    response:createSlug(slug:$slug){
      code
      message
      data{
        _id
        name
        type
      }
    }
  }
`
export const QUERY_SLUG = gql`
query($_id: String!){
  response: slug(_id: $_id){
    code
    message
    data{
      _id
      type 
      name
    }
  }
}
`
export const DELETE_SLUG = gql`
mutation($_id: String){
  response: deleteSlug(_id: $_id){
    code 
    message
  }
}
`
export const UPDATE_SLUG = gql`
mutation($slug: SlugInput){
  response:updateSlug(slug: $slug){
    code 
    message
    data{
      _id
      name
      type
    }
  }
}
`
export const LIST_SLUG = gql`
query($filtered: [FilteredInput], $sorted: [SortedInput], $page: Int, $pageSize: Int){
  response: slugs (filtered:$filtered, sorted: $sorted, page: $page, pageSize: $pageSize){
    code
    pages
    records
    message
    data{
      _id
      name
      type
    }
  }
}
`
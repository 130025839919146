import gql from 'graphql-tag';

export const REGISTER_DEVICE = gql`
mutation($data:PushDeviceInput){
    response:registerDevice(data:$data){
      code
      message
    }
  }
`
export const GET_NOTIFY = gql`
query($page:Int,$pageSize:Int,$sorted:[SortedInput]){
  response:notification_sents(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:[{id:"data.Type",value:"dms-",operation:"~i"}]){
    code
    message
    records
    data{
      _id
      title
      appId
      body
      createdTime
      content
      data
      read
    }
  }
}
`
export const GET_UNREAD_NOTIFY_NUM = gql`
{
  response:unread_notification_sent_num{
    code
    message
    data
  }
}
`

export const DO_READ_NOTIFY = gql`
mutation ($_id:String!){
  response:read_notice(_id:$_id){
    code
    message
    data
  }
}
`
import React from 'react';
import ReactDOM from 'react-dom';
import './react-table-custom';

import App from './App';
import * as serviceWorker from './serviceWorker';

var hostname = window.location.hostname;
// var apiHost="apiv2.app.benhvienphusanhanoi.vn:4002";

ReactDOM.render(     
      <App />,
   document.getElementById('root')
) 
serviceWorker.register({
   onSuccess: async (register) => {
     // console.log("register success");
     const subscription = await register.pushManager.subscribe({
       userVisibleOnly: true,
       applicationServerKey: urlBase64ToUint8Array(window.env.PublicVapidKey),
     });
     // console.log("get subscription info");
     var p256dh = base64Encode(subscription.getKey('p256dh'));
     var auth = base64Encode(subscription.getKey('auth'));
     // console.log("subscription Info = ", subscription, " p256dh=", p256dh, "auth=", auth);
     //lưu mọi thứ vào store để chờ khi login sẽ gọi
     localStorage.setItem('device', JSON.stringify({ p256dh, auth, tokenId: subscription.endpoint }));
   },
   onUpdate: (register) => {
     console.log("onUpdate Push:", register);     
   }
 });
 
 
 function base64Encode(arrayBuffer) {
   return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
 }
 function urlBase64ToUint8Array(base64String) {
   const padding = '='.repeat((4 - base64String.length % 4) % 4);
   const base64 = (base64String + padding)
     .replace(/-/g, '+')
     .replace(/_/g, '/');
 
   const rawData = window.atob(base64);
   const outputArray = new Uint8Array(rawData.length);
 
   for (let i = 0; i < rawData.length; ++i) {
     outputArray[i] = rawData.charCodeAt(i);
   }
   return outputArray;
 }
 
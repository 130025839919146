import { combineReducers } from 'redux';
import * as Actions from 'store/actions';


const dialogs = (state = [], action) => {
    switch (action.type) {
        case Actions.SHOW_QUICK_DIALOG: {
            //loc theo id
            if (!action.dialog||!action.dialog.id) {
                //bat buoc phai co id
                return state;
            }
            const filter = state.filter(item => item.id !== action.dialog.id);
            return [...filter, action.dialog];
        }
        case Actions.HIDE_QUICK_DIALOG: {
            const filter = state.filter(item => item.id !== action.data);
            return filter;
        }
        default: {
            return state;
        }
    }
}
export default combineReducers({ dialogs });

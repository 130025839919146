import React, { useState, useEffect } from 'react';
import { Icon, Button, IconButton, FormGroup, Input, InputLabel, NativeSelect, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import ReactTable from 'react-table';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import {hideQuickTableDialog } from 'components/QuickTableDialog';
import { useDispatch } from 'react-redux';

import _ from '@lodash';
///data ở đây nên là dạng đối tượng gồm{ data:[],pages:1,records:10}
export default function QuickTableDialog({ title, subtitle, columns, initData, onFetchData, onUpdateRef, ...props }) {            
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(hideQuickTableDialog());
    };
    const [data, setData] = useState(initData);
    console.log("data=", data);
    if (onUpdateRef) {
        onUpdateRef.current = (newData) => {
            setData(newData);
        }
    }

    return (
        <Card className={props.className}>
            <CardHeader className="el-CardHeader-FU"
                action={
                    <IconButton aria-label="settings" onClick={handleClose}>
                        <Icon>close</Icon>
                    </IconButton>
                }
                title={title}
                subheader={subtitle}
            />
            <CardContent className="el-CardContent-FU">
                <ReactTable                    
                    className="-striped -highlight w-full h-full sm:rounded-8"
                    data={data&&data.data}
                    defaultPageSize={10}
                    pages={data&&data.pages?data.pages:1}
                    onFetchData={onFetchData}
                    getTdProps={() => ({
                        style: { border: `none` },
                    })}
                    columns={columns}
                />
            </CardContent>
            {/* <CardActions>                
                <Button color="default" onClick={handleClose}>
                    Đóng
                </Button>
            </CardActions> */}
        </Card>
    )
}
import { client } from 'utils/rootApolloConnect';

import { REGISTER_DEVICE,GET_NOTIFY ,DO_READ_NOTIFY,GET_UNREAD_NOTIFY_NUM} from './query';
import { LOAD_NOTIFY,SET_UNREAD} from "store/actions"
export function load_notify() {
    return (dispatch) => {
        client.query({
            query: GET_NOTIFY,
            variables:{ pageSize:10,page:0,sorted:[{id:"CreatedTime",desc:true}]}
        }).then(response => {
            dispatch({
                type: LOAD_NOTIFY,
                payload: response.data.response
            })
        })
    }
    
}
export function get_unread_notify_num() {
    return(dispatch)=> client.query({
        query: GET_UNREAD_NOTIFY_NUM
    }).then(response => {
        dispatch({
            type: SET_UNREAD,
            payload: response.data.response.data
        })
    })
}
export function read_notify(_id) {
    return(dispatch)=> client.mutate({
        mutation: DO_READ_NOTIFY,
        variables:{_id}
    }).then(response => {
        dispatch({
            type: SET_UNREAD,
            payload: response.data.response.data
        })
    })
}
export function register_device(data) {
    return client.mutate({
        mutation: REGISTER_DEVICE,
        variables: {data}
    });
}

import gql from 'graphql-tag';


export const GET_TUTORIALES=gql`
    query getTutoriales($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int)
    {
        response:articles(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
            code
            message
            page
            pages
            records
            data
            {
                _id
                content
                createdTime
                disable
                name
                shortDesc
                slug
                thumb
                updatedTime
            }
        }
    }
`

export const GET_TUTORIAL = gql`
query ArticleDetail($_id:String!){
  response:article(_id:$_id){
    data{
      _id
      content
      shortDesc
      thumb
      name
      slug
      # title
      disable
    }
    
  }
}
`;
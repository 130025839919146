import React from 'react'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import TutorialTable from 'pages/Documents/FileTables/PVT/TutorialTable'
import authCheck from 'utils/authCheck';
import { withApollo } from 'react-apollo'
import { GET_TUTORIALES } from './query';

function createMarkup(content){
    return {__html: `${content}`};
  }
class RuleDetailPage extends React.Component
{
    state = {
        article : null,
        _id     : null,
    }
    constructor(props){
        super(props)
        this.setState({

        })
    }
    componentDidMount(){
      window.scrollTo(0, 0);
      this.props.client.query({
        query: GET_TUTORIALES,
        variables: {
          filtered: [
            {
              id:'slug',value:'rules'
            },
            {
              id: "disable",
              value:"false"
            }
          ],
          pageSize: 1,
          sorted: [
            {
              id:"updatedTime",desc:true
            }
          ]
        }
      }).then(result => {
        if (result.data.response.data&&result.data.response.data.length>0) {
          this.setState({ article: result.data.response.data[0] });
        }
      })
        // const _id = this.props.match.params._id;
      //   const _id = "5d99be716ca68c35ed64f44c";
      //   if(_id){
      //     this.setState({_id:_id});
      //     //fetch detail
      //     this.props.client.query({
      //         query:QUERY_DETAIL,
      //         variables:{_id:_id},
              
      //     }).then(result=>{
      //       if(authCheck(result)){
      //           const article=result.data.response.data;    
      //           this.setState({article});   
      //       }       
      //     });
      // }
    }

    render(){
        const { article } = this.state
        return(
            <div style = {{textAlign:"center"}}>
                <PageTitle onClick={e => window.history.back()} title={"NỘI QUY"} subTitle={""}></PageTitle>
                <div style={{maxWidth:"1280px", margin:"auto"}}>
                    <div style = {{ backgroundColor:"white", padding:"30px"}}>
                        {article&&article.content&&<div dangerouslySetInnerHTML={createMarkup(article.content)} />}
                    </div>
                </div>
            </div>
        )
    }
}
export default withApollo(RuleDetailPage);
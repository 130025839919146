import gql from 'graphql-tag';

export const CREATE_FILE_VERSION = gql`
    mutation createFileVersion($version:FileVersionInput!)
    {
        response:createFileVersion(version:$version)
        {
            code
           message
           data{
            _id
            createdTime
            docId
            fileId
            name
            note
            updatedTime
           }
        }
    }
`

export const DELETE_FILE_VERSION = gql`
    mutation deleteFileVersion($_id:String!)
    {
        response:deleteFileVersion(_id:$_id)
        {
            code
            message
        }
    }
`


export const GET_DOC_COMMENTS=gql`
    query getDocComments($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int)
    {
        response:docComments(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
            code
            message
            page
            pages
            records
            data{
                _id
                account{                                        
                    base{
                        fullName                        
                        department
                    }
                }
                children{
                    _id
                    account{
                        base{
                            fullName
                            department
                        }
                    }
                    comment
                    createdTime
                    docId
                    name
                    parentId
                    section
                    subSection
                    updatedTime                    
                    read
                    docFile{
                        name
                    }
                }
                comment
                createdTime
                docId
                name
                parentId
                section
                subSection
                updatedTime                
                read
                docFile{
                    name
                }
            }
        }
    }
`
export const CREATE_DOC_COMMENT=gql`
    mutation createDocComments($comment:DocCommentInput!){
        response:createDocComment(comment:$comment){
            code
            message
        }
    }
`

export const UPDATE_READ_COMMENT=gql`
    mutation updateCommentRead($_id:String!)
    {
        response:updateCommentRead(_id:$_id){
            code
            message
            data
        }
    }
`
export const DELETE_DOC_COMMENT=gql`
    mutation deleteDocComment($_id:String!)
    {
        response:deleteDocComment(_id:$_id){
            code
            message
            data{
                _id
            }
        }
    }
`


export const CREATE_APPENDIX=gql`
    mutation createAppendixes($docId:String!, $data:[AppendixInput]!)
    {
        response:createAppendixes(docId: $docId, data: $data){
            code
            message
            data{
                _id
                code
                docId
                fileId
                fileUrl
                pdfUrl:fileUrl(ispdf:true)
                name
                fileInfo(ispdf:false){
                    name
                    filePath
                    oriExtension
                    createdTime
                }
            }
        }
    }
`
export const DELETE_APPENDIX=gql`
    mutation deleteAppendix($_id: String!){
        response:deleteAppendix(_id: $_id){
            code
            message
        }
    }
`
export const UPDATE_APPENDIX=gql`
    mutation updateAppendix($data:AppendixInput!)
    {
        response:updateAppendix(data:$data)
        {
            code
            message
            data{
                _id
                code
                docId
                fileId
                fileUrl
                pdfUrl:fileUrl(ispdf:true)
                name
                fileInfo(ispdf:false){
                    name
                    filePath
                    oriExtension
                    createdTime
                }
            }
        }
    }
`
export const GET_APPENDIXES=gql`
    query getAppendixes($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int)
    {
        response:appendixes(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
            code
            message
            page
            pages
            records
            data{
                _id
                code
                docId
                fileId
                fileUrl
                pdfUrl:fileUrl(ispdf:true)
                name  
                fileInfo(ispdf:false){
                    name
                    filePath
                    oriExtension
                    createdTime
                }
            }
        }
    }
`
import React from 'react'
import BackButton from 'components/Widget/PVT/BackButton'

const PageTitleWidget=({onClick=null,title,subTitle = "", style = null})=>{
    return(
        <div style={{display:"block", textAlign:"center", ...style}}>
            {onClick!==null ? <BackButton onClick={e => {window.history.back()}}></BackButton> : null}
            <h2 style = {{marginTop:"10px",fontWeight:"bold", color:"#08AC50"}}>{title}</h2>
            <h4 style = {{marginTop:"5px",fontWeight:"bold", color:"#888888"}}>{subTitle}</h4>
        </div>
    )
}
export default PageTitleWidget;
import React from 'react'
import { withApollo } from 'react-apollo'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import FeedbacksTable from 'pages/Documents/FileTables/PVT/FeedbacksTable'
import { GET_DOC_COMMENTS } from './query'
import authCheck from 'utils/authCheck';

class Feedbacks extends React.Component
{
    state = {
        data        : [],
        pages       : 1,
        loading     : false,

        filtered    : [],
        pageSize    : 20,
    }
    constructor(props){
        super(props)
        this.setState({

        })
        this.onShowDetail   = this.onShowDetail.bind(this)
        this.fetchData      = this.fetchData.bind(this)
        this.onShowDetail = this.onShowDetail.bind(this)
        this.handleFilterdChange   = this.handleFilterdChange.bind(this)
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        this.fetchData({filtered:[], sorted:[], pageSize: 20, page:0})
    }

    componentWillReceiveProps(props) {
    }

    //fetch data
    fetchData(state){
        this.setState({loading:true})
        const {filtered, sorted, pageSize, page} = state
        console.log("state table: ", state)
        var sort = [...sorted,{id:"createdTime", desc:true}]
        this.props.client.query({
            query: GET_DOC_COMMENTS,
            variables: {  page:  page, sorted: sort, pageSize:pageSize, filtered: filtered}
        }).then(result =>{
            if(authCheck(result.data)){
                // console.log("file comments:", result.data.response.data)
                this.setState({loading:false})
                if(result.data&&result.data.response&&result.data.response.data){
                    const data = result.data.response.data
                    this.setState({data: data,pages:result.data.response.pages,pageSize: pageSize})
                }
            }
        })
    }

    handleFilterdChange(filter){
        console.log("filter change: ", filter)
        this.setState({filtered:filter})
    }
    //func
    onShowDetail(index){
        const {data} = this.state
        const item = data[index]
        if(item){
            const {docId, _id} = item
            console.log(`show Detail: docId: ${docId} commentId: ${_id}`)
            this.props.history.push(`qtqd-detail/${docId}/${_id}`)
        }
    }

    render(){
        const {filtered, pageSize } = this.state
        return(
            <div style = {{textAlign:"center"}}>
                <PageTitle title={"DANH SÁCH GÓP Ý"}></PageTitle>
                <div style={{maxWidth:"1280px", margin:"auto"}}>
                    <div style = {{marginTop:"20px", backgroundColor:"white"}}>
                        <FeedbacksTable 
                             data = {this.state.data} 
                             pages = {this.state.pages} 
                             pageSize={pageSize} 
                             loading={this.state.loading} 
                             fetchData={this.fetchData} 
                             filtered={filtered}
                             onFilteredChange={this.handleFilterdChange}
                            onDetail = {this.onShowDetail}></FeedbacksTable>
                    </div>
                </div>
            </div>
        )
    }

}

export default withApollo(Feedbacks)
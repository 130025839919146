import Page from "components/Page";
import React from "react";
import { Button, Input, Row, Col, Label, Form, FormGroup, Card, CardBody, CardHeader, ButtonGroup } from "reactstrap";
import { withApollo } from "react-apollo";
import authCheck from "utils/authCheck";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { ALL_SLUG_BY_TYPE } from 'GraphQL/slug';
import { MdAdd } from "react-icons/lib/md";
import showSlugModal from 'Modals/SlugModal'
import { CREATE_FILE, UPDATE_FILE, GET_FILE_DETAIL, QUERY_DOCUMENT_DETAIL } from 'GraphQL/document'
import moment from 'moment';

import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';

import 'moment/locale/vi';

class VBPQ extends React.Component {

    state = {
        slugs: [],
        _id: null,
        file: null,
        slug: "",
        options: {type:""},
        name: "",
        content: "",
        publishDate: new Date(),
        fileNumber: "",
        parentId: "",
        parentPath: "",
        parentName: ""
    }
    constructor(props) {
        super(props);
        const{_id,slug,parentPath,parentId,parentName} = props;
        this.state={_id,slug,parentPath,parentId,parentName};
    }
    
    fetchAllFileSlug = () => {
        this.props.client.query({
            query: ALL_SLUG_BY_TYPE,
            variables: { type: "loai-van-ban-phap-quy" }
        }).then(result => {
            authCheck(result.data);
            this.setState({ slugs: result.data.response.data });
        })
    }
    fetchFileDetail = (_id) => {
        const {slug}=this.props;
        
        this.props.client.query({
            query: GET_FILE_DETAIL,
            variables: { _id }
        }).then(result => {
            if (authCheck(result.data.response)) {
                var data = result.data.response.data;
                if (data.publishDate)
                    data.publishDate = moment(data.publishDate).toDate();
                if(data.slug!==slug)
                    data.slug=slug;
                if(data.options!==null){
                    data.options=JSON.parse(data.options);
                }
                this.setState(data);
            }
        })
    }
    componentDidMount() {
        const{_id}=this.props;
        if (_id) {
            this.fetchFileDetail(_id);
        }
        this.fetchAllFileSlug();
    }
    onUpdate = () => {
        const { _id, slug, content, publishDate, fileNumber, file, parentPath,options} = this.state;
        if (_id && _id != null) {
            this.props.client.mutate({
                mutation: UPDATE_FILE,
                variables: {
                    doc: {
                        _id, slug, content, publishDate, fileNumber, file, parentPath,options:JSON.stringify(options)
                    }
                }
            }).then(result => {
                authCheck(result.data);
                if (result.data.response.code === 0) {
                    this.props.history.goBack();
                }
            })
        } else {
            this.props.client.mutate({
                mutation: CREATE_FILE,
                variables: {
                    doc: {
                        slug, content, publishDate, fileNumber, file, parentPath,options:JSON.stringify(options)
                    }
                }
            }).then(result => {
                authCheck(result.data);
                if (result.data.response.code === 0) {
                    this.props.history.goBack();
                }
            })
        }
    }

    render = () => {
        const { slugs, content, publishDate, fileNumber, options } = this.state;
        return (
            <div>
                <div style={{ textAlign: "center", paddingBottom: 30 }}>
                    <h1>VĂN BẢN PHÁP QUY</h1>
                </div>
                <Row style={{ justifyContent: "center" }}>
                    <Col md={10}>

                        <Form>
                            <FormGroup row>
                                <Label md={2}>
                                    Loại văn bản
                                </Label>
                                <Col md={4}>
                                    <Input type="select" value={options&&options.type} onChange={e => { this.setState({ options: {type:e.target.value} }) }}>
                                        <option value="">
                                            Chọn loại văn bản
                                        </option>
                                        {
                                            slugs && slugs.map((item, index) => <option key={index} value={item.name}>
                                                {item.name}
                                            </option>)
                                        }
                                    </Input>
                                </Col>
                                <Col>
                                    <Button color="success" onClick={e => {
                                        showSlugModal("TẠO LOẠI VĂN BẢN PHÁP QUY MỚI", { type: "file" }).then(result => {
                                            slugs.splice(0, 0, result);
                                            this.setState({ slugs, options: {type:result.name} });
                                        })
                                    }}><MdAdd /></Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={2}>
                                    Số hiệu
                                </Label>
                                <Col md={4}>
                                    <Input value={fileNumber} placeholder="Số hiệu" onChange={e => this.setState({ fileNumber: e.target.value })} />
                                </Col>
                                <Col md={1}></Col>
                                <Label md={2}>
                                    Ngày ban hành
                                </Label>
                                <Col md={3}>
                                    <DayPickerInput
                                        style={{width:"100%"}}
                                        value={publishDate}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        format="DD/MM/YYYY"
                                        placeholder={`${formatDate(new Date())}`}
                                        // onDayChange={day => this.setState({ publishDate: day })}
                                        onDayChange={day => {
                                            if(day){
                                                this.setState({ publishDate: day })
                                            }
                                        }}
                                        component={props => <Input {...props} />}
                                    />
                                    {/* <Input type="date" value={publishDate} onChange={e => this.setState({ publishDate: e.target.value })} /> */}
                                </Col>

                            </FormGroup>
                            
                            <FormGroup row>
                                <Label md={2}>
                                    Nội dung văn bản
                                </Label>
                                <Col md={10}>
                                    <Input type="textarea" value={content} onChange={e => this.setState({ content: e.target.value })} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={2}>
                                    Upload văn bản
                                </Label>
                                <Col md={10}>
                                    <Input
                                        type="file"
                                        onChange={({
                                            target: {
                                                validity,
                                                files: [file]
                                            }
                                        }) => {
                                            // mutation({ variables: { folder: "project", file } });
                                            this.setState({ file });
                                        }}
                                        bsSize="sm"
                                    />

                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md={2}>&nbsp;</Col>
                                <Col md={10}>
                                    <ButtonGroup>
                                        <Button color="primary" onClick={() => this.onUpdate()}>
                                            Cập nhật thông tin
                                        </Button>
                                        <Button color="info" onClick={e => this.props.history.goBack()}>
                                            trở lại
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default withApollo(VBPQ);
import React, { useState, useEffect } from "react";
import { Button, FormGroup, Input, Label, Row, Col, Card, ButtonGroup } from "reactstrap";
import logo_phusan from "assets/img/logo/logo_phusan.png";
import { withApollo } from "react-apollo";
import authCheck from 'utils/authCheck';
import { setToken, setUser, setUserId, SignOut } from '../../Constants'
import { useForm } from '../../hooks';
import { LOGIN_MUTATION, MUTATION_LOGIN_WITH_TOKEN, QUERY_ME } from "./query"

const initState = {
  username: "",
  password: "",
  remember: true
}

const LoginPage = (props) => {
  const { form, handleChange } = useForm(initState);
  // componentDidMount() {
  //   const { token } = this.props.match.params;

  // }
  useEffect(() => {
    const { token } = props.match.params;
    if (token) {
      props.client.mutate({
        mutation: MUTATION_LOGIN_WITH_TOKEN,
        variables: { token }
      }).then(result => {
        if (result.data.response.code == 0) {
          //login thanh cong roi day
          var user = result.data.response.data;
          if (user.base) {
            setToken(user.base.oauth_token);
            loadUserInfo();
          }
        }
      })
    } else {
      SignOut();
    }
  }, [props.match.params]);

  function loadUserInfo() {
    props.client.query({
      query: QUERY_ME
    }).then(result => {
      if (authCheck(result)) {
        const user = result.data.response.data;
        const {roleId,roleName } = user;
        
        setUser({...user.base,roleId,roleName});
        setUserId(user._id);
        document.location.href = "/";
      }
    })
  }
  const onLogoClick = () => { };
  const onSubmit = () => {
    const { username, password } = form;
    props.client.mutate({
      mutation: LOGIN_MUTATION,
      variables: { username, password }
    }).then(result => {
      if (authCheck(result.data.response)) {
        var oauth = result.data.response.data;
        if (oauth.oauth_token) {
          setToken(oauth.oauth_token);
          loadUserInfo();

        } else {

        }
      }
    })
  }


  return (
    <Row
      style={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",

      }}
    >
      <Col md={6} lg={4}>

        <div style={{ border: "1px solid #aaaaaa", borderRadius: "3%", margin: 20, backgroundColor: "#fff" }}>
          <div className="ml-5 mr-5 mt-2 mb-5" >
            <div className="text-center">
              <img
                src={logo_phusan}
                className="rounded"
                style={{ width: "auto", height: 120, cursor: "pointer" }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
            <div className="text-center">
              <h3 className="mb-2">QUẢN LÝ VĂN BẢN NỘI BỘ</h3>
              <h6>BỆNH VIỆN PHỤ SẢN HÀ NỘI</h6>
            </div>
            <FormGroup row className="pt-5">
              {/* <Label md={1} className="text-right" style={{ fontSize: 18, marginBottom: 10 }}><FaUser /></Label> */}
              <Col md={12}>
                <Input
                  size="lg"
                  type="text"
                  placeholder="Tên đăng nhập"
                  value={form.username}
                  name="username"
                  onChange={handleChange}
                  onKeyPress={e => {
                    if (e.charCode === 13) onSubmit();
                  }}
                />
              </Col>

            </FormGroup>
            <FormGroup row>
              {/* <Label md={1} className="text-right" style={{ fontSize: 18, marginBottom: 10 }}><FaKey /></Label> */}
              <Col md={12}>
                <Input
                  size="lg"
                  type="password"
                  placeholder="Mật khẩu"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  onKeyPress={e => {
                    if (e.charCode === 13) onSubmit();
                  }}
                />
              </Col>

            </FormGroup>


            <div style={{ textAlign: "right", paddingTop: 20 }}>
              <Button
                size="lg"
                color="blue"
                onClick={onSubmit}
              >
                ĐĂNG NHẬP
                    </Button>
            </div>
          </div>
        </div>

      </Col>
    </Row>
  );

}
export default withApollo(LoginPage);

import { Content, Footer, Header, Sidebar } from './index';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import RighSidebar from './RighSidebar';
import { FuseDialog, DragableDialog } from 'components'

function MainLayout(props) {
  const quickdialog = useSelector(({ quickdialog }) => quickdialog.dialogs);

  useEffect(() => {
    openSidebar('open');
  }, []);
  useEffect(() => {
    if (props.breakpoint)
      checkBreakpoint(props.breakpoint);
  }, [props.breakpoint]);

  function isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }
  // close sidebar when
  const handleContentClick = () => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      isSidebarOpen() &&
      (props.breakpoint === 'xs' ||
        props.breakpoint === 'sm' ||
        props.breakpoint === 'md')
    ) {
      openSidebar('close');
    }
  };

  function checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
      case 'lg':
      case 'xl':
      default:
        return openSidebar('close');
    }
  }


  function openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }


  const { children } = props;
  return (
    <main className="cr-app bg-light">
      <Header {...props} />
      <Sidebar />
      <Content fluid onClick={handleContentClick}>
        <div style={{ height: 90 }}></div>
        <div className="content-body">
          {children}
          <FuseDialog />
          {
            quickdialog.map((dialog, index) => <DragableDialog key={index} {...dialog} />)
          }
        </div>
        <Footer {...props} />
      </Content>
      <RighSidebar {...props} />
      <NotificationSystem
        dismissible={false}
        ref={notificationSystem =>
          (notificationSystem = notificationSystem)
        }
      />
    </main>
  );

}


export default MainLayout;

import Page from "components/Page";
import React from "react";
import { Button, Input, Row, Col, Label, Form, FormGroup } from "reactstrap";
import { withApollo} from "react-apollo";
import gql from "graphql-tag";
import authCheck from "utils/authCheck";
import ToggleButton from 'react-toggle-button'
import AdvanceEditor from 'components/Widget/AdvanceEditor';
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import * as BaseControl from 'utils/PVT/VTBaseControl.js';

export const QUERY_DETAIL = gql`
query ArticleDetail($_id:String!){
  response:article(_id:$_id){
    data{
      _id
      content
      shortDesc
      thumb
      name
      slug
      # title
      disable
    }
    
  }
}
`;
export const QUERY_PERMISSIONS=gql`
{
  permissions{
    data{
      name
      value
    }
  }
}
`;
export const MUTATION_CREATE = gql`
mutation COMPANY($article:ArticleInput!){
  updateArticle(article:$article){
    code
    message
  }
}
`;
export const UPLOAD_QUERY= gql`
query CompanyMutation($file:FileUploadInputType){
  uploadImage(file:$file)
}
`;
export const MUTATION_EDIT = gql`
mutation COMPANY($article:ArticleInput!){
  updateArticle(article:$article){
    code
    message
  }
} 
`;

export const DELETE_QUERY = gql`
mutation COMPANY($_id:String!){
  deleteArticle(_id:$_id){
    code
    message
  }
} 
`;
class ArticleEditPage extends React.Component {
  constructor() {
    super();
    
    this.state = {
      name: "",
      thumb:"",
      shortDesc: "",
      title:"",
      content:"",
      slug:"tutorial",
      disable:true,
    };
    
  }
  componentDidMount() {
    const _id = this.props.match.params._id;
    if(_id){
      this.setState({isEdit:true,_id:_id});
      //fetch detail
      this.props.client.query({
          query:QUERY_DETAIL,
          variables:{_id:_id},
          
      }).then(result=>{
          authCheck(result);
          const article=result.data.response.data;          
          this.setState(article);          
      });
  }
  }
  
  onUpdate=()=>{
    const {
      _id,
      name,
      shortDesc,
      content,
      disable,
      slug,
      file,
    } = this.state;
    var mArtitle = {}
    var thumb = ""
    if(file === undefined || file === null){
      thumb = ""
    }else{
      thumb = file
    }
    if(_id === undefined || _id.length === 0){
      mArtitle = {
        name,
        disable,
        shortDesc,
        slug,
        content,                  
        thumb:thumb,
      }
    }else{
      mArtitle = {
        _id,
        name,
        disable,
        shortDesc,
        slug,
        content,                  
        thumb:thumb,
      }
    }
    
    console.log("article input: ", mArtitle)
    this.props.client.mutate({
      mutation : (_id === undefined || _id.length === 0)
      ? MUTATION_CREATE
      : MUTATION_EDIT,
      variables: {article: mArtitle}//truong hợp này chỉ dùng 1 dấu nháy vì variables chỉ là 1 object ko phải thuộc tính
    }).then(result=>{
      // this.onCancel()
            if(authCheck(result.data)){
              console.log("add article success: ", result.data)
              window.history.back()
            }
    });
  }
  onCancel = () => {
    this.props.history.push("/articlesPage");
  };
  uploadCallback = (file) => {
    console.log("upload callback "+file.name);
    // var client = this.props.client;
    return new Promise(
      (resolve, reject) => {
          this.props.client.query({
            query : UPLOAD_QUERY,
            variables: {file}//truong hợp này chỉ dùng 1 dấu nháy vì variables chỉ là 1 object ko phải thuộc tính
          }).then(result=>{
            console.log("upload result:"+JSON.stringify(result));
            resolve({data:{link:result.data.uploadNewImage}})
          });
          
      }
    );
  }
  onDelete = () => {
    const {_id} = this.state
    if(_id && _id !== undefined && _id !== null)
    {
      BaseControl.showConfirmAlert(
        "Thông báo",
        "Bạn có chắc muốn xóa văn bản đính kèm này?",
        "Đồng ý",
        "Hủy",
        ()=>{//success
            try{
                this.props.client.query({
                  query: DELETE_QUERY,
                  variables: {  _id:_id}
                }).then(result =>{
                    if(authCheck(result.data)){
                        window.history.back()
                    }
                })
            }catch(error){
                console.log("===> delete appendix error: ", error)
            }
        },
        ()=>{//cancel

        }
    )
    }
  }
  render = () => {
    const {
      _id,
      name,
      title,
      shortDesc,
      content,
      disable,
      thumb,
      file,
      slug,
      editorState
    } = this.state;
    
    return (
      <Page
        // title={(this.state._id!=null)?"Sửa bài viết":"Tạo bài viết"}
        // breadcrumbs={[{ name: (this.state._id!=null)?"Sửa bài viết":"Tạo bài viết", active: true }]}
        // className="Article"
        style={{width:"100%", }}
      >
        <PageTitle onClick={e => this.handleBackButton} title={(this.state._id!=null)?"SỬA BÀI VIẾT":"THÊM BÀI VIẾT"}></PageTitle>
        <Row style={{marginTop:"30px"}}>
          <Col md={12}>
          {
            (_id === undefined || _id.length === 0)?
              <Button color="success" onClick={()=>this.onUpdate()} style={{float:"right",height:"45px", marginRight:"25px", fontSize:"15px"}}>
                      Thêm mới
              </Button>
              :
              <div>
                <Button color="danger" onClick={()=>this.onDelete()} style={{float:"right",height:"45px", marginRight:"25px", fontSize:"15px"}}>
                      Xóa bài viết
                  </Button>
                <Button color="success" onClick={()=>this.onUpdate()} style={{float:"right",height:"45px", marginRight:"25px", fontSize:"15px"}}>
                      Cập nhật
                  </Button>
              </div>
              }
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form className="m-3" >
            <FormGroup row  style={{fontSize:"16px"}}>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tạm ẩn:
                </Label>
                <Col md={10} sm={8} xs={6}>
                <ToggleButton
                  disabled
                  value={ disable }
                  onToggle={(value) => {
                      this.setState({disable:!value});
                  }} />
                </Col>
              </FormGroup>
              <FormGroup row  style={{fontSize:"16px"}}>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tên bài viết:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={name}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    style={{fontSize:"16px"}}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup row  style={{fontSize:"16px"}}>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tiêu đề bài viết:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={title}
                    onChange={e => {
                      this.setState({ title: e.target.value });
                    }}
                    bsSize="sm"
                    style={{fontSize:"16px"}}
                  />
                </Col>
              </FormGroup> */}
              <FormGroup row  style={{fontSize:"16px"}}>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Loại:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="select"
                    value={slug}
                    onChange={e => {
                      this.setState({ slug: e.target.value });
                    }}
                    bsSize="sm"
                    style={{fontSize:"16px"}}
                  >
                  <option value="rules">Nội quy</option>
                  <option value="tutorial">Hướng dẫn sử dụng</option>
                  </Input>
                </Col>
              </FormGroup>
              {/* <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Ảnh Thumb:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <img width="100" height="100"  src={thumb} alt="" style={{maxHeight:100,maxWidth:"100%"}}/>
                  <Input
                        type="file"
                        onChange={({
                        target: {
                            validity,
                            files: [file]
                        }
                        }) => {
                        this.setState({ file });
                        }}
                        bsSize="sm"
                    />
                </Col>
              </FormGroup> */}
              <FormGroup row  style={{fontSize:"16px"}}>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Mô tả ngắn
                </Label>
                <Col md={10} sm={8} xs={6}>
                
                  <Input
                    type="textarea"
                    value={shortDesc}
                    onChange={e => {
                      this.setState({ shortDesc: e.target.value });
                    }}
                    bsSize="sm"
                    style={{fontSize:"16px"}}
                  />
                </Col>
              </FormGroup>
              <FormGroup row  style={{fontSize:"16px"}}>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Nội dung
                </Label>
                <Col md={10} sm={8} xs={6}>
                <div >
                    <AdvanceEditor content={content} onChange={content => this.setState({ content })}/>
                   </div>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Page>
    );
  };
}
export default withApollo(ArticleEditPage);

import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import UploadFileForm from 'components/Widget/PVT/UploadFileForm'
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { useForm } from 'hooks';
import _ from 'lodash';

const initialForm = {
  fileId:null,
  code: "",
  name: "",
}
function UpdateBieuMauForm({ data, onCancel, onSuccess, ...props }) {
  const { form, setForm, handleChange, setInForm } = useForm(initialForm);

  useEffect(() => {
    if(data)
      setForm(data);
  }, [data]);

  function handleSubmit() {
    if (form.code.length === 0) {
      BaseControl.showErrorToast("Vui lòng nhập mã số tài liệu")
      return
    }
    if (form.name.length === 0) {
      BaseControl.showErrorToast("Vui lòng nhập tên văn bản")
      return
    }
    if (!form.fileId) {
      BaseControl.showErrorToast("Vui lòng tải lên file tài liệu")
      return
    }
    // fields 'fileUrl', 'pdfUrl', 'fileInfo' for type 'AppendixInput'.
    const submitdata = _.omit(form, ['fileUrl','pdfUrl','fileInfo']);
    onSuccess(submitdata, props.isUpdate, props.index);
  }

  return (
    <Modal isOpen={props.isOpen} className={props.className} size="l" style={{ maxWidth: "640px", backgroundColor: "white" }}>
      <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center' }} ><span style={{ color: "#22BB03", fontWeight: "bold" }}>CẬP NHẬT BIỂU MẪU - PHỤ LỤC - ĐÍNH KÈM</span></ModalHeader>
      <ModalBody>
      <FormGroup>
          <Label style={{ fontSize: "16px", fontWeight: "bold", color: "#4E5357", paddingBottom: "5px" }}>Mã số tài liệu</Label>
          <Input type="text" name="code" id="txtVersion"
            placeholder="Mã số tài liệu" value={form.code} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label style={{ fontSize: "16px", fontWeight: "bold", color: "#4E5357", paddingBottom: "5px" }}>Tên văn bản</Label>
          <Input type="text" name="name" id="txtContent" placeholder="Tên văn bản" value={form.name} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <UploadFileForm
            server={props.server}
            onUpdateFiles={fileId => setInForm('fileId', fileId)}
            allowMultiple={false} maxFiles={1}
            style={{ width: "100%", marginBottom: "20px", }} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSubmit} color="success" size="lg" style={{ fontSize: "16px" }}>XÁC NHẬN</Button>{' '}
        <Button color="danger" size="lg" onClick={onCancel} style={{ fontSize: "16px" }}>HỦY</Button>
      </ModalFooter>
    </Modal>
  );

}

export default UpdateBieuMauForm;
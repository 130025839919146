import React from "react";

import logo_phusan from "assets/img/icon/Group 5.png";
import group4 from 'assets/img/icon/Group 4.png';
import { AUTH_TOKEN } from 'Constants';

import { Mutation, withApollo } from "react-apollo";
import gql from "graphql-tag";
import 'styles/loginstyle.css'

import { LOGIN_MUTATION } from "GraphQL/Login"


class LoginPageTest extends React.Component {
  state = {
    username: "",
    password: "",
    remember: true
  };

  doSubmit=()=>{
    const{username,password}=this.state;
    this.props.client.mutate({
      mutation:LOGIN_MUTATION,
      variables:{username,password}
    }).then(result=>{
      this.handleSubmitResult(result.data);
    })
  }
  handleSubmitResult = (data) => {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(data.login.data));
    this.props.history.push("/");
  }
  onLogoClick = () => { };

  render() {
    const { username, password, remember } = this.state;
    return (
      <div class="main-login">
        <div class="line-top"></div>
        <main>
          <div class="logo">
            <div class="container"> <img src={logo_phusan} alt="BỆNH VIỆN PHỤ SẢN HÀ NỘI ADMIN PORTAL" class="img-reponsive logo-img" />
            </div>
          </div>
          <div class="login-box d-flex">
            <div class="flex-item">
              <div class="content-box"><img src={group4} alt="ADMIN PORTAL" class="img-reponsive center-item" />
                <div class="text-box">
                  <h2>AN TOÀN - HIỆU QUẢ -
              DỄ SỬ DỤNG</h2>
                  <ul>
                    <li>Quản trị điều phối các hoạt động</li>
                    <li>Đa nền tảng trình duyệt, mobile (iOS, Android)</li>
                    <li>Hợp nhất dữ liệu, quản trị tập trung</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="flex-item">
              <div class="content-box">
                <div class="login-form">
                  <div class="login-header">
                    <p>ĐĂNG NHẬP</p>
                  </div>
                  <div class="login-body">
                    <input value={username} onKeyPress={e => {
                          if (e.charCode === 13) this.doSubmit();
                        }} onChange={e=>this.setState({username:e.target.value})} type="text" class="text-input" placeholder="Tên đăng nhập" />
                    <input onKeyPress={e => {
                          if (e.charCode === 13) this.doSubmit();
                        }} value={password} onChange={e=>this.setState({password:e.target.value})} type="password" class="text-input" placeholder="Mật khẩu" />
                    <input onClick={this.doSubmit}  type="button" class="btn-login" value="ĐĂNG NHẬP" />
                  </div>
                  <div class="login-footer"> <a href="#" class="link">Quên mật khẩu?</a> </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div class="footer-login">
            <div class="container  d-flex">
              <div class="flex-item">
                <div class="col-12">
                  <h3><b>BỆNH VIỆN PHỤ SẢN HÀ NỘI</b></h3>
                  <h4>Số 929 - Đường La Thành - Ngọc Khánh - Ba Đình - Hà Nội</h4>
                  <h5>Bệnh viện Phụ Sản Hà Nội là Bệnh viện chuyên khoa hàng đầucủa thành phố trong lĩnh vực Sản Phụ Khoa và Kế hoạch hóa gia đình</h5>
                  <p class="phone-number"><span>Cấp cứu:</span><span class="sp-white">0243 8343181</span></p>
                  <p class="phone-number"><span>Đặt khám:</span><span class="sp-white">19006922</span></p>
                </div>
              </div>
              <div class="flex-item">
                <div class="col-12">
                  <h3><b>CƠ SỞ 2</b></h3>
                  <h4>Số 38, Cảm Hội, Hai Bà Trưng, Hà Nội</h4>
                  <p class="phone-number"><span>Liên hệ:</span><span class="sp-white">0246 2785 746</span></p>
                </div>
              </div>
              <div class="flex-item">
                <div class="col-12">
                  <h3><b>CƠ SỞ 3</b></h3>
                  <h4>Số 10 - Đường Quang Trung - Hà Đông -
              Hà Nội</h4>
                  <p class="phone-number"><span>Liên hệ:</span><span class="sp-white">0243 3512 424</span></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 copy-right">
            <p class="text-center pading-24 text-white">© 2019 Bệnh Viện Phụ Sản Hà Nội</p>
          </div>
        </footer>
      </div>

    );
  }
}
export default withApollo(LoginPageTest);
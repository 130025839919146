import gql from 'graphql-tag';

export const ALL_SLUG_BY_TYPE=gql`
query($type:String!){
    response:slugs(pageSize:0, filtered:[{id:"type",value:$type}]){
        code
        message
        data{
            _id
            name
        }
    }
}
`
export const CREATE_SLUG=gql`
    mutation createSlug($slug:SlugInput!)
    {
        response:createSlug(slug:$slug)
        {
            code
            message
            data{
                _id
                name
                type
            }
        }
    }
`
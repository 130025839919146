import React from 'react';
import Page from 'components/Page';
import { Button, ButtonGroup,Col,Row,FormGroup, Input} from 'reactstrap';
import { GET_LIST_SLUG, DELETE_SLUG } from 'GraphQL/slug'
import authCheck from '../../../utils/authCheck';
import { withApollo, Mutation } from 'react-apollo'
import editIcon from 'assets/icons/edit_icon.png'
import deleteIcon from 'assets/icons/delete_icon.png'
import ReactTable from "react-table-custom";
import Moment from 'moment'
import confirm from 'components/Confirmation';

class SlugManager extends React.Component{
    state = {
        key: "",
        data:[],
        pages: 1,
        records:0
    }

    constructor(props){
        super(props);
        this.fetchData = this.fetchData.bind(this);
    }
    componentDidMount() {
        
    }
    fetchData = (state) =>{
        const { page, pageSize,sorted, filtered } = state;
        const { key } = this.state;
        this.props.client.query({
            query: GET_LIST_SLUG,
            variables: { filtered, sorted ,page,pageSize}
        }).then (result =>{
            authCheck(result);
            this.setState({ data: result.data.response.data, pages: result.data.response.pages})
        })
    }
    onEdit = (_id) =>{
        this.props.history.push("/SlugDetail/"+_id)
    }
    onDelete = (_id, name) =>{
        confirm(`Bạn có chắc chắn muốn xóa slug ${name}?`, { showCancel: false }).then(kq=>{
            this.props.client.mutate({
                mutation: DELETE_SLUG,
                variables: { _id }
            }).then(result =>{
                if(result.data.response.code == 0){
                    confirm(`Đã xóa slug ${name} thành công`, { hideCancel: true }).then(_=>{
                        this.fetchData({ page: 0, pageSize: 20, filtered: [], sorted: [{ id: "type", desc: false }] });
                    })
                }
            })
        })
    }
    render = () =>{
        return(
            <Page 
                className="CustomerPage">
                <div style = {{textAlign:"center"}}>
                    <h2 style = {{marginTop:"15px",fontWeight:"bold", color:"#08AC50"}}>QUẢN LÝ SLUG</h2>
                    <div style = {{textAlign:"left"}}>
                        <Button color = "success" style = {{fontSize:"16px", marginTop:"10px", marginLeft:"10px"}} onClick = {e => this.props.history.push("/SlugDetail")}>Thêm mới</Button>
                    </div>
                </div>
                <div style = {{marginTop:"20px"}}></div>
                <ReactTable
                    manual
                    noDataText="Không có dữ liệu"
                    style= {{color:"#4E5357", fontSize:"14px", minHeight:"80px"}}
                    sortable={false}
                    defaultSorted={[{ id: "type", desc: false }]}                  
                    data={this.state.data}
                    defaultPageSize={20}
                    filterable
                    pages={this.state.pages}
                    onFetchData={this.fetchData} 
                    className="-striped -highlight"
                    columns={[
                        {
                            Header: "STT",
                            width: 50,
                            className: "text-center",
                            filterable: false,
                            Cell: row => <span>
                                {row.index + 1}
                            </span>
                        },
                        {
                            Header: "Slug",
                            accessor: "name",
                        },
                        {
                            Header: "Loại",
                            accessor: "type",
                            Filter: ({ filter, onChange }) =>
                                <Input bsSize="sm" type="select" placeholder="Chọn loại slug" onChange={e => onChange(e.target.value)}>
                                    <option value="">Chọn loại slug</option>
                                    <option value ="loai-van-ban">Thư mục</option>                                    
                                    <option value="slug_document_type">Các loại văn bản</option>
                                    <option value="slug_dvbh">Đơn vị ban hành</option>
                                </Input>
                        },
                        {
                            Header: "Tác vụ",
                            filterable: false,
                            Cell: row => <div style ={{ textAlign: "center" }}>
                                <ButtonGroup>
                                    <Button onClick={() => this.onEdit(row.original._id)} color="" ><img src = {editIcon} alt = "edit" style = {{objectFit:"cover", width: "20px"}}></img></Button>
                                    <Button onClick={() => this.onDelete(row.original._id, row.original.name)} color="" ><img src = {deleteIcon} alt = "delete" style = {{objectFit:"cover", width: "20px"}}></img></Button>
                                </ButtonGroup>
                            </div>
                        }
                    ]}
                />
            </Page>
        );
    }
}
export default withApollo(SlugManager);

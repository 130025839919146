import React from 'react'
import { Button } from 'reactstrap'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import QTQDable from 'pages/Documents/FileTables/PVT/QTQDTable'
import { QUERY_DIRECTORY } from 'GraphQL/directory'
import {  GET_ALL_FILES} from 'GraphQL/PVT/FileManager';
import authCheck from 'utils/authCheck';
import { withApollo } from 'react-apollo'


class DepartmentFilesPage extends React.Component{
    state = {
        parentId    : "", 
        name        : "",
        parentPath  : "",
        parentName  : "",
        id          : "",
        pageSize    : 20,

        pages       : 0,
        filtered    : [],
        loading     : false,
        permission  : null,
        records     : 0,

    }
    constructor(props){
        super(props)
        this.setState({

        })
        this.onShowDetail   = this.onShowDetail.bind(this)
        this.fetchData      = this.fetchData.bind(this)
        this.handleFilterdChange   = this.handleFilterdChange.bind(this)
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        var name = this.props.match.params.departmentName;
       this.setState({departmentName:name})
       this.fetchData(name)
    }

    componentWillReceiveProps(willProps) {
        var name = willProps.match.params.departmentName;
       this.setState({departmentName:name})
       this.fetchData(name)
    }

    fetchData(departmentName){
        if(departmentName && departmentName!== undefined && departmentName!== null){
            // var copy = [...filtered, {id:"options",value:`department:${departmentName}$`}]
            var copy = [ {id:"options",value:`{\"department\":\"${departmentName}\"}`}]
           //\"department\":\"P.Kế hoạch tổng hợp\"
            this.props.client.query({
                query       : GET_ALL_FILES,
                variables   : {  filtered:  copy}
            }).then(result =>{
                authCheck(result.data)
                this.setState({loading:false})
                if(result.data&&result.data.response&&result.data.response.data){
                    this.setState({
                        data    :result.data.response.data,
                        pages   :result.data.response.pages,
                        records : result.data.response.records,
                    })
                }
            })
        }
    }
    handleFilterdChange(filter){
        console.log("filter change: ", filter)
        this.setState({filtered:filter})
    }
    onShowDetail(docId){
        this.props.history.push(`/qtqd-detail/${docId}`)
    }
    render(){
        const { id, departmentName,filtered, name, pageSize, permission, records } = this.state
        return(
            <div style = {{textAlign:"center"}}>
                <PageTitle onClick={e => {window.history.back()}} title={departmentName}></PageTitle>
                <div style={{maxWidth:"1280px", margin:"auto"}}>
                    {(permission&&permission.write===true)&&
                        <div style = {{textAlign:"left"}}>
                            <Button color = "success" style = {{fontSize:"16px", marginTop:"10px", marginLeft:"00px"}} onClick = {e => this.props.history.push(`/qtqd-add/${id}`)}>Thêm mới</Button>
                        </div>
                    }

                    <div style = {{textAlign:"left", marginTop:"20px", marginBottom:"-10px"}}>
                        <span style={{fontSize:"16px"}}>Tổng số: {records} tài liệu</span>
                    </div>
                    <div style = {{marginTop:"20px"}}>
                        <QTQDable parentId = {""} 
                        data = {this.state.data} 
                        pages = {this.state.pages} 
                        pageSize={pageSize} 
                        loading={this.state.loading} 
                        fetchData={this.fetchData} 
                        filtered={filtered}
                        onFilteredChange={this.handleFilterdChange}
                        onDetail = {this.onShowDetail}></QTQDable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withApollo(DepartmentFilesPage);
//parentId,data,pages,onDetail
import React, { Fragment } from 'react'
import Avatar from 'react-avatar'
import { Button } from 'reactstrap'
import { Typography } from '@material-ui/core';
import closeIcon from 'assets/icons/close_icon.png'
import feedbackIcon from 'assets/icons/feedback_icon.png'
import Moment from 'moment'


const getBackgroundColor = (_id, targetId, read) => {
    if (_id === targetId) {
        return "#FBF2F2"
    } else {
        if (read === true) {
            return "white"
        } else {
            // return "#FBF2F2"
            return "#E2FCF6"
        }
    }
}
const FeedbackWidget = ({ _id, sender, section,childcount, subSection, comment, createdTime, read, targetId, onDelete, canDelete = false, onClick, style }) => {
    return (
        <div style={{ border: "1px solid #E8E9EA", borderRadius: "4px", paddingBottom: "15px", backgroundColor: `${getBackgroundColor(_id, targetId, read)}`, ...style }} onClick={onClick}>
            <div style={{ width: "100%", backgroundColor: "transparent", display: "inline-block" }}>
                <div style={{ marginLeft: "25px", marginBottom: "15px" }}>
                    {canDelete && <Button onClick={e => onDelete(_id)} color="" style={{ width: "50px", height: "50px", textAlign: "center", float: "right", marginTop: "0px" }}><img src={closeIcon} alt="close" style={{ widht: "14px", height: "14px", objectFit: "contain", }}></img></Button>}
                </div>
                <div className="flex" style={{ float: "left", marginLeft: "25px", marginBottom: "0px"}}>
                    <Avatar size="48px" round={true} name={sender.base.fullName} src={sender.base.picture} style={{ marginTop: "5px" }}></Avatar>
                    <div style={{ marginLeft: 15 }}>
                        <div style={{ textAlign: "left" }}>
                            <div className="flex">
                                <Typography style={{ color: "#7A7E81", fontSize: "13px" }}>
                                    {Moment(`${createdTime}`).format("HH:mm DD/MM/YYYY")}
                                </Typography>
                                <Typography className="text-description pl-2">
                                    ({childcount} phản hồi)
                                </Typography>
                            </div>
                            <div className="flex">
                                <Typography style={{ color: "#212121" }}>{sender.title && `${sender.title}.`}{sender.base.fullName}</Typography>
                                <Typography style={{ color: "#7A7E81", fontSize: "13px", paddingLeft: 8 }}>{sender.departmentName}</Typography>
                            </div>
                        </div>
                        <div className="flex">
                            <img src={feedbackIcon} alt="icon" style={{ width: 16, height: 16, objectFit: "scale-down", paddingRight: 4 }} />
                            <div className="text-left">
                                <Typography className="text-description" variant="subtitle2">MỤC {section} - TIỂU MỤC: {subSection}</Typography>
                                <Typography className="text-comment">
                                    {comment}
                                </Typography>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}
export default FeedbackWidget;

import React from 'react'

import PDFViewer from 'components/Widget/NTD/PDFViewer'

class PvtTestPdfPage extends React.Component{

    state = {
        numPages: null,
        pageNumber: 1,
    }

    constructor(props)
    {
        super(props)
        this.setState({

        })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render(){
        // const fileUrl = "http://data.host95.elsaga.net/file/06ba9e6defa648ad83721eb408be1c70.pdf"
        const fileUrl = "http://api.host95.elsaga.net/stream/session/5d9397af8150a31a0c141a6e"
        
        return (
        <div style = {{width:"100%", minHeight:"800px", margin:"auto", marginTop:"30px"}}>
            <PDFViewer
                fileUrl={`${fileUrl}`}
                downloadEnable={true}
                printEnable={true}
                page={1}
                pages={1}
                zoom={1.0}
            >
            </PDFViewer>
        </div>
        );
    }
}
export default PvtTestPdfPage;
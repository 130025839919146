import React from 'react'
import { CardBody, CardHeader, Row, Col, Input } from 'reactstrap'
import moment from 'moment';

export function View({ data, ...props }) {
    return (
        <CardBody >
            <div style={{ padding: "20px" }}>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Tên văn bản</span>
                            </Col>
                            <Col sm={10}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="name" id="txtName" placeholder="Tên QTQĐ" value={data.name} disabled />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Mã tài liệu</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="maTaiLieu" id="txtMaTaiLieu" placeholder="Mã tài liệu" value={data.fileNumber} disabled></Input>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Phiên bản</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="phienban" id="txtPhienBan" placeholder="Phiên bản" value={data.version} disabled></Input>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row >
                    <Col >
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Đơn vị ban hành</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="phienban" placeholder="Khoa phòng" value={data.department} disabled></Input>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Ngày ban hành</span>
                            </Col>
                            <Col sm={8}>
                                <Input type="text" value={moment(data.issuedDate).format("DD/MM/YYYY")} disabled></Input>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={2} style={{ marginTop: "10px" }}>
                                <span>Khoa phòng liên quan</span>
                            </Col>
                            <Col sm={10}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="quyetDinh" id="txtQuyetDinh" placeholder="" value={data.relateDepartments.map(d=>d.name).join()} disabled></Input>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row >                    
                    <Col sm={6}>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={4} style={{ marginTop: "10px" }}>
                                <span>Quyết định</span>
                            </Col>
                            <Col sm={8}>
                                <div style={{ display: "flex" }}>
                                    <Input type="text" name="quyetDinh" id="txtQuyetDinh" placeholder="" value={`Số ${data.decisionNumber} ngày ${moment(data.decisionDate).format("DD/MM/YYYY")}`} disabled></Input>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </div>
        </CardBody>

    )
}
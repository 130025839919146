import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';


const useStyles = makeStyles({
    root: {
        height: 216,
        flexGrow: 1,
        maxWidth: 400,
    },
});



export default function CheckBoxTree({ nodes, checked, onChecked }) {
    const [selected, setSelected] = useState([]);
    const classes = useStyles();
    const [expanded, setExpanded] = useState([]);
    const handleToggle = (event, nodeIds) => {
        //neu click vao check box thi ko toggle 
        
        if(event.target.type!=="checkbox")
            setExpanded(nodeIds);
    };
    function handleCheck(node,checked) {
        toggleCheckChild(node,checked);
        if (onChecked)
            onChecked(selected);
    }
    function toggleCheckChild(node, checked) {
        const index = selected.indexOf(node.value);   
        if (checked) {
            if(index<0)
                selected.push(node.value);            
        } else {
             
            if (index >= 0)
                selected.splice(index, 1)
        }
        if (node.children && node.children.length > 0) {
            node.children.forEach(function (item) {
                toggleCheckChild(item,checked)
            })
        }
    }

    useEffect(() => {
        if (checked)
            setSelected(checked);
    }, [checked])
    const CheckedTreeItem = ({ node }) => {
        if (!node)
            return "node empty"
        const label =
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox                    
                    checked={selected.includes(node.value)}
                    onChange={(event, checked) => handleCheck(node,checked)}
                    color="primary"
                />
                <Typography variant="caption">{node.label}</Typography>
            </div>
        return <TreeItem
            nodeId={node.value}
            label={label}
        >
            <div>{node.children && node.children.length > 0 && node.children.map((child, index) =>
                <CheckedTreeItem node={child} key={index} />
            )}</div>
        </TreeItem>
    }

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
        // onNodeSelect={handleSelect}
        >
            {
                nodes && nodes.map((item, index) =>
                    <CheckedTreeItem
                        node={item}
                        key={index}
                    />)
            }

        </TreeView>


    )

}


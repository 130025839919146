import React, { useState, useEffect } from 'react'
import { withApollo } from 'react-apollo'
import PageTitle from 'components/Widget/PVT/PageTitleWidget'
import { Card, CardHeader, Button, CardTitle } from 'reactstrap'
import _ from 'lodash';
import 'moment/locale/vi';
import 'react-web-tabs/dist/react-web-tabs.css';

import editIcon from 'assets/icons/edit_icon.png'
import deleteIcon from 'assets/icons/delete_icon.png'
import printIcon from 'assets/icons/print_icon.png'
import downloadIcon from 'assets/icons/download_icon.png'

import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { GET_FILE_DETAIL, UPDATE_DOC_FILE, DELETE_DOC_FILE } from '../query';
import authCheck from 'utils/authCheck';

import { getUser,getToken } from "Constants";
import { View } from './View';
import { EditForm } from './EditForm';
import { TabInfores } from './TabInfoes';

import FileSaver, { saveAs } from 'file-saver';
import axios from 'axios';
import printJS from 'print-js'

function QTQDDetailPage(props) {
    const [user] = useState(getUser());
    const [data, setData] = useState(null);
    const [commentId, setCommentId] = useState(null);
    const [editMode, setEditMode] = useState(false);
    useEffect(() => {
        const { docId, commentId } = props.match.params;
        if (docId) {
            refetchDoc(docId);
        }
        if (commentId)
            setCommentId(commentId);
    }, [props]);
    function refetchDoc(_id) {
        props.client.query({
            query: GET_FILE_DETAIL,
            variables: { _id }
        }).then(result => {
            if (authCheck(result.data)) {
                setData(result.data.response.data);
            }
        });
    }
    function handleDeleteDoc() {

        BaseControl.showConfirmAlert(
            "Thông báo",
            `Bạn có chắc muốn xóa văn bản "${data.name}"?`,
            "Đồng ý",
            "Hủy",
            () => {//success
                try {
                    if (data) {
                        props.client.query({
                            query: DELETE_DOC_FILE,
                            variables: { _id: data._id }
                        }).then(result => {
                            authCheck(result.data)
                            // this.fetchAppendixes()
                            window.history.back()
                        })
                    }
                } catch (error) {
                    console.log("===> delete doc files error: ", error)
                }
            },
            () => {//cancel

            }
        )
    }
    function handleSave(form) {
        setEditMode(false);
        var fields = ["fileNumber", "department","departmentId","relateDepartmentIds", "issuedBy", "issuedDate", "name", "decisionNumber", "decisionDate"];
        props.client.query({
            query: UPDATE_DOC_FILE,
            variables: { doc: _.omit(form, ["createdTime", "extension", "fileUrl", "pdfUrl", "path", "updatedTime", "account", "permissions", "parent",'type','versions','relateDepartments']), fields: fields }
        }).then(result => {
            if (authCheck(result.data)) {
                BaseControl.showSuccessToast("Cập nhật thành công")
                setData(result.data.response.data);
            }

            // this.fetchDocumentDetail()
        })
    }
    function handleDownloadFile(pdfUrl, fileName) {

        var token = getToken();
        var accessToken = "";
        try {
            accessToken = token.accessToken;
            axios({
                url: pdfUrl,
                method: 'GET',
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                json: true,
                responseType: 'blob', // important
            }).then((response) => {
                FileSaver.saveAs(new Blob([response.data]), `${fileName}`);
            });
        } catch (err) { }
    }
    //print document
    function handlePrintFile(pdfUrl) {
        var token = getToken();
        var accessToken = "";
        try {
            accessToken = token.accessToken;
            printJS({ printable: `${pdfUrl}?access_token=${accessToken}`, type: 'pdf', showModal: true })
        } catch (err) { }
    }

    return (
        <div>
            <PageTitle
                onClick={() => this.handleBackButton}
                title={(data && data.parent && data.parent.name)}
                subTitle={data && data.name}
            />
            {
                data && <div>
                    <div id="info" style={{ maxWidth: "1280px", backgroundColor: "white", margin: "auto", marginTop: "35px", marginBottom: "40px", color: "#4E5357", fontSize: "14px", borderRadius: "4px" }}>
                        <Card style={{ maxWidth: "1280px", backgroundColor: "white", margin: "auto", marginTop: "35px", color: "#4E5357", fontSize: "14px", borderRadius: "4px" }}>
                            <CardHeader>
                                {
                                    editMode ?
                                        <CardTitle style={{ marginTop: "15px" }}>Chỉnh sửa chi tiết</CardTitle>
                                        : <div style={{ textAlign: "left" }}>
                                            {
                                                (user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.print))&&<Button onClick={() => handlePrintFile(data.pdfUrl)} outline color="" ><img src={printIcon} alt="print" style={{ objectFit: "cover" }}></img></Button>
                                            }
                                            {
                                                (user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.download))&&<Button outline color="" ><img src={downloadIcon} alt="download" style={{ objectFit: "cover" }} onClick={() => {
                                                    handleDownloadFile(data.pdfUrl, data.fileNumber)
                                                }}></img></Button>
                                            }
                                            {(user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.edit === true)) && <Button onClick={() => setEditMode(true)} outline color="" ><img src={editIcon} alt="edit" style={{ objectFit: "cover" }}></img></Button>}
                                            {(user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.delete === true)) && <Button onClick={handleDeleteDoc} outline color="" ><img src={deleteIcon} alt="delete" style={{ objectFit: "cover" }}></img></Button>}
                                        </div>
                                }
                            </CardHeader>
                            {
                                editMode ? <EditForm data={data} onCancel={() => setEditMode(false)} onSave={handleSave} /> :
                                    <View data={data} />
                            }

                        </Card>
                    </div>
                    <TabInfores data={data} commentId={commentId} refetchDoc={refetchDoc}/>
                </div>
            }
        </div>
    )
}
export default withApollo(QTQDDetailPage);
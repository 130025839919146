import * as Actions from 'store/actions';
import { combineReducers } from 'redux';
const initData = {
    data: [],
    records: 0,
    unread:0
}
const notify = (state = initData, action) => {
    switch (action.type) {
        case Actions.LOAD_NOTIFY: {            
            return {
                ...state,
                ...action.payload
            }
        }
        case Actions.SET_UNREAD: {
            return {
                ...state,
                unread: action.payload
            };
        }
        
        default: {
            return state;
        }
    }
}
export default combineReducers({ notify });
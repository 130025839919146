import React, { useMemo, useState, useEffect } from 'react'
import { Button, Input, Label } from 'reactstrap'
import PropTypes from 'utils/propTypes';
import { getToken } from "Constants";

import iconPreTop from 'assets/icons/PDF/preTop_icon.png'
import iconPre from 'assets/icons/PDF/pre_icon.png'
import iconNextEnd from 'assets/icons/PDF/nextEnd_icon.png'
import iconNext from 'assets/icons/PDF/next_icon.png'
import iconZoomIn from 'assets/icons/PDF/zoomIn_icon.png'
import iconZoomOut from 'assets/icons/PDF/zoomOut_icon.png'
import iconDownload from 'assets/icons/PDF/pdfDownload_icon.png'
import iconPrint from 'assets/icons/PDF/pdfPrint_icon.png'
import iconRotateLeft from 'assets/icons/PDF/rotate_left_icon.png'
import iconRotateRight from 'assets/icons/PDF/rotate_right_icon.png'

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const scaleValue = 0.2


const PDFViewer = ({ fileUrl, printEnable, downloadEnable, onDownload, onPrint, ...props }) => {
    const [pages, setPages] = useState(null);
    const [page, setPage] = useState(props.page ? props.page : 1);
    const [pageText, setPageText] = useState(props.page ? props.page : 1);
    const [loaded, setLoaded] = useState(false);
    const [zoom, setZoom] = useState(props.zoom ? props.zoom : 1.0);
    const [rotate, setRotate] = useState(0);
    const [scaleText, setScaleText] = useState(Math.trunc(zoom * 100))

    const [file, setFile] = useState(fileUrl);

    useEffect(() => {
        var token = getToken();
        try {
            setFile({
                url: fileUrl,
                httpHeaders: { 'Authorization': `Bearer ${token}` },
                withCredentials: true
            })
        } catch (err) { }
    }, [fileUrl]);

    return (
        <div>
            <Header handlePreToTop={() => { setPage(1); setPageText(1) }}
                handlePre={() => {
                    if (page > 1) {
                        setPage(page - 1); setPageText(page - 1)
                    }
                }}
                handleNext={e => {
                    if (page < pages) {
                        setPage(page + 1); setPageText(page + 1);
                    }
                }}
                handleNextToEnd={() => {
                    setPageText(pages); setPage(pages);
                }}
                handleChangePage={e => {
                    var value = parseInt(e.target.value)
                    if (isNaN(value)) {
                        setPageText("");
                    } else if (value) {
                        if (value >= 0 && value <= pages) {
                            setPage(value); setPageText(value);
                        }
                    }
                }}
                handleZoomIn={() => {
                    setZoom(zoom + scaleValue);
                    setScaleText(Math.trunc((zoom + scaleValue) * 100))
                }}
                handleZoomOut={() => {
                    if (zoom < 0.1) {
                        setZoom(0.1);
                        setScaleText(Math.trunc(0.1 * 100))
                    }
                    else {
                        setZoom(zoom - scaleValue);
                        setScaleText(Math.trunc((zoom - scaleValue) * 100))
                    }
                }}
                handleChangeScaleValue={e => {
                    var value = parseInt(e.target.value)
                    if (value) {
                        var scale = value / 100
                        setZoom(scale);
                        setScaleText(Math.trunc(scale * 100))
                    } else {
                        setZoom(0.1);
                        setScaleText(Math.trunc(0.1 * 100))
                    }
                }}
                handleChangeLeftRotate={() => {
                    setRotate(rotate - 90);
                }}
                handleChangeRightRotate={
                    () => { setRotate(rotate + 90) }
                }
                downloadEnable={downloadEnable} printEnable={printEnable} page={page} pages={pages} zoom={zoom} pageText={pageText} scaleText={scaleText}
                onDownload={onDownload}
                onPrint={onPrint} />
            <div style={{ width: "100%", overflow: "scroll", margin: "auto", textAlign: "center", backgroundColor: "white", display: "flex" }}>
                {
                    (fileUrl && fileUrl !== null && fileUrl.length !== 0) &&
                    <Document
                        // file={fileUrl}
                        file={{
                            url: fileUrl
                        }}
                        style={{ display: "flex", justifyContent: "center" }}
                        onLoadSuccess={({ numPages }) => {
                            setPages(numPages);
                            setLoaded(true);
                        }}
                        onLoadError={e => {
                            console.log(`load error pdf file:${file}`, e);
                            setLoaded(false);
                        }}
                    >
                        <Page pageNumber={page}
                            rotate={rotate}
                            scale={zoom}
                            width={980}
                        />
                    </Document>
                }
            </div >
            {
                loaded === true ?
                    <Header handlePreToTop={() => { setPage(1); setPageText(1) }}
                        handlePre={() => {
                            if (page > 1) {
                                setPage(page - 1); setPageText(page - 1)
                            }
                        }}
                        handleNext={e => {
                            if (page < pages) {
                                setPage(page + 1); setPageText(page + 1);
                            }
                        }}
                        handleNextToEnd={() => {
                            setPageText(pages); setPage(pages);
                        }}
                        handleChangePage={e => {
                            var value = parseInt(e.target.value)
                            if (isNaN(value)) {
                                setPageText("");
                            } else if (value) {
                                if (value >= 0 && value <= pages) {
                                    setPage(value); setPageText(value);
                                }
                            }
                        }}
                        handleZoomIn={() => {
                            setZoom(zoom + scaleValue);
                            setScaleText(Math.trunc((zoom + scaleValue) * 100))
                        }}
                        handleZoomOut={() => {
                            if (zoom < 0.1) {
                                setZoom(0.1);
                                setScaleText(Math.trunc(0.1 * 100))
                            }
                            else {
                                setZoom(zoom - scaleValue);
                                setScaleText(Math.trunc((zoom - scaleValue) * 100))
                            }
                        }}
                        handleChangeScaleValue={e => {
                            var value = parseInt(e.target.value)
                            if (value) {
                                var scale = value / 100
                                setZoom(scale);
                                setScaleText(Math.trunc(scale * 100))
                            } else {
                                setZoom(0.1);
                                setScaleText(Math.trunc(0.1 * 100))
                            }
                        }}
                        handleChangeLeftRotate={() => {
                            setRotate(rotate - 90);
                        }}
                        handleChangeRightRotate={
                            () => { setRotate(rotate + 90) }
                        }
                        downloadEnable={downloadEnable} printEnable={printEnable} page={page} pages={pages} zoom={zoom} pageText={pageText} scaleText={scaleText}
                        onDownload={onDownload}
                        onPrint={onPrint} />
                    : null
            }

            {/* <Document
                style={{display:"flex", justifyContent:"center"}}
                file={file}
                onLoadSuccess={({ numPages }) => setNumberOfPages(numPages)}
                onLoadError={e=>{console.log(e)}}
                // options={{ cMapUrl: '/_next/cmaps/', cMapPacked: true }}
                >
                <Page pageNumber={pageNumber} />
            </Document>
            <p>Page {pageNumber} of {numberOfPages}</p>  */}
        </div >
    )
}

PDFViewer.propTypes = {
    fileUrl: PropTypes.string,
    downloadEnable: PropTypes.bool,
    printEnable: PropTypes.bool,
    page: PropTypes.number,
    zoom: PropTypes.number,
};

PDFViewer.defaultProps = {
    downloadEnable: false,
    printEnable: false,
    page: 1,
    zoom: 1.0,
};

const Header = ({ handlePreToTop, handlePre, handleNext, handleNextToEnd, handleChangePage, handleChangeScaleValue, handleZoomIn, handleZoomOut, handleChangeLeftRotate, handleChangeRightRotate, downloadEnable, printEnable, onDownload, onPrint, page, pages, zoom, pageText, scaleText, rotate, style }) => {
    return (
        <div style={{ backgroundColor: "#2F526C", width: "100%", height: "50px", textAlign: "center", color: "white", ...style }}>
            <div style={{ float: "left", marginRight: "20px" }}>
                <Button onClick={handleChangeLeftRotate} color="" style={{ width: "50px", height: "50px", margin: "auto", objectFit: "contain" }}><img src={iconRotateLeft} alt="download"></img></Button>
                <Button onClick={handleChangeRightRotate} color="" style={{ width: "50px", height: "50px", margin: "auto", objectFit: "contain" }}><img src={iconRotateRight} alt="print"></img></Button>
            </div>
            <Button onClick={handlePreToTop} color="" style={{ width: "50px", height: "50px", margin: "auto" }}><img src={iconPreTop} alt="preTop"></img></Button>
            <Button onClick={handlePre} color="" style={{ width: "50px", height: "50px", margin: "auto" }}><img src={iconPre} alt="pre"></img></Button>
            <Button onClick={handleNext} color="" style={{ width: "50px", height: "50px", margin: "auto" }}><img src={iconNext} alt="next"></img></Button>
            <Button onClick={handleNextToEnd} color="" style={{ width: "50px", height: "50px", margin: "auto" }}><img src={iconNextEnd} alt="nextEnd"></img></Button>
            <div style={{ display: "inline", marginLeft: "20px" }}>
                <Input value={pageText} onChange={handleChangePage} pattern="[0-9]*" type="text" name="page" id="txtpage" placeholder="" style={{ width: "60px", height: "30px", display: "inline", backgroundColor: "transparent", color: "white", textAlign: "center" }}></Input>
                <Label style={{ color: "white", marginLeft: "10px" }}>/ {pages}</Label>
            </div>
            <div style={{ display: "inline", marginLeft: "20px" }}>
                <Button onClick={handleZoomOut} color="" style={{ width: "50px", height: "50px", margin: "auto" }}><img src={iconZoomOut} alt="zoomout"></img></Button>
                <Input disabled={true} value={scaleText} pattern="[0-9]*" type="text" name="scale" id="txtScale" placeholder="" style={{ width: "60px", height: "30px", display: "inline", backgroundColor: "transparent", color: "white", textAlign: "center" }}></Input>%
                    <Button onClick={handleZoomIn} color="" style={{ width: "50px", height: "50px", margin: "auto" }}><img src={iconZoomIn} alt="zoomin"></img></Button>
            </div>
            <div style={{ float: "right", marginRight: "20px" }}>
                {downloadEnable === true ? <Button color="" style={{ width: "50px", height: "50px", margin: "auto", objectFit: "contain" }} onClick={onDownload}><img src={iconDownload} alt="download"></img></Button> : null}
                {printEnable === true ? <Button color="" style={{ width: "50px", height: "50px", margin: "auto", objectFit: "contain" }} onClick={onPrint}><img src={iconPrint} alt="print"></img></Button> : null}
            </div>
        </div>
    )
}

export default PDFViewer;
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { getToken } from '../Constants'

const httpLink = createUploadLink({
  uri: window.env.GRAPH_URL
})

const authLink = setContext((_, { headers }) => {

  var tokenStr = getToken();

  if (tokenStr) {
     return {
      headers: {
        ...headers,
        authorization:`Bearer ${tokenStr}`
      }
    }
  }  
  return {};
})
//this defaultOptions will stop apollo from caching?
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions
})



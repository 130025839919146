import React from 'react';
import { showDialog,hideDialog } from 'store/actions';
import QuickTableDialog from './QuickTableDialog';
export function showQuickTableDialog({ rootClass,title, subtitle, columns, initData,onClose, onFetchData,onUpdateRef, ...props }) {
    return (dispatch) => {
        const dialog = {
            children: <QuickTableDialog
                {...props}
                title={title}
                subtitle={subtitle}
                columns={columns}
                initData={initData}
                onFetchData={onFetchData}
                onUpdateRef={onUpdateRef}
                onClose={onClose}
            />,
            id: 'quick-table-dialog',
            rootClass
        };
        dispatch(showDialog(dialog))
    }
}
export function hideQuickTableDialog() {
    return(dispatch)=>dispatch(hideDialog('quick-table-dialog'))
}
import React,{useState,useEffect} from 'react';
import { getUser,getToken,getUserId } from 'Constants';
import authCheck from 'utils/authCheck';
import CreateNewVersionForm from 'components/Widget/PVT/CreateNewVersionForm'
import VersionWidget from 'components/Widget/PVT/VersionWidget'
import { Button } from 'reactstrap';
import { CREATE_FILE_VERSION} from './query';
import axios from 'axios';
import FileSaver, { saveAs } from 'file-saver';
import { client } from 'utils/apolloConnect';
export function TabVersion({ data,refetchDoc, ...props }) {
    const [user] = useState(getUser());
    const [showVersionForm, setShowVersionForm] = useState(false);

    function onCreateFileVersion(item) {
        client.query({
            query: CREATE_FILE_VERSION,
            variables: { version: { ...item, docId:data._id} }
        }).then(result => {
            if (authCheck(result.data)) {
                refetchDoc&&refetchDoc(data._id)
            }
        })
    }
    function handleDownloadFile(pdfUrl, fileName) {
        
        var token = getToken();
        var accessToken = "";
        try {
            accessToken = token.accessToken;
            axios({
                url: pdfUrl,
                method: 'GET',
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                json: true,
                responseType: 'blob', // important
            }).then((response) => {
                FileSaver.saveAs(new Blob([response.data]), `${fileName}`);
            });
        } catch (err) { }
    }

    return (
        <div className="w-full">
            <div style={{ margin: "auto", display: "block", textAlign: "center", backgroundColor: "white", padding: "20px", marginRight: "50px" }}>
                {(user.roleId == "quan-tri-he-thong" || data.permission && data.permission.edit) && <Button onClick={e => { setShowVersionForm(true) }} color="success" style={{ fontSize: "15px", float: "right" }}>Phiên bản mới</Button>}
                <CreateNewVersionForm
                    isOpen={showVersionForm}
                    onSuccess={(item) => {
                        onCreateFileVersion(item)
                        setShowVersionForm(false);
                    }}
                    onCancel={() => {
                        setShowVersionForm(false);
                    }}
                >
                </CreateNewVersionForm>
            </div>
            <div style={{ margin: "auto", display: "block", textAlign: "center", backgroundColor: "white", padding: "20px", marginTop: "15px" }}>
                {
                    data.versions.map((item, index) => {
                        var isLasted = false
                        if (index === data.versions.length - 1) {
                            isLasted = true
                        }
                        return <VersionWidget
                            onDownload={e => {
                                let fileUrl = item.pdfUrl
                                let fileName = `${item.fileId}.pdf`
                                console.log(`donwloading fileName: ${fileName} path: ${fileUrl}`)
                                if (fileUrl && fileName) {
                                    handleDownloadFile(fileUrl, `${fileName}.pdf`)
                                }
                            }}
                            onPreview={e => {
                                if (item) {
                                    let fileUrl = item.pdfUrl
                                    // console.log("get fileUrl: ", fileUrl)
                                    var win = window.open(fileUrl, '_blank');
                                    win.focus();
                                }
                            }}

                            //thay đổi theo yêu cầu mới: ai tạo version này thì mới được xem và tải về
                            //nên cần check xem id người tạo và id đăng nhập có trung nhau hay ko
                            canDownload={user.roleId == "quan-tri-he-thong" || item.accountId === getUserId()}
                            canPreview={user.roleId == "quan-tri-he-thong" || item.accountId === getUserId()}
                            time={item.createdTime}
                            version={item.name}
                            userName={item.account && item.account.base && item.account.base.fullName}
                            content={item.note}
                            isLasted={isLasted}
                        >
                        </VersionWidget>
                    })
                }
            </div>
        </div>
    )
}
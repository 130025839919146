import React,{useState,useEffect} from 'react';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import { Card } from 'reactstrap';
import { getUser } from 'constants';
import { TabDetail,TabVersion ,TabFeedback,TabAppendix} from './Tabs'

export function TabInfores({ data,commentId,refetchDoc}) {
   
    return (
        <div id="content" style={{ maxWidth: "1280px", minHeight: "350px", backgroundColor: "white", margin: "auto", marginTop: "35px", marginBottom: "40px", color: "#4E5357", fontSize: "16px", borderRadius: "4px" }}>
            <Card border="none">
                <Tabs
                    defaultTab={commentId?'feedback':"content"}
                    onChange={(tabId) => { console.log("changeToTab",tabId) }}
                    style={{ border: '0px' }}
                >
                    <TabList>
                        <Tab tabFor="content">Nội dung</Tab>
                        <Tab tabFor="version">Phiên bản</Tab>
                        <Tab tabFor="feedback">Góp ý</Tab>
                        <Tab tabFor="bieumau">Biểu mẫu</Tab>
                    </TabList>
                    <TabPanel tabId="content">
                        <TabDetail data={data}/>
                    </TabPanel>
                    <TabPanel tabId="version" style={{ textAlign: "center" }}>
                        <TabVersion data={data} refetchDoc={refetchDoc}/>
                    </TabPanel>
                    <TabPanel tabId="feedback">
                        <TabFeedback data={data} commentId={commentId}/>
                    </TabPanel>
                    <TabPanel tabId="bieumau">
                        <TabAppendix data={data}/>
                    </TabPanel>
                </Tabs>
            </Card>
        </div>
    )
}
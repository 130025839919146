import gql from 'graphql-tag';

export const QUERY_ME = gql`
{
  response:me{
    code
    message
    data{
      _id
      roleId
      roleName
      accountGroups{
        _id
        accountId
        groupName
        groupId
        isAdmin
        permission{
          delete
          deleteFeedback
          download
          edit
          print
          write
          feedback
        }
      }
      base{
        oauth_token
        fullName
        userName
        code
        birthdate
        email
        gender
        isRoot
        sub
        picture
      }
      
    }
  }
}
`

export const LOGIN_MUTATION = gql`
mutation ($username: String!, $password: String!) {
    response:login(username: $username, password: $password) {
      code
      message
      data{
        oauth_token
      }
    }
  }
`


export const MUTATION_LOGIN_WITH_TOKEN = gql`
mutation($token:String!){
    response:validateToken(token:$token){
        code
        message
        data{
          _id
          fullName
          roleId
          roleName
          accountGroups{
            _id
            accountId
            groupName
            permission{
              delete
            }
          }
          claims{
            value
            type
          }
          base{
            oauth_token
            fullName
            userName
            code
            birthdate
            email
            gender
            isRoot
          }           
        }
    }
}
`
import React, { useState, useEffect, useRef } from 'react';
import SendFeedbackWidget from 'components/Widget/PVT/SendFeedbackWidget'
import FeedbackWidget from 'components/Widget/PVT/FeedbackWidget'
import AnswerFeedbackWidget from 'components/Widget/PVT/AnswerFeedbackWidget'
import AnswerFeedbackInputWidget from 'components/Widget/PVT/AnswerFeedbackInputWidget';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import authCheck from 'utils/authCheck';
import * as BaseControl from 'utils/PVT/VTBaseControl.js';
import { client } from 'utils/apolloConnect';
import { GET_DOC_COMMENTS, CREATE_DOC_COMMENT, UPDATE_READ_COMMENT, DELETE_DOC_COMMENT } from './query'
import { getUser } from 'Constants';

const scrollToMyRef = (ref) => window.scrollTo(0, ref.current.offsetTop + 150)
export function TabFeedback({ data, commentId, ...props }) {
    const [comments, setComments] = useState([]);
    const [user] = useState(getUser());
    const myRef = useRef();

    function fetchComments() {
        var filtered = [{ id: "docId", value: `${data._id}` }, { id: "parentId", value: "null" }]
        var sorted = [{ id: "createdTime", desc: true }]
        client.query({
            query: GET_DOC_COMMENTS,
            variables: { page: 0, filtered: filtered, sorted: sorted }
        }).then(result => {
            authCheck(result.data)
            const data = result.data.response.data
            if (data) {
                setComments(data);
            }
        })
    }
    function updateRead(commentId) {
        client.mutate({
            mutation: UPDATE_READ_COMMENT,
            variables: { _id: commentId }
        }).then(result => {
            authCheck(result.data)
            const data = result.data.response.data
            if (data) {
                // fetchComments()
            }
        })
    }
    function onSendComment(form) {
        const { section, subSection, comment } = form;
        if (comment === null || comment.length === 0) {
            BaseControl.showErrorToast("Vui lòng nhập nội dung góp ý")
            return
        }
        var input = {
            section: section,
            subSection: subSection,
            docId: data._id,
            comment: comment,
            parentId: null,
        }
        client.query({
            query: CREATE_DOC_COMMENT,
            variables: { comment: input, }
        }).then(result => {
            if (authCheck(result.data)) {
                fetchComments()
            }
        })
    }
    useEffect(() => {
        fetchComments();

    }, [data]);
    useEffect(() => {
        if (myRef.current) {
            scrollToMyRef(myRef)
        }
    }, [myRef]);
    useEffect(() => {
        if (commentId) {
            updateRead(commentId);
        }
    }, [commentId]);
    return (
        <div className="w-full">
            <div style={{ marginTop: "10px", display: "inline-block", width: "100%", minHeight: "232px", padding: "8px 40px" }}>
                <SendFeedbackWidget style={{ width: "100%", minHeight: "232px" }}
                    onSendComment={onSendComment}>
                </SendFeedbackWidget>
            </div>
            <Typography style={{fontSize:18,fontWeight:"bold",padding:"20px 0 0 40px"}}>
                DANH SÁCH PHẢN HỒI
            </Typography>
            <div style={{ marginTop: "1 0px", marginBottom: "160px", padding: "8px 40px", display: "block", width: "100%", backgroundColor: "white", textAlign: "center", }}>
                {
                    comments.map(e => {
                        var sender = e.account ? e.account : ""
                        var comment = e.comment ? e.comment : ""
                        var section = e.section ? e.section : ""
                        var subSection = e.subSection ? e.subSection : ""
                        var createdTime = e.createdTime && e.createdTime
                        var _id = e._id
                        var read = e.read
                        var childComments = e.children ? e.children : []
                        return (
                            <ExpansionPanel defaultExpanded>
                                <ExpansionPanelSummary
                                    //  expandIcon={<ExpandMoreIcon />}
                                    //  aria-controls={`require-content`}
                                >
                                    <div id={_id} style={{ marginLeft: "20px", }}
                                        ref={_id === commentId ? myRef : null}
                                        style={{ width: "100%" }}
                                    >
                                        <FeedbackWidget
                                            _id={_id}
                                            sender={sender}
                                            section={section}
                                            subSection={subSection}
                                            createdTime={createdTime}
                                            read={read}
                                            childcount={childComments.length}
                                            targetId={commentId}
                                            comment={comment}
                                            canDelete={user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.deleteFeedback)}
                                            onDelete={deleteId => {
                                                BaseControl.showConfirmAlert(
                                                    "Thông báo",
                                                    "Bạn có chắc muốn xóa góp ý này?",
                                                    "Đồng ý",
                                                    "Hủy",
                                                    () => {//success
                                                        try {
                                                            if (deleteId) {
                                                                client.query({
                                                                    query: DELETE_DOC_COMMENT,
                                                                    variables: { _id: deleteId }
                                                                }).then(result => {
                                                                    if (authCheck(result.data)) {
                                                                        fetchComments()
                                                                    }
                                                                })
                                                            }
                                                        } catch (error) {
                                                            // console.log("===> delete delete doc error: ", error)
                                                        }
                                                    },
                                                    () => {//cancel

                                                    }
                                                )
                                            }}
                                            onClick={() =>
                                                read === false &&
                                                client.query({
                                                    query: UPDATE_READ_COMMENT,
                                                    variables: { _id: _id }
                                                }).then(result => {
                                                    authCheck(result.data)
                                                    // console.log("read comment:", result.data.response.data)
                                                    const data = result.data.response.data
                                                    if (data) {
                                                        fetchComments()
                                                    }
                                                })
                                            }
                                        ></FeedbackWidget>
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="w-full">
                                        {
                                            childComments.map(element => {
                                                var sender = element.account ? element.account : ""
                                                var comment = element.comment ? element.comment : ""
                                                var createdTime = element.createdTime && element.createdTime
                                                var childId = element._id;
                                                var read = element.read
                                                return (
                                                    <div id={childId} style={{ marginLeft: 60, }} ref={childId === commentId ? myRef : null}>
                                                        {/* _id,sender, comment, createdTime,onDelete, canDelete=false,style */}
                                                        <AnswerFeedbackWidget
                                                            _id={childId}
                                                            sender={sender}
                                                            createdTime={createdTime}
                                                            read={read}
                                                            targetId={commentId}
                                                            comment={comment}
                                                            canDelete={user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.deleteFeedback)}
                                                            onDelete={subDeleteId => {
                                                                // console.log("delete sub comment with Id : ", subDeleteId)

                                                                BaseControl.showConfirmAlert(
                                                                    "Thông báo",
                                                                    "Bạn có chắc muốn xóa góp ý này?",
                                                                    "Đồng ý",
                                                                    "Hủy",
                                                                    () => {//success
                                                                        try {
                                                                            if (subDeleteId) {
                                                                                client.query({
                                                                                    query: DELETE_DOC_COMMENT,
                                                                                    variables: { _id: subDeleteId }
                                                                                }).then(result => {
                                                                                    if (authCheck(result.data)) {
                                                                                        fetchComments()
                                                                                    }
                                                                                })
                                                                            }
                                                                        } catch (error) {
                                                                            // console.log("===> delete doc sub comment: ", error)
                                                                        }
                                                                    },
                                                                    () => {//cancel

                                                                    }
                                                                )
                                                            }}
                                                            onClick={() =>
                                                                read === false &&
                                                                client.query({
                                                                    query: UPDATE_READ_COMMENT,
                                                                    variables: { _id: childId }
                                                                }).then(result => {
                                                                    if (authCheck(result.data)) {
                                                                        const data = result.data.response.data
                                                                        if (data) {
                                                                            fetchComments()
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        ></AnswerFeedbackWidget>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div style={{ marginLeft: 60}}>
                                            {
                                                (user.roleId == "quan-tri-he-thong" || (data.permission && data.permission.feedback)) &&
                                                <AnswerFeedbackInputWidget onSendComment={(comment) => {
                                                    var input = {
                                                        docId: data.docId,
                                                        comment: comment,
                                                        parentId: _id,
                                                    }
                                                    client.query({
                                                        query: CREATE_DOC_COMMENT,
                                                        variables: { comment: input, }
                                                    }).then(result => {
                                                        if (authCheck(result.data)) {
                                                            fetchComments()
                                                        }
                                                    })
                                                }}></AnswerFeedbackInputWidget>
                                            }
                                        </div>
                                    </div>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
                }
            </div>
        </div>
    )
}
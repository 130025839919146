import gql from 'graphql-tag';

export const DELETE_MENU=gql`
mutation($_id:String!){
  response:deleteMenu(_id:$_id){
    code
    message
  }
}
`
export const LIST_ALL_PARENT_MENU=gql`
{
  response:menus(filtered:[{id:"path",value:"#"}]){
    code
    message
    data{
      _id
      name
    }
  }
}
`
export const LIST_MENU=gql`
query ($filtered:[FilteredInput],$sorted:[SortedInput], $page: Int, $pageSize: Int){
  response:menus(filtered:$filtered,sorted:$sorted, page: $page, pageSize: $pageSize){
    code
    message
    pages
    data{
      _id
      sort      
      name
      level
      parentId
      parent{
        name
      }
      description
      disable
      path
      icon      
    }
  }
}
`

export const QUERY_SIDEMENU = gql`
query ($filtered:[FilteredInput],$sorted:[SortedInput]){
  directories(filtered:$filtered,sorted:$sorted){
    code
    message
    data{
      name
      to:_id
      hidden
      priority
      slug
      children{
        name
        hidden
        to:_id
        slug
        priority
        children{
          name
          to:_id
          hidden
          slug
          priority
          children{
            name
            hidden
            to:_id
            slug
            priority
          }
        }
      }
    }
  
}
  response:myMenus(showRootIfNotExist:true){
    code
    message
    data{
      name
      to
      icon
      children{
        name
        to
        icon
        children{
          name
          to
          icon
          children{
            name
            to
            icon
          }
        }
      }
    }
  }
  
  
}

`
